import Tabs from "@ipgd-gauge/tabs";
import { deleteUrlParam, getUrlParam, handleError, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import XIcon from "../../../icons/X";
import { getCurrentTime, getEstimationsQuotation } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, GLOBAL_STATUS_INCOMPLETE, STATUS_NEW } from "../../../util/constants";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { closeSummary } from "../../../util/util";
import Actions from "../actions";
import style from './style.module.scss';
import Summary from "./summary";
import Tab1 from "./tab1";
import Tab2 from "./tab2";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";

const EstimationQuotation = () => {
    const { t } = useTranslation();

    const id = getUrlParam('id');
    const currentUser = getUser();
    const printDialogRef = useRef();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);
    const attachmentsImgRef = useRef();

    const defaultValue = {
        status: STATUS_NEW,
        globalStatus: GLOBAL_STATUS_INCOMPLETE,
        customerLevel: CUSTOMER_LEVEL_PREDEFINED
    };

    const [estimationQuotation, setEstimationQuotation] = useState({ ...defaultValue });

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (isNotEmpty(id)) {
            getEstimationsQuotation(id)
                .then((response) => setEstimationQuotation(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction();
        }
    }, [id])

    const _newAction = async () => {
        let tempDefaultValue = Object.assign({}, defaultValue);
        let currentTime = null;
        try {
            currentTime = (await getCurrentTime()).data;
        } catch (error) {
            currentTime = new Date().getTime();
        }
        tempDefaultValue.dateDgr = currentTime;
        tempDefaultValue.fcuId = currentUser.fcuId;
        tempDefaultValue.exchngRate = 1;
        tempDefaultValue.mbnId = currentUser?.mbnId;
        fromNewActionRef.current = true;
        deleteUrlParam('id');
        setEstimationQuotation(tempDefaultValue);
    }

    const countNetPrice = (estimationQuotation) => {
        let netPrice = 0
        for (let i = 0; i < estimationQuotation.lines.length; i++) {
            netPrice += Number(estimationQuotation.lines[i].netPrice)
        }
        return netPrice
    }

    const _handleDataChanged = (name, value) => {
        const newEstimationQuotation = {};
        newEstimationQuotation[name] = value;

        if (name == "customerLevel") {
            if (value == CUSTOMER_LEVEL_NEW) {
                newEstimationQuotation.atrId = null;
                newEstimationQuotation.customerData = null;
            } else if (value == CUSTOMER_LEVEL_PREDEFINED) {
                newEstimationQuotation.customerName = null;
                newEstimationQuotation.mobileNumber = null;
                newEstimationQuotation.cardNumber = null;
                newEstimationQuotation.phoneNumber = null;
                newEstimationQuotation.email = null;
                newEstimationQuotation.customerCommRegNum = null;
                newEstimationQuotation.taxNumber = null;
                newEstimationQuotation.customerContact = null;
                newEstimationQuotation.customerDeelgationNum = null;
                newEstimationQuotation.city = null;
                newEstimationQuotation.town = null;
                newEstimationQuotation.buildingNumber = null;
                newEstimationQuotation.postalCode = null;
                newEstimationQuotation.customerAddress = null;
            }
        } else if (name == "lines") {
            newEstimationQuotation.netPrice = countNetPrice(newEstimationQuotation)
        } else if (name === "totalPrice" || name === "vat") {
            newEstimationQuotation.netPrice = parseNumber(newEstimationQuotation?.totalPrice) + parseNumber(newEstimationQuotation?.vat);
        }
        else if (name == "hasValidyPeriod") {
            newEstimationQuotation.validityPeriodFromDgr = null;
            newEstimationQuotation.validityPeriodToDgr = null;
            newEstimationQuotation.validityPeriodFromDhi = null;
            newEstimationQuotation.validityPeriodToDhi = null;
        } else if (name == "hasOfferDetails") {
            newEstimationQuotation.quotationDesc = null;
            newEstimationQuotation.quotationDetails = null;
            newEstimationQuotation.noOfDays = null;
            newEstimationQuotation.documentsSource = null;
            newEstimationQuotation.reportUsers = null;
        } else if (name === "atrId") {
            newEstimationQuotation.atrId = value.value;
            newEstimationQuotation.customerData = value;
        }
        if (name === "umyId") {
            newEstimationQuotation.umyId = value.value;
            newEstimationQuotation.quotationType = value;
            newEstimationQuotation.lines = [];
        }

        setEstimationQuotation((state) => ({ ...state, ...newEstimationQuotation }));
    }


    const handleOpenSummary = () => {
        document.getElementById('estimation-quotation-lines').classList.toggle('active');
        document.getElementById('estimation-quotation-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    return (
        <>
            <div className={style.container}>
                <div id="estimation-quotation-lines" className="lines-container-open-close">
                    <Tabs
                        id="estimation-quotations-main"
                        labels={['home', 'attachments']}
                        containerStyle={style.tabsContainer}
                        tabContentStyle={style.tabContentStyle}
                    >
                        <Tab1
                            currentUser={currentUser}
                            estimationQuotation={estimationQuotation}
                            handleDataChanged={_handleDataChanged}
                            setEstimationQuotation={setEstimationQuotation}
                        />
                        <Tab2
                            data={estimationQuotation}
                            attachmentsImgRef={attachmentsImgRef}
                        />
                    </Tabs>
                </div>
                <div id="estimation-quotation-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        estimationQuotation={estimationQuotation}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                user={currentUser}
                isListing={false}
                data={estimationQuotation}
                setData={setEstimationQuotation}
                newAction={_newAction}
                printDialogRef={printDialogRef}
                fromSaveActionRef={fromSaveActionRef}
                attachmentsImgRef={attachmentsImgRef}
            />
            <PrintDialog
                id="estimation-quotation-print-dialog"
                ref={printDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                user={currentUser}
            />
        </>
        // <>
        //     <div id='estimation-quotation-container' className={style.container}>
        //         <div className={style.linesContainer}>
        //             <div>
        //                 <div className={`toggle-mode ${style.toggleMode}`}>
        //                     <Header
        // currentUser={currentUser}
        // estimationQuotation={estimationQuotation}
        // handleDataChanged={_handleDataChanged}
        //                     />

        //                     {isNotEmpty(estimationQuotation?.quotationType?.fields) &&
        //                         <>
        //                             <Customer estimationQuotation={estimationQuotation} handleDataChanged={_handleDataChanged} />
        //                             <QuotationDescription estimationQuotation={estimationQuotation} handleDataChanged={_handleDataChanged} />
        //                             <Details estimationQuotation={estimationQuotation} setEstimationQuotation={setEstimationQuotation} handleDataChanged={_handleDataChanged} user={currentUser} />
        //                         </>
        //                     }
        //                 </div>
        //             </div>
        //         </div>
        // </>
    )
}

export default EstimationQuotation;