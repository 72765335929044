import { formatDate, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from "../../../../icons/EstimationMap";
import { formatMoney } from "../../../../util/util";
import { ACTION_COUNT_TOTALS } from "../reducer";
import style from './style.module.scss';
import XIcon from "../../../../icons/X";

const Totals = (props) => {
    const { totals, creditNote, dispatchCreditNote } = props
    const { t } = useTranslation("creditNotesForCustomersEstimation");

    useEffect(() => {
        dispatchCreditNote({ type: ACTION_COUNT_TOTALS })
    }, [creditNote.lines])

    return (
        <div className={style.totalsTrx}>
            <div>
                <div className='totalsLbl'>
                    <label>{t('count')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{totals?.count}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('total_price')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalPriceAlc, 6)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('discount')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalDiscountAlc, 6)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('vat')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalVatAlc, 6)}</label>
                    </div>
                </div>
            </div>
            <div>
                <div className='totalsLbl'>
                    <label>{t('net_price')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalNetPriceAlc, 6)}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Summary = (props) => {
    const { t } = useTranslation();
    const { creditNote, dispatchCreditNote, totals , handleOpenSummary} = props;

    return (
        <div className="summary-container-open-close">
            <div className="summary-header-title-open-close">
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('customersEstimationInvoices:summary')}</label>
                </div>
                <div className="attach-summary-container">
                    <button onClick={handleOpenSummary}>
                        <XIcon />
                    </button>
                </div>
            </div>

            <div className="summaryContainerWithTotal">
                <div className="summary-table">
                    <div className="frm-summary-card">
                        {isNotEmpty(creditNote?.header?.code) &&
                            <div className={` ${style.summaryCode}`}>
                                <div className="td-column">#{creditNote?.header?.code}</div>
                            </div>}
                        {(isNotEmpty(creditNote?.header?.dateDgr) || isNotEmpty(creditNote?.header?.atrId)) &&
                            <div className="summary-ref">
                                <div>
                                    <label className="form-label">{t('customersEstimationInvoices:invoice_date')}</label>
                                    <div>{formatDate(creditNote?.header?.dateDgr) || '-'}</div>
                                </div>
                                <div>
                                    <label className="form-label">{t('customersEstimationInvoices:customer')}</label>
                                    <div>{isEnglish() ? creditNote?.header?.customerData?.descLo : creditNote?.header?.customerData?.descFo || '-'}</div>
                                </div>
                            </div>}

                        {isNotEmpty(creditNote?.header?.invoiceData?.code) &&
                            <div className="desc-summary-container">
                                <label>{t('creditNotesForCustomersEstimation:invoice_number')}</label>
                                <div className="desc-summary" title={creditNote?.header?.invoiceData?.code}>
                                    {creditNote?.header?.invoiceData?.code}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Totals
                    dispatchCreditNote={dispatchCreditNote}
                    creditNote={creditNote}
                    totals={totals}
                />
            </div>
        </div>
    );
}

export default Summary;