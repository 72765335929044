import { deleteUrlParam, getUrlParam, isEmpty, isNotEmpty, removeAllMandatoryClasses, showNotificationToast } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import { getDefaultFormValues, getEstimatorInvoice, getEstimatorInvoiceParties } from "../../../util/apis";
import { AFFECTED_ACCOUNT_PARTIES_ACCOUNTS, GLOBAL_STATUS_INCOMPLETE, INVOICE_LEVEL_ALL_PARTIES, INVOICE_LEVEL_SPECIFIC_PARTIES } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Details, { ESTIMATION_FEES, ITEM } from "./Details";
import Header from "./Header";
import PartyList from "./PartyList";
import Actions from "./actions";
import estimatorInvoiceReducer, { ACTION_CREATE_NEW_INVOICE, ACTION_SET_INVOICE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";

const EstimatorsInspectorsInvoice = () => {

    const { t } = useTranslation();
    const user = getUser();
    const printDialogRef = useRef();
    const partyIdParam = getUrlParam('partyId');

    const initialState = {
        invoice: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                invoiceLevel: INVOICE_LEVEL_ALL_PARTIES,
                affectedAccounts: AFFECTED_ACCOUNT_PARTIES_ACCOUNTS,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
            },
            lines: [],
            parties: [],
            filteredLines: null,
        },
        totals: {},
    }

    const [invoiceState, dispatchInvoice] = useReducer(estimatorInvoiceReducer, initialState);

    const invoiceId = getUrlParam('id');

    useEffect(() => {
        if (invoiceId) {
            getEstimatorInvoice(invoiceId)
                .then((response) => {
                    let header = response.data;
                    response.data.defaultCdaDescLo = header.defaultCdaItem?.descLo;
                    response.data.defaultCdaDescFo = header.defaultCdaItem?.descFo;
                    response.data.defaultCdaCode = header.defaultCdaItem?.code;
                    response.data.lines.map((line) => {
                        if (line.source == ESTIMATION_FEES) {
                            if (header.defaultCdaItem) {
                                line.descLo = header.defaultCdaItem?.code + ', ' + header.defaultCdaItem?.descLo
                                line.descFo = header.defaultCdaItem?.code + ', ' + header.defaultCdaItem?.descFo
                            }
                        }
                        if (line.source == ITEM) {
                            line.descLo = line.item.code + ', ' + line.item.descLo
                            line.descFo = line.item.code + ', ' + line.item.descFo
                        }
                    })
                    let invoiceData = { header: response.data, lines: response.data.lines || [], parties: response.data.parties || [] };
                    dispatchInvoice({ value: invoiceData, type: ACTION_SET_INVOICE })
                })
        } else {
            _newInvoice();
        }
    }, [invoiceId]);

    const _newInvoice = async () => {
        const newInvoice = { ...initialState };
        const defaultData = (await getDefaultFormValues(RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID)).data
        if (isNotEmpty(partyIdParam)) {
            newInvoice.invoice.parties.aprId = partyIdParam;
            newInvoice.invoice.header.invoiceLevel = INVOICE_LEVEL_SPECIFIC_PARTIES;
            let partiesRes = await getEstimatorInvoiceParties('', newInvoice.invoice?.header?.fogId, null, null, partyIdParam);
            let party = partiesRes.data.filter(p => p.id == partyIdParam);
            let rows = [];
            party.map((data, index) => {
                let row = {};
                row.aprId = data.id;
                row.seq = index + 1;
                row.party = {};
                row.party.code = data.code
                row.party.nameLo = data.nameLo
                row.party.nameFo = data.nameFo
                row.party.descLo = data.descLo
                row.party.descFo = data.descFo
                rows.push(row);
            })
            newInvoice.invoice.parties = rows;
        }

        newInvoice.invoice.header.dateDgr = defaultData.currentDate;
        newInvoice.invoice.header.fcuId = defaultData?.fcuId;
        newInvoice.invoice.header.defaultCdaId = defaultData?.defaultItem?.id;
        newInvoice.invoice.header.defaultCdaCode = defaultData?.defaultItem?.code;
        newInvoice.invoice.header.defaultCdaDescLo = defaultData?.defaultItem?.descLo;
        newInvoice.invoice.header.defaultCdaDescFo = defaultData?.defaultItem?.descFo;
        newInvoice.invoice.header.defaultCdaVatPerc = defaultData?.defaultItem?.vatPerc;
        if (isEmpty(defaultData?.estimationInvoicePolicyId)) {
            showNotificationToast(t('USER_POLICY_ERR'), { hideProgressBar: true, type: 'warning' })
        }

        dispatchInvoice({ value: { ...newInvoice }, type: ACTION_CREATE_NEW_INVOICE })
        removeAllMandatoryClasses(document.getElementById("estimators-inspectors-invoice"))
        deleteUrlParam("id");
    }

    const handleOpenSummary = () => {
        document.getElementById('estimators-inspectors-invoice-lines').classList.toggle('active');
        document.getElementById('estimators-inspectors-invoice-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    return (
        <>
            <div className={style.container}>
                <div id="estimators-inspectors-invoice-lines" className="lines-container-open-close">
                    <div>
                        <div id='estimators-inspectors-invoice' className={`toggle-mode ${style.toggleMode}`}>
                            <Header
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />

                            {invoiceState.invoice?.header?.invoiceLevel == INVOICE_LEVEL_SPECIFIC_PARTIES &&
                                <PartyList
                                    user={user}
                                    invoice={invoiceState.invoice}
                                    dispatchInvoice={dispatchInvoice}
                                />
                            }

                            <Details
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />
                        </div>
                    </div>
                </div>


                <div id="estimators-inspectors-invoice-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        totals={invoiceState.totals}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>

            </div>

            <Actions
                user={user}
                invoiceState={invoiceState}
                dispatchInvoice={dispatchInvoice}
                printDialogRef={printDialogRef}
                newAction={_newInvoice}
            />

            <PrintDialog
                id="estimator-inspector-invoice-print-dialog"
                ref={printDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                user={user}
            />
        </>
    )
}

export default EstimatorsInspectorsInvoice;