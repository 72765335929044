import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { getDomainValues } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const dialogId = "properties-estimation-list-of-value-dialog";

const ValueRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { isActive, handleDataChange } = rowActions;

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.valueRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-code`}
                        value={data?.code}
                        disabled={true}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-english-name`}
                        value={data?.descLo}
                        onBlur={value => handleDataChange(index, "descLo", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-short-english-name`}
                        value={data?.shDescLo}
                        onBlur={value => handleDataChange(index, "shDescLo", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-arabic-name`}
                        value={data?.descFo}
                        onBlur={value => handleDataChange(index, "descFo", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-short-arabic-name`}
                        value={data?.shDescFo}
                        onBlur={value => handleDataChange(index, "shDescFo", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-${index}-remarks`}
                        value={data?.rem}
                        onBlur={value => handleDataChange(index, "rem", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-status`}
                        value={data?.isActive}
                        options={isActive || []}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "isActive", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    );
};


const ValueTable = (props) => {
    const { data, setData, user } = props;

    const { t } = useTranslation("propertiesEstimationListOfValues");

    const [isActive, setIsActive] = useState([]);

    useEffect(() => {
        getDomainValues(STATUS_DOMAIN)
            .then((response) => setIsActive(response.data))
            .catch((error) => { });
    }, []);

    const _handleDataChange = (index, name, value) => {
        data.lines[index][name] = value;
        setData({ ...data });
    }

    const columns = [
        { title: <span data-property="code" className='mandatory'>{t('code')}</span>, width: "10%" },
        { title: t('english_name'), width: "16%" },
        { title: t('short_english'), width: "12%" },
        { title: t('arabic_name'), width: "16%" },
        { title: t('short_arabic'), width: "15%" },
        { title: t('remarks'), width: "17%" },
        { title: t('status'), width: "14%" },
    ];

    return (
        <Table
            id={`${dialogId}-values`}
            list={data?.lines || []}
            columns={columns}
            Row={ValueRow}
            tableStyle={style.valueTable}
            rowActions={{ isActive, handleDataChange: _handleDataChange }}
            rowHeight={40}
            user={user}
            FormId={RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID}
        />
    );
}

const Tab2 = (props) => {
    const { tab, data, setData, user } = props;

    return (
        <div className={`${tab == 2 ? 'active' : ''} ${style.frmPadding}`}>
            <ValueTable data={data} setData={setData} user={user} />
        </div>
    )
}

export default Tab2;