import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCitiesLov, getCommonBranches, getCommonCustomers, getCommonDivisionsList, getCommonPropertyTypes, getDomainValues, getEvaluationFunctions, getFndUsers, getRsmStatus, getTownsList } from "../../../util/apis";
import { RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID } from "../../../util/constants/forms";

const filterId = "estimation-transactions-register-filter";
const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';
const EstimationTransactionsRegisterFilter = (props, ref) => {
    const { user, tableRef, policyFilterRef } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation("");

    const [filterData, setFilterData] = useState({});

    const _getDefaultFilter = () => {
        return { policy: user?.estimationPolicy?.lsoId }
    }

    const handleDataChange = (name, value) => {
        let refreshData = false;
        filterData[name] = value;
        if (name === "policy") {
            refreshData = true;
            filterData.policy = value?.value;
            filterData.branchs = [];
            filterData.divisions = [];
            filterData.towns = [];
            filterData.customers = [];
        }
        setFilterData({ ...filterData });
        if (refreshData) {
            tableRef.current.refresh();
        }
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ handleDataChange, getFilterData, open }));

    return (
        <AdvanceFilter
            id={filterId}
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            getDefaultFilter={_getDefaultFilter}
            formId={RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID}
            numOfRows={5}
            postDefaultFilterRetrieved={(data) => policyFilterRef.current.setPolicyId(data.policy)}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:application_status')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-status`}
                                isMulti={true}
                                value={filterData.status}
                                api={() => getRsmStatus()}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("status", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:billing_status')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-billing-status`}
                                isMulti={true}
                                value={filterData.billingStatus}
                                api={() => getDomainValues("2855")}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("billingStatus", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:transaction_code')}</label>
                            <FrmInput
                                id={`${filterId}-code`}
                                value={filterData.code}
                                onBlur={value => handleDataChange("code", value)}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:ref_code')}</label>
                            <FrmInput
                                id={`${filterId}-ref-code`}
                                value={filterData.refCode}
                                onBlur={value => handleDataChange("refCode", value)}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:property_type')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-property-type`}
                                isMulti={true}
                                value={filterData.propertyTypes}
                                api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyTypes)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("propertyTypes", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:customer')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-customer`}
                                key={`${filterId}-customer-${filterData.policy}`}
                                value={filterData.customers}
                                onChange={value => handleDataChange("customers", value)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, null, true, filterData?.policy || "-1")}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, null, true, filterData?.policy || "-1")}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                isDisabled={isEmpty(filterData.policy)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:trxn_date_from_to')}</label>
                            <DateFromToInput
                                id="trxn_date_from_to"
                                fromValue={filterData?.trxDateFrom}
                                toValue={filterData?.trxDateTo}
                                fromOnChange={(value) => handleDataChange("trxDateFrom", value)}
                                toOnChange={(value) => handleDataChange("trxDateTo", value)}
                                enableTime
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:current_approvel_date_from_to')}</label>
                            <DateFromToInput
                                id="current-approvel_from_to"
                                fromValue={filterData?.currentApprovalDateFrom}
                                toValue={filterData?.currentApprovalDateTo}
                                fromOnChange={(value) => handleDataChange("currentApprovalDateFrom", value)}
                                toOnChange={(value) => handleDataChange("currentApprovalDateTo", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:order_date_from_to')}</label>
                            <DateFromToInput
                                id="trxn_date_from_to"
                                fromValue={filterData?.orderDateFrom}
                                toValue={filterData?.orderDateTo}
                                fromOnChange={(value) => handleDataChange("orderDateFrom", value)}
                                toOnChange={(value) => handleDataChange("orderDateTo", value)}
                                enableTime
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:invoice_approval_data_from_to')}</label>
                            <DateFromToInput
                                id="trxn_date_from_to"
                                fromValue={filterData?.invoiceApprovalDateFrom}
                                toValue={filterData?.invoiceApprovalDateTo}
                                fromOnChange={(value) => handleDataChange("invoiceApprovalDateFrom", value)}
                                toOnChange={(value) => handleDataChange("invoiceApprovalDateTo", value)}
                                enableTime
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('advance_filter')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:branch')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-branch`}
                                key={`${filterId}-branch-${filterData.policy}`}
                                isMulti={true}
                                value={filterData.branchs}
                                api={() => getCommonBranches(null, filterData.branchs, null, BRANCH_CONDITION)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("branchs", value)}
                                isDisabled={isEmpty(filterData.policy)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:division')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-division`}
                                key={`${filterId}-division-${filterData.policy}`}
                                isMulti={true}
                                value={filterData.divisions}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, filterData.policy || "-1")}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("divisions", value)}
                                isDisabled={isEmpty(filterData.policy)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:city')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-city`}
                                isMulti={true}
                                value={filterData.cities}
                                defualtValueId={filterData.cities}
                                defualtValueApi={() => getCitiesLov(filterData.cities)}
                                api={(searchValue) => getCitiesLov(null, null, null, null, searchValue, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isAsync={true}
                                onChange={value => handleDataChange("cities", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:town')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-town`}
                                key={`${filterId}-town-${filterData?.policy}`}
                                isMulti={true}
                                value={filterData.towns}
                                defualtValueApi={() => getTownsList(null, filterData.towns)}
                                defualtValueId={filterData.towns}
                                api={(searchKey) => getTownsList(searchKey, null, null, null, false, filterData.policy || "-1")}
                                isAsync={true}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={value => handleDataChange("towns", value)}
                                isDisabled={isEmpty(filterData.policy)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:workflow')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-workflow`}
                                isMulti={true}
                                value={filterData.workflow}
                                api={() => getEvaluationFunctions(filterData?.workflow, null, null, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("workflow", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('estimationTransactionsRegister:procedures')}</label>
                </div>
                <div className='container'>
                    <div className='row  rowSmModel'>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:inspector')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-inspector`}
                                isMulti={true}
                                value={filterData.inspectors}
                                api={() => getFndUsers(filterData.inspectors, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("inspectors", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:estimator')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-estimator`}
                                isMulti={true}
                                value={filterData.estimator}
                                api={() => getFndUsers(filterData.estimator, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("estimator", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:created_by')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-created-by`}
                                isMulti={true}
                                value={filterData.createdBy}
                                api={() => getFndUsers(filterData.createdBy, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("createdBy", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:inspector_user')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-inspector-user`}
                                isMulti={true}
                                value={filterData.inspectorUser}
                                api={() => getFndUsers(filterData.inspectorUser, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("inspectorUser", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:inspection_auditor')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-inspection-auditor`}
                                isMulti={true}
                                value={filterData.inspectionAuditor}
                                api={() => getFndUsers(filterData.inspectionAuditor, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("inspectionAuditor", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:reviewer')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-reviewer`}
                                isMulti={true}
                                value={filterData.reviewer}
                                api={() => getFndUsers(filterData.reviewer, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("reviewer", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:reviewing_auditor')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-reviewing-auditor`}
                                isMulti={true}
                                value={filterData.reviewingAuditor}
                                api={() => getFndUsers(filterData.reviewingAuditor, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("reviewingAuditor", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransactionsRegister:approved_by')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-approved-by`}
                                isMulti={true}
                                value={filterData.approvedBy}
                                api={() => getFndUsers(filterData.approvedBy, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("approvedBy", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationTransactionsRegister:printed_by')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-printed-by`}
                                isMulti={true}
                                value={filterData.printedBy}
                                api={() => getFndUsers(filterData.printedBy, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={value => handleDataChange("printedBy", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationTransactionsRegisterFilter);