import ActionsButton from "@ipgd-gauge/actions-button";
import { isAllowedAction } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import PloicyIcon from "../../icons/PloicyIcon";
import { exportEstimationInvoiceRegister } from "../../util/apis";
import { PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID } from "../../util/constants";
import { EXPORT_ACTION } from "../../util/constants/actions";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import { openTotalTableSm } from "../../util/util";
import PloicyFilter from "../common/filters/policy-filter";
import EstimationInvoicesRegisterTable from "./listing";
import EstimationInvoicesRegisterFilter from "./listing/Filters";

const EstimationInvoicesRegister = () => {
    const { t } = useTranslation();

    const user = getUser();

    const filtersRef = useRef();
    const policyFilterRef = useRef();
    const estimationInvoicesRegisterTableRef = useRef();


    const _exportExcelAction = () => {
        const filterData = { ...filtersRef.current?.getFilterData(),  columnSetting: estimationInvoicesRegisterTableRef.current.getSelectedSettingId()};
        exportEstimationInvoiceRegister(filterData, "Estimation Invoices Register", "export-excel-sheet")
    }

    const _getActions = () => {
        const actions = [];
        if (isAllowedAction(user, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, EXPORT_ACTION)) {
            actions.push({ id: `export-excel-sheet`, icon: <ExcelIcon />, label: t('export') , action: _exportExcelAction});
        }

        return actions;
    }
    return (
        <>
            <div className="headerPagesPanelReportSm">
                <FormTitle formId={RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID} />

                <div className="headerSearchPanel policieHeaderSearchReprtsSm">
                    <div className="ploicyFilterContainer">
                        <PloicyFilter
                            id="estimation-invoices-register-policy-filter"
                            ref={policyFilterRef}
                            filterRef={filtersRef}
                            formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                            functionFormId={PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID}
                        />

                    </div>
                    <div className="reports-header-actions">
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('estimation-invoices-register-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                        <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                            <FiltersIcon />
                        </button>
                    </div>
                </div>
            </div>

            <EstimationInvoicesRegisterTable
                user={user}
                estimationInvoicesRegisterTableRef={estimationInvoicesRegisterTableRef}
                filtersRef={filtersRef}
            />

            <EstimationInvoicesRegisterFilter
                ref={filtersRef}
                estimationInvoicesRegisterTableRef={estimationInvoicesRegisterTableRef}
                policyFilterRef={policyFilterRef}
            />

            <ActionsButton actions={_getActions()} />
        </>
    );
}

export default EstimationInvoicesRegister;