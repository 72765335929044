import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, getUrlParam, isEmpty, isEnglish, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FrmDecimalInput } from '../../../../components/decimal-input';
import FrmGHDatePicker from "../../../../components/frm-g-h-date";
import Table from '../../../../components/table';
import AddHeaderBtnIcon from "../../../../icons/AddHeaderBtn";
import CardIcon from "../../../../icons/Card";
import DeleteTowIcon from "../../../../icons/Delete2";
import ListIcon from "../../../../icons/List";
import { getCitiesLov, getCommonPropertyTypes, getDomainValues, getEstimationDomains, getRegionsLov, getTownsList } from '../../../../util/apis';
import { AREA_LEVEL_DOMAIN, ESTIMATION_QUOTATION_APROACH_COST_FIELD, ESTIMATION_QUOTATION_AREA_LEVEL_FIELD, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD, ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD, ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD, ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD, ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD, ESTIMATION_QUOTATION_LICENCE_NUM_FIELD, ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD, ESTIMATION_QUOTATION_NET_PRICE_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD, ESTIMATION_QUOTATION_PRICE_FIELD, ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_PROPERTY_USE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_REM_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD, ESTIMATION_QUOTATION_VAT_FIELD, ESTIMATION_QUOTATION_VAT_PERC_FIELD, STATUS_ACTIVE } from '../../../../util/constants';
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../../util/constants/forms";
import { getFormUrl } from "../../../../util/formUtil";
import { useCalleeForm } from "../../../../util/hooks/useCalleeFrom";
import { getDynamicColumnsTableWidth, getDynamicTableColumns } from "../../../../util/util";
import DetailsSidePanel from "../../../common/dialogs/estimation-quotation-contract-detail-dilaog/DetailsSidePanel";
import Card from "../Card";
import style from '../style.module.scss';

const ID = "estimation-quotation";


const Details = (props) => {
    const { estimationQuotation, handleDataChanged, user, setEstimationQuotation } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();

    const { getData, isCallee: isRequisitionCallee, goToSourceForm: goToRequisitionForm, cancelCallee } = useCalleeForm(getFormUrl(RSM3190_EVALUATION_QUOTATIONS_FORM_ID), getFormUrl(RSM3190_EVALUATION_QUOTATIONS_FORM_ID));

    const [lovs, setLovs] = useState({
        areaLevels: [],
        regions: [],
        estimationReason: [],
        valueAssumption: [],
        propertyUse: [],
        estimationBase: []
    });
    const [cardMode, setCardMode] = useState(false);

    useEffect(() => {
        Promise.all([getRegionsLov(null, null, true),
        getDomainValues(AREA_LEVEL_DOMAIN),
        getEstimationDomains("1019"),
        getEstimationDomains("1071"),
        getEstimationDomains("1018"),
        getEstimationDomains("1026"),
        getCommonPropertyTypes(null, null, true, false, false, false, true, [])])
            .then((response) => {
                lovs.regions = response[0].data;
                lovs.areaLevels = response[1].data;
                lovs.estimationReason = response[2].data;
                lovs.valueAssumption = response[3].data;
                lovs.propertyUse = response[4].data;
                lovs.estimationBase = response[5].data;
                lovs.propertyTypes = response[6].data;
                for (let index = 0; index < estimationQuotation.lines.length; index++) {
                    const line = estimationQuotation.lines[index];
                    const region = lovs.regions.find(region => region.id == line.frsId);
                    const areaLevel = lovs.areaLevels.find(region => region.id == line.areaLevel);
                    const estimationReason = lovs.estimationReason.find(region => region.id == line.estimationReason);
                    const valueAssumption = lovs.valueAssumption.find(region => region.id == line.valueAssumption);
                    const propertyUse = lovs.propertyUse.find(region => region.id == line.propertyUse);
                    const estimationBase = lovs.estimationBase.find(region => region.id == line.estimationBase);
                    const propertyType = lovs.propertyTypes.find(region => region.id == line.myoId);
                    line.regionData = region;
                    line.areaLevelData = areaLevel;
                    line.estimationReasonData = estimationReason;
                    line.valueAssumptionData = valueAssumption;
                    line.propertyUseData = propertyUse;
                    line.estimationBaseData = estimationBase;
                    line.propertyTypeData = propertyType;
                    line.propertyUseData = propertyUse;
                }
                setEstimationQuotation({ ...estimationQuotation })
                setLovs({ ...lovs });
            })
            .catch((err) => {
                console.log("🚀 ~ useEffect ~ err:", err)

            });
    }, [])

    const columns = [
        { title: '#', width: '1%' },
        { title: t("estimationQuotation:desc"), width: '4%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD) },
        { title: <span data-property="deedNumber" className="mandatory">{t("estimationQuotation:instrument#")}</span>, width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD) },

        { title: <span data-property="titleDeedDgr" className="mandatory">{t("estimationQuotation:instrument_date")}</span>, width: '5%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) },
        { title: t("estimationQuotation:license_number"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_NUM_FIELD) },
        { title: t("estimationQuotation:license_date"), width: '5%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD) },
        { title: t("estimationQuotation:property_description"), width: '4%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD) },

        { title: <span data-property="frsId" className="mandatory">{t("estimationQuotation:region")}</span>, width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD) },
        { title: <span data-property="ecdId" className="mandatory">{t("estimationQuotation:city")}</span>, width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD) },
        { title: <span data-property="criId" className="mandatory">{t("estimationQuotation:town")}</span>, width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD) },
        { title: <span data-property="myoId" className="mandatory">{t("estimationQuotation:property_type")}</span>, width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD) },
        { title: t("estimationQuotation:area_level"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD) },
        { title: t("estimationQuotation:area_from"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) },
        { title: t("estimationQuotation:area_to"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) },
        { title: t("estimationQuotation:no_of_floors_from"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) },
        { title: t("estimationQuotation:no_of_floors_to"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD) },

        { title: t('estimationQuotation:cost_approch'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },
        { title: t('estimationQuotation:market_approch'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },
        { title: t('estimationQuotation:income_approch'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },


        { title: t('estimationQuotation:replacement_cost'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('estimationQuotation:comparison'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('estimationQuotation:income_capitalization_investment'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('estimationQuotation:remaining_method'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('estimationQuotation:discounted_cashflows'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('estimationQuotation:profits'), width: '2%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },


        { title: t("estimationQuotation:evaluation_purpose"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD) },
        { title: t("estimationQuotation:value_assumption"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD) },
        { title: t("estimationQuotation:usage_type"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_USE_FIELD) },
        { title: t("estimationQuotation:estimation_base"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD) },

        { title: t("estimationQuotation:price"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD) },
        { title: t("estimationQuotation:vat_perc"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD) },
        { title: t("estimationQuotation:vat"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD) },
        { title: t("estimationQuotation:total_price"), width: '3%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NET_PRICE_FIELD) },
        { title: t("estimationQuotation:remarks"), width: '4%', hidden: !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD) },
        { title: "", width: '4%' }
    ]

    const _getTableWidth = () => {
        const defaultTableWidth = 600;
        let totalHidden = 0;
        columns.forEach((column) => {
            if (column.hidden) {
                totalHidden += Number(column.width.replace('%', ''));
            }
        })
        let newTableWidth = defaultTableWidth - ((defaultTableWidth * totalHidden) / 100);
        console.log("🚀 ~ Details ~ newTableWidth:", newTableWidth)
        return newTableWidth < 100 ? 100 : newTableWidth;
    }

    const _deleteRow = (index) => {
        if (!estimationQuotation.lines) {
            estimationQuotation.lines = []
        }

        deleteItemByIndex(estimationQuotation.lines, index);
        handleDataChanged("lines", [...estimationQuotation.lines])
    }


    const editRow = (index) => {
        dialogRef.current.open(index)
    }

    const _deleteAllLines = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => handleDataChanged("lines", []));
    }

    const handleLineChange = (line, name, value) => {
        line[name] = value;

        if (name == "vatPercentage" || name == "price") {
            line.vat = ((parseNumber(line.vatPercentage)) / 100) * (parseNumber(line.price));
            line.netPrice = (parseNumber(line.vat) || 0) + (parseNumber(line.price) || 0);
        } else if (name == "frsId") {
            line.ecdId = null;
            line.frsId = value.id;
            line.regionData = value;
            line.cityData = null;
            line.townData = null;
            line.criId = null;
        } else if (name == "ecdId") {
            line.ecdId = value.id;
            line.cityData = value;
            line.criId = null;
            line.townData = null;

        } else if (name == "criId") {
            // line.ecdId = value.id;
            line.townData = value;
            line.criId = value.id;;
        }
        else if (name == "areaLevel") {
            line.areaLevel = value.id;
            line.areaLevelData = value

        }
        else if (name == "estimationReason") {
            line.estimationReason = value.id;
            line.estimationReasonData = value
        }
        else if (name == "valueAssumption") {
            line.valueAssumption = value.id;
            line.valueAssumptionData = value
        }
        else if (name == "propertyUse") {
            line.propertyUse = value.id;
            line.propertyUseData = value
        }
        else if (name == "estimationBase") {
            line.estimationBase = value.id;
            line.estimationBaseData = value
        }
        else if (name == "myoId") {
            line.myoId = value.id;
            line.propertyTypeData = value
        }
        else if (name == "aproachIncome") {
            line.methodInvestment = false;
            line.methodCashFlow = false;
            line.methodRemainValue = false;
            line.methodIncomeCaptal = false;
        }
        else if (name == "aproachMarket") {
            line.methodComparision = false;
        }
        else if (name == "aproachCost") {
            line.methodReplaceCost = false;

        }

        if (name == "price" || name == "vat" || name == "vatPercentage") {
            line.netPrice = (parseNumber(line.vat) || 0) + (parseNumber(line.price) || 0);
        }
    }

    const handleRowChange = (index, name, value) => {
        if (estimationQuotation.lines) {
            handleLineChange(estimationQuotation.lines[index], name, value)
            handleDataChanged("lines", [...estimationQuotation.lines])
        }
    }

    const _saveLine = (index, data) => {
        if (index < 0) {
            if (!estimationQuotation.lines) {
                estimationQuotation.lines = []
            }
            estimationQuotation.lines.push({ ...data })
        }
        else {
            estimationQuotation.lines[index] = { ...data }
        }
        handleDataChanged("lines", [...estimationQuotation.lines])
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('estimationQuotation:details')}
                    </label>
                    <div className={`tableHeaderActionsTable`}>
                        <button
                            // onClick={_handleAddRow}
                            onClick={() => dialogRef?.current?.open(-1)}
                        >
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t("add")}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllLines}
                            disabled={estimationQuotation.lines?.length == 0}
                            title={t('delete_all')}
                        >
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('delete_all')}
                            </label>
                        </button>
                        <button title={cardMode ? t("view_mode_listing") : t("view_mode_card")} className={style.cardBtn} onClick={() => setCardMode(!cardMode)}>
                            {cardMode &&
                                <ListIcon />
                                ||
                                <CardIcon />
                            }
                        </button>
                    </div>
                </div>
            </div>
            <Table
                id={`${ID}-details-table-${estimationQuotation?.umyId}-${estimationQuotation?.quotationType?.fields}`}
                key={estimationQuotation?.quotationType?.fields}
                columns={getDynamicTableColumns(columns)}
                Row={Row}
                rowHeight={40}
                list={estimationQuotation.lines || []}
                rowActions={{ handleRowChange, dialogRef, editRow, lovs, deleteRow: _deleteRow, estimationQuotation }}
                tableStyle={cardMode ? style.hideElement : style.tableStyle}
                // Total={() => <Total lines={(estimationQuotation.lines || [])} />}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                user={user}
                tableWidth={getDynamicColumnsTableWidth(columns)}
            />
            {/* <MyGrid
                //     data={estimationQuotation.lines || []}
                //     lovs={lovs}
                //     editRow={editRow}
                //     deleteRow={_deleteRow}
                //     estimationQuotation={estimationQuotation}
                //     isRequisitionCallee={isRequisitionCallee}
                        // />*/}
            {cardMode &&
                <div className={style.cardContainer}>
                    {estimationQuotation.lines.map((card, index) => {
                        return (
                            <>
                                <Card lovs={lovs} editRow={editRow} index={index} deleteRow={_deleteRow} estimationQuotation={estimationQuotation} data={card} />
                            </>
                        )
                    })}
                </div>
            }
            <DetailsSidePanel
                ref={dialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                masterData={estimationQuotation}
                saveAction={_saveLine}
                handleChange={handleLineChange}
                lovs={lovs}
            />
        </div>
    );
}

const Row = (props) => {
    const { virtualizedStyle, data, rowActions, index, onRowClick, rowClassName } = props
    const { sequence, id, newId } = data;
    const { handleRowChange, lovs, deleteRow, estimationQuotation, dialogRef, editRow } = rowActions;


    return (
        <div key={newId || id} dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} ${style.tableRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>
                <div>
                    {sequence}
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-description`}
                        value={data.description}
                        onBlur={value => handleRowChange(index, 'description', value)}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-deed-number`}
                        value={data.deedNumber}
                        onBlur={value => handleRowChange(index, 'deedNumber', value)}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmGHDatePicker
                        id={`${ID}-${index}-buy-instrument-date`}
                        disabledHijri={false}
                        onGregorianChange={(value) => handleRowChange(index, "titleDeedDgr", value)}
                        hijriDate={data?.titleDeedDhi}
                        gregorianDate={data?.titleDeedDgr}
                        onHijriChange={(value) => handleRowChange(index, "titleDeedDhi", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-license-number`}
                        value={data.licenceNum}
                        onBlur={value => handleRowChange(index, 'licenceNum', value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmGHDatePicker
                        id={`${ID}-${index}-license-date-d-h`}
                        gregorianDate={data?.licenceDateDgr}
                        onGregorianChange={(value) => handleRowChange(index, "licenceDateDgr", value)}
                        hijriDate={data?.licenceDateDhi}
                        disabledHijri={false}
                        onHijriChange={(value) => handleRowChange(index, "licenceDateDhi", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-property-description`}
                        value={data?.propertyDescription}
                        onBlur={(val) => handleRowChange(index, "propertyDescription", val)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-region`}
                        key={`${ID}-${index}-region-${data.id}`}
                        value={data.frsId}
                        onChange={value => handleRowChange(index, "frsId", value)}
                        api={() => getRegionsLov(isEmpty(data.frsId) ? [] : [data.frsId], STATUS_ACTIVE, true)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-city`}
                        key={`${ID}-${index}-city-${data.frsId}`}
                        value={data.ecdId}
                        onChange={value => handleRowChange(index, "ecdId", value)}
                        defualtValueId={data.ecdId}
                        defualtValueApi={() => getCitiesLov(isEmpty(data.ecdId) ? [] : [data.ecdId])}
                        api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, data.frsId, searchValue)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!data.frsId || !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD)}
                        isAsync={true}
                        portalTarget={document.body}
                        onDataRetrieved={(list) => {
                            const id = getUrlParam("id");
                            if (isNotEmpty(id) && data.ecdId) {
                                list?.forEach((element) => {
                                    if (element.id == data.ecdId) {
                                        handleRowChange(index, "cityData", { ...element })
                                        return;
                                    }
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-town`}
                        key={`${ID}-${index}-town-${data.ecdId}`}
                        value={data.criId}
                        onChange={value => handleRowChange(index, "criId", value)}
                        api={(searchKey) => getTownsList(searchKey, null, data.ecdId, STATUS_ACTIVE, true, null, true)}
                        defualtValueId={data.criId}
                        defualtValueApi={() => getTownsList(null, isEmpty(data.criId) ? [] : [data.criId], data.ecdId, STATUS_ACTIVE, true, null, true)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isAsync
                        isDisabled={!data.ecdId || !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD)}
                        portalTarget={document.body}
                        onDataRetrieved={(list) => {
                            const id = getUrlParam("id");
                            if (isNotEmpty(id) && data.criId) {
                                list?.forEach((element) => {
                                    if (element.id == data.criId) {
                                        handleRowChange(index, "townData", { ...element })
                                        return;
                                    }
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-type`}
                        value={data.myoId}
                        api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, false, true, isEmpty(data.myoId) ? [] : [data.myoId])}
                        onChange={value => handleRowChange(index, "myoId", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-area-level`}
                        value={data.areaLevel}
                        options={lovs.areaLevels}
                        onChange={value => handleRowChange(index, "areaLevel", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-area-from`}
                        value={data.landAreaFrom || ''}
                        onBlur={value => handleRowChange(index, "landAreaFrom", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-area-to`}
                        value={data.landAreaTo || ''}
                        onBlur={value => handleRowChange(index, "landAreaTo", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>


            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-no-of-floors-from`}
                        value={data.numberOfFloorsFrom || ''}
                        onBlur={value => handleRowChange(index, "numberOfFloorsFrom", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-no-of-floors-to`}
                        value={data.numberOfFloorsTo || ''}
                        onBlur={value => handleRowChange(index, "numberOfFloorsTo", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-cost-approch`}
                        isChecked={data?.aproachCost}
                        setIsChecked={value => handleRowChange(index, "aproachCost", value)}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-market-approch`}
                        isChecked={data?.aproachMarket}
                        setIsChecked={value => handleRowChange(index, "aproachMarket", value)}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-income-approch`}
                        isChecked={data?.aproachIncome}
                        setIsChecked={value => handleRowChange(index, "aproachIncome", value)}
                    />
                </div>
            </div>

            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-replacement-cost`}
                        isChecked={data?.methodReplaceCost}
                        setIsChecked={value => handleRowChange(index, "methodReplaceCost", value)}
                        disabled={!data?.aproachCost}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-comparison`}
                        isChecked={data?.methodComparision}
                        setIsChecked={value => handleRowChange(index, "methodComparision", value)}
                        disabled={!data?.aproachMarket}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-income-capitalization-investment`}
                        isChecked={data?.methodIncomeCaptal}
                        setIsChecked={value => handleRowChange(index, "methodIncomeCaptal", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-remaining-method`}
                        isChecked={data?.methodRemainValue}
                        setIsChecked={value => handleRowChange(index, "methodRemainValue", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-discounted-cashflows`}
                        isChecked={data?.methodCashFlow}
                        setIsChecked={value => handleRowChange(index, "methodCashFlow", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-profits`}
                        isChecked={data?.methodInvestment}
                        setIsChecked={value => handleRowChange(index, "methodInvestment", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-area-level`}
                        value={data.estimationReason}
                        options={lovs.estimationReason}
                        onChange={value => handleRowChange(index, "estimationReason", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-value-assumption`}
                        value={data.valueAssumption}
                        options={lovs.valueAssumption}
                        onChange={value => handleRowChange(index, "valueAssumption", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-use`}
                        value={data.propertyUse}
                        options={lovs.propertyUse}
                        onChange={value => handleRowChange(index, "propertyUse", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-use`}
                        value={data.estimationBase}
                        options={lovs.estimationBase}
                        onChange={value => handleRowChange(index, "estimationBase", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>


            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-price`}
                        value={data.price || ''}
                        onBlur={value => handleRowChange(index, "price", value)}
                        allowDecimals={true}
                        defaultValue={''}
                        minValue={0}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-vat-percentage`}
                        value={data.vatPercentage}
                        onBlur={value => handleRowChange(index, "vatPercentage", value)}
                        minValue={0}
                        allowDecimals={true}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-vat`}
                        value={parseNumber(data.vat)}
                        onBlur={value => handleRowChange(index, "vat", value)}
                        allowDecimals={true}
                        minValue={0}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-net-price`}
                        value={data.netPrice}
                        allowDecimals={true}
                        minValue={0}
                        disabled={true}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-rem`}
                        value={data.rem}
                        onBlur={value => handleRowChange(index, 'rem', value)}
                        disabled={!estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD)}
                    />
                </div>
            </div>
            <div>
                <div className={`${style.tableBtns} deleteBtnSm`}>
                    <EditBtn onClick={() => editRow(index)} />
                    <DeleteBtn onClick={() => deleteRow(index)} />
                </div>
            </div>
        </div>
    )
}


export default Details;