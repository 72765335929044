import { DeleteBtn } from '@ipgd-gauge/table';
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useRef } from 'react';
import { useTranslation } from "react-i18next";
import FormTitle from '../../components/form-title';
import MultiInsertDialog from '../../components/multi-insert-dialog';
import Table from "../../components/table";
import AddHeaderBtnIcon from '../../icons/AddHeaderBtn';
import DeleteTowIcon from '../../icons/Delete2';
import { getCommonCustomers } from '../../util/apis';
import { RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID } from '../../util/constants/forms';
import style from './style.module.scss';

const CustomersListRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}`} style={virtualizedStyle} onClick={onRowClick} >
            <div><div> <label>{data?.sequence}</label></div></div>
            <div><div> <label>{data?.code}</label></div></div>
            <div><div> <label>{isEnglish() ? data?.descLo : data?.descFo}</label></div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};
const CustomersListTable = (props) => {
    const { user, data, setData } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '10%' },
        { title: t('estimationFees:code'), width: '20%' },
        { title: t('estimationFees:name'), width: '55%' },
        { title: "", width: '15%' }
    ]

    const _handleDeleteLine = (index) => {
        if (!data.customers) {
            data.customers = [];
        }

        deleteItemByIndex(data.customers, index);
        setData({ ...data });
    }

    return (
        <Table
            id="customers-list-table"
            formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID}
            columns={columns}
            Row={CustomersListRow}
            rowHeight={50}
            tableStyle={style.customersListTable}
            list={data.customers || []}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            user={user}
        />
    );
}

const Tab2 = (props) => {
    const { tab, user, data, setData } = props;

    const { t } = useTranslation();
    const customerListRef = useRef();

    const _insertCustomers = (selectedData) => {
        data.customers = [...data.customers, ...selectedData];
        setData({ ...data });

        customerListRef.current.close();
    }

    const _deleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.customers = [];
            setData({ ...data });
        });
    }

    return (
        <>
            <div className={tab == 2 ? style.activeEstimation : style.inActiveEstimation}>
                <div className={style.header}>
                    <div className={style.title}>
                        <FormTitle formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID} />
                    </div>
                    <div className="tableHeaderActions">
                        <button
                            onClick={() => customerListRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('estimationFees:insert')}
                            </label>
                        </button>
                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAll}
                            disabled={data.customers.length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationFees:delete_all')}

                            </label>
                        </button>
                    </div>
                </div>
                <CustomersListTable user={user} data={data} setData={setData} />
            </div>

            <MultiInsertDialog
                id='customer-insert-dialog'
                ref={customerListRef}
                formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID}
                title={t('estimationFees:insert_customer')}
                okBtnAction={_insertCustomers}
                api={(searchValue) => getCommonCustomers([], null, searchValue, true, false, false, null, false, null)}
                searchPlaceholder={t('code') + " , " + t('name')}
                columns={[
                    { label: '', width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('code'), value: 'code', width: '30%' },
                    { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: '60%' }
                ]}
                isSmallSize
            />
        </>
    )
}

export default Tab2;