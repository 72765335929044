import ActionsButton from "@ipgd-gauge/actions-button";
import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { addUrlParam, checkValidation, confirmationDialog, handleError, hideActionWaiting, isAllowedAction, isNotEmpty, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../icons/AddBtn";
import ApproveIcon from "../../icons/Approve";
import DeleteTowIcon from "../../icons/Delete2";
import EditTableIcon from "../../icons/EditTable";
import { PdfTrxIcon } from "../../icons/EstimationTrx";
import NewWordIcon from "../../icons/NewWord";
import RejectIcon from "../../icons/Reject";
import SaveActionIcon from "../../icons/SaveAction";
import UnpostIcon from "../../icons/Unpost";
import ViewIcon from "../../icons/View";
import { approveValuationContract, deleteValuationContracts, rejectValuationContract, saveValuationContracts, unpostValuationContract } from "../../util/apis";
import { ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from '../../util/constants';
import { CONFIRM_FORM_ACTION, DELETE_ACTION, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION, UN_POST_ACTION, VIEW_EDIT_ACTION } from "../../util/constants/actions";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../util/constants/hotkeys";

const Actions = (props) => {
    const { user, isListing, data, setData, tableRef, newAction, printDialogRef, attachmentsImgRef, fromSaveActionRef } = props;
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [actions, setActions] = useState([]);
    const handleActionCompleted = () => {
        if (isListing) {
            tableRef?.current?.refresh();
        } else {
            navigate(`/valuation-contracts`);
        }
    }

    const _saveAction = (withSubmit) => {
        if (withSubmit && !checkValidation([{ id: 'valuation-contract-main' },
        {
            id: `valation-contract-details-table-${data?.umyId}-${data?.quotationType?.fields}`,
            title: t('estimationQuotation:details'),
            data: data.lines,
            disabledConditions: {
                deedNum: () => !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD),
                titleDeedDgr: () => !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD),
                frsId: () => !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD),
                ecdId: (lineData) => !lineData.frsId || !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD),
                criId: (lineData) => !lineData.ecdId || !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD),
                myoId: () => !data?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD),
            }
        }
        ], t)) {
            return
        }
        showActionWaiting(withSubmit ? 'submit' : 'incomplete');
        saveValuationContracts(data, withSubmit)
            .then(async (response) => {
                await attachmentsImgRef?.current?.uploadImages(response.data.id);
                for (let index = 0; index < data?.lines?.length; index++) {
                    const element = data?.lines[index];
                    response.data.lines[index] = { ...element, ...response.data?.lines[index] }
                }
                fromSaveActionRef.current = true;
                showSuccessMessage('save_successfully', t);
                setData({ ...data, ...response.data });
                addUrlParam('id', response.data.id);
            })
            .catch((error) => {
                handleError(null, error?.response?.data?.message.split('#')[0], t)
            })
            .finally(() => hideActionWaiting(withSubmit ? 'submit' : 'incomplete'));

    }

    const _deleteAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (!isListing) {
                showActionWaiting('delete');
            }
            return deleteValuationContracts(data.id)
                .then(() => {
                    if (!isListing) {
                        hideActionWaiting('delete')
                    }
                    handleActionCompleted();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, null, t))
        });
    }

    const _isAllowedSaveAction = () => {
        return !isListing && (data.globalStatus == GLOBAL_STATUS_INCOMPLETE || data.globalStatus == GLOBAL_STATUS_SUBMITTED) && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, SAVE_ACTION);
    }

    const _rejectAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _confirmAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return approveValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _unpostAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return unpostValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _getActions = () => {
        const tempActions = [];

        if (isListing && (data.globalStatus == GLOBAL_STATUS_SUBMITTED || data.globalStatus == GLOBAL_STATUS_CONFIRMED) && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, UN_POST_ACTION)) {
            tempActions.push({
                id: 'unpost',
                label: t('valuationContracts:unpost'),
                action: _unpostAction,
                color: "var(--action-save)",
                icon: <UnpostIcon />,
            });
        }

        if (isNotEmpty(data?.id) && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, PRINT_ACTION)) {
            tempActions.push({
                id: 'print-word',
                label: t('print_word'),
                action: () => printDialogRef?.current?.open([data?.id], null, true),
                color: "var(--pageination)",
                icon: <NewWordIcon />
            });

            tempActions.push({
                id: 'print-pdf',
                label: t('print_pdf'),
                action: () => printDialogRef?.current?.open([data?.id]),
                color: "var(--pageination)",
                icon: <PdfTrxIcon />
            });
        }

        if (data.globalStatus == GLOBAL_STATUS_INCOMPLETE && isNotEmpty(data?.id) && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, DELETE_ACTION)) {
            tempActions.push({
                id: 'delete',
                label: t('valuationContracts:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: _deleteAction,
                icon: <DeleteTowIcon />,
            });
        }

        if (data.globalStatus == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, REJECT_ACTION)) {
            tempActions.push({
                id: 'reject',
                label: t('valuationContracts:reject'),
                action: () => _rejectAction(),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
            });
        }
        if (data.globalStatus == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, CONFIRM_FORM_ACTION)) {
            tempActions.push({
                id: 'confirm',
                label: t('valuationContracts:confirm'),
                action: _confirmAction,
                color: "var(--action-save)",
                icon: <ApproveIcon />,
            });
        }

        if (isListing && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, VIEW_EDIT_ACTION)) {

            if (data.globalStatus == GLOBAL_STATUS_CONFIRMED || data.globalStatus == GLOBAL_STATUS_REJECTED) {
                tempActions.push({
                    id: 'view',
                    label: t('valuationContracts:view'),
                    action: () => navigate(`/valuation-contract?id=${data.id}`),
                    color: "var(--action-save)",
                    iconColor: "var(--green)",
                    icon: <ViewIcon />,
                });
            } else {
                tempActions.push({
                    id: 'edit',
                    label: t('valuationContracts:edit'),
                    action: () => navigate(`/valuation-contract?id=${data.id}`),
                    color: "var(--green)",
                    icon: <EditTableIcon />,
                });
            }
        }

        if (!isListing && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, NEW_ACTION)) {
            tempActions.push({
                id: 'new',
                label: t('valuationContracts:new'),
                action: () => newAction(),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
            });
        }

        if (!isListing && data.globalStatus == GLOBAL_STATUS_INCOMPLETE && isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, INCOMPLETE_ACTION)) {
            tempActions.push({
                id: 'incomplete',
                label: t('valuationContracts:incomplete'),
                action: () => _saveAction(false),
                color: "var(--action-incomplete)",
                icon: <SaveActionIcon />,
            })
        }

        if (_isAllowedSaveAction()) {
            tempActions.push({
                id: 'submit',
                label: t('valuationContracts:save'),
                action: () => _saveAction(true),
                color: "var(--action-save)",
                hotkey: SAVE_ACTION_SHORT_CUT,
                icon: <SaveActionIcon />,
            });
        }
        setActions(tempActions);

        if (isListing) {
            tempActions.reverse();
        }
    }

    useEffect(() => {
        _getActions();
    }, [i18next.language, data]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction(true);
        }
    }, { enableOnFormTags: true });

    if (isListing) {
        return <TableActionsButton defaultIndex={0} buttons={actions} />
    }

    return <ActionsButton actions={actions} />
}

export default Actions;