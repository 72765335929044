import Checkbox from "@ipgd-gauge/checkbox";
import Image from '@ipgd-gauge/image';
import { FrmSelectMenu, SelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from '@ipgd-gauge/spinner';
import { confirmationDialog, fixImagePath, handleError, hideWaiting, isEnglish, parseNumber, showWaiting } from '@ipgd-gauge/utils';
import { arrayMoveImmutable } from 'array-move';
import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Resizer from "react-image-file-resizer";
import InputRange from 'react-input-range-rtl';
import 'react-input-range/lib/css/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DatePickerIcon from '../../../../../icons/DatePicker';
import DeleteAttachmentIcon from '../../../../../icons/DeleteAttachment';
import DownloadIcon from '../../../../../icons/Download';
import { ElectricityIcon, FilledElectricityIcon } from '../../../../../icons/Electricity';
import ExcelIcon from '../../../../../icons/Excel';
import FavImgIcon from '../../../../../icons/FavImg';
import FavouriteImgIcon from '../../../../../icons/FavouriteImg';
import FullScreenIcon from '../../../../../icons/FullScreen';
import LeftArrow from '../../../../../icons/LeftArrow';
import MinusPreviewIcon from '../../../../../icons/MinusPreview';
import NewTabIcon from '../../../../../icons/NewTab';
import PdfIcon from '../../../../../icons/Pdf';
import PrevDownLoadIcon from '../../../../../icons/PrevDownLoad';
import PrevFullImgIcon from '../../../../../icons/PrevFullImg';
import PrevPlusIcon from '../../../../../icons/PrevPlus';
import { FillPreviewHomeIcon, PreviewHomeIcon } from '../../../../../icons/PreviewHome';
import PreviewImageIcon from '../../../../../icons/PreviewImage';
import ReOrderIcon from '../../../../../icons/ReOrder';
import { RotatIcon, RotatImageIcon } from '../../../../../icons/Rotat';
import RotateIcon from '../../../../../icons/Rotate';
import TxtIcon from '../../../../../icons/Txt';
import UpdateArrowIcon from '../../../../../icons/UploadArrow';
import UserProfileIcon from '../../../../../icons/UserProfile';
import VideoIcon from "../../../../../icons/Video";
import { FillWaterIcon, WaterIcon } from '../../../../../icons/Water';
import WordIcon from '../../../../../icons/Word';
import X from '../../../../../icons/X';
import { changeValue } from '../../../../../store/est-trnx-data/actions';
import { addTrxAttachment, deleteTrxAttachment, downloadAttachmentsCompress, downloadTransactionAttachment, getDomainValues, getTrxAttachment, rotateAttachments, sortAttachments, updateAttachmentFlag, updateAttachmentType } from '../../../../../util/apis';
import { DOMAIN_DOCUMENTS_TYPES } from '../../../../../util/constants';
import { formatDateNTime } from '../../../../../util/dateUtil';
import { resize_height, resize_width, splitArrayToChunks } from '../../../../../util/util';
import style from './style.module.scss';

const FILE = "file";
const OBJECT = "object";
const IMAGE_FLAG_ELECTRICITY = "ELECTRICITY";
const IMAGE_FLAG_WATER = "WATER";
const IMAGE_FLAG_FLAGGED = "FLAGGED";
const IMAGE_FLAG_MAIN_IMG = "MAIN_IMG";

const TRX_MODE_VIEW_ENTRY = "2"
const TRX_MODE_VIEW_INSPEC_N_ESTIMATION = "4"
const TRX_MODE_VIEW_APPROVAL = "6"
const TRX_MODE_VIEW_REVISION = "8"

const PREVENT_EDIT_ACTION_MODES = [TRX_MODE_VIEW_ENTRY, TRX_MODE_VIEW_INSPEC_N_ESTIMATION, TRX_MODE_VIEW_APPROVAL, TRX_MODE_VIEW_REVISION];

const DragHandle = SortableHandle(() => <span className={style.dragHandleContainer} >::</span>);

const SortableFile = SortableElement(({ file, fileIndex, setImgsFiles, handleDeleteImg, handleSelectImage, selectedImages, setImgPreview }) => {

    return (
        <li>
            <div className={style.multiImgBox} >
                <Image src={URL.createObjectURL(file)} />
                <div className={style.bottomActionsContainer} >
                    <button><RotatImageIcon /></button>
                    <button><FavouriteImgIcon /></button>
                </div>
                {/* <div className={style.topActionsContainer} >
                    <button
                        title={t('delete')}
                        aria-label={t('delete')}
                        onClick={() => handleDeleteImg(fileIndex)}
                    >
                        <X />
                    </button>
                    <DragHandle />
                    <Checkbox
                        id={`attachment-${fileIndex}`}
                        isChecked={selectedImages.filter(img => img.type == FILE && img.file == file).length > 0}
                        setIsChecked={() => handleSelectImage(file, FILE)}
                    />
                </div> */}
            </div>
        </li>
    )
}
);

const SortableFilesList = SortableContainer(({ files, setImgsFiles, handleDeleteImg, handleSelectImage, selectedImages, setImgPreview }) => {
    return (
        <ul className={style.sortableImagesContainer} >
            {files.map((file, index) => (
                <>
                    <SortableFile
                        key={`item-${index}`}
                        fileIndex={index}
                        index={index}
                        file={file}
                        setImgsFiles={setImgsFiles}
                        handleDeleteImg={handleDeleteImg}
                        handleSelectImage={handleSelectImage}
                        selectedImages={selectedImages}
                    />
                </>
            ))}
        </ul>
    );
});

const SortableImage = SortableElement(({ image, imageIndex, handleDeleteImg, handleSelectImage, selectedImages, getTrxKey, handleUpdateFlag, handleRotateAttachments, trxMode, handleClickOnImage }) => {
    const { t } = useTranslation();
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const _deleteImage = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () =>
            deleteTrxAttachment({ trxKey: getTrxKey(), imgKeys: [`${image.key}`] })
                .then(() => {
                    handleDeleteImg(imageIndex, image.filePath, true)
                })
                .catch((error) => {
                    handleError(error, null, t)
                })
        );
    }

    return (
        <li>
            <div key={`image-${imageIndex}`} className={style.multiImgBox}>
                {(image?.filePath?.includes(".doc") || image?.filePath?.includes(".docx") || image?.filePath?.includes(".pdf") || image?.filePath?.includes(".txt") || image?.filePath?.includes(".xlsx") || image?.filePath?.includes(".xls") || image?.filePath?.includes(".csv") || image?.filePath?.includes(".mp4") || image?.filePath?.includes(".mov") || image?.filePath?.includes(".avi") || image?.filePath?.includes(".wmv") || image?.filePath?.includes(".avchd") || image?.filePath?.includes(".webm") || image?.filePath?.includes(".flv")) ?
                    <div className={style.iconContainer} >
                        {
                            (image?.filePath?.includes(".doc") || image?.filePath?.includes(".docx")) &&
                            <WordIcon />
                        }
                        {
                            image?.filePath?.includes(".pdf") &&
                            <PdfIcon />
                        }
                        {
                            image?.filePath?.includes(".txt") &&
                            <TxtIcon />
                        }
                        {
                            (image?.filePath?.includes(".xlsx") || image?.filePath?.includes(".xls") || image?.filePath?.includes(".csv")) &&
                            <ExcelIcon color="green" />
                        }
                        {
                            (image?.filePath?.includes(".mp4") || image?.filePath?.includes(".mov") || image?.filePath?.includes(".avi") || image?.filePath?.includes(".wmv") || image?.filePath?.includes(".avchd") || image?.filePath?.includes(".webm") || image?.filePath?.includes(".flv")) &&
                            <VideoIcon />
                        }
                        {image?.name && <label>{image?.name.substring(0, image?.name.lastIndexOf('.'))}</label>}
                    </div>
                    :
                    image.filePath ?
                        <Image
                            src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(image.filePath)}`}
                            className={style.images}
                            alt={image.id}
                        />
                        :
                        <Spinner
                            id="image-view-spinner"
                            className={style.imgViewSpinner}
                        />
                }
                <div
                    className={image?.showSortLayer && selectedImages.filter(img => img.type == OBJECT && img.key == image.key).length == 0 ? style.sortLayer : style.openPreview}
                    onClick={() => handleClickOnImage(image, imageIndex)}
                >
                    <ReOrderIcon />
                </div>
                {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) && !image?.showSortLayer &&
                    <div className={style.bottomActionsContainer} >
                        <button onClick={() => handleRotateAttachments([image.key])}>
                            <RotatImageIcon />
                        </button>
                        <button onClick={() => handleUpdateFlag(image.key, IMAGE_FLAG_FLAGGED)}>
                            {image.flagged ? <FavImgIcon /> : <FavouriteImgIcon />}
                        </button>
                        <button onClick={() => downloadTransactionAttachment({ imgKey: image.key, trxKey: getTrxKey() }, image.name, true)}>
                            <PrevDownLoadIcon />
                        </button>
                        <a target={"_blank"} title="Open New Tab" href={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(image?.filePath)}/${image.name}`}>
                            <NewTabIcon />
                        </a>
                    </div>
                }
                <div className={style.multiImgNum}>{imageIndex + 1}</div>
                {(!image?.showSortLayer || (image?.showSortLayer && selectedImages.filter(img => img.type == OBJECT && img.key == image.key).length > 0)) &&
                    <div className={style.topActionsContainer} >
                        {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) && !image?.showSortLayer ?
                            <>
                                <button
                                    title={t('delete')}
                                    aria-label={t('delete')}
                                    onClick={_deleteImage}
                                >
                                    <X />
                                </button>
                                <DragHandle />
                            </>
                            :
                            <div></div>
                        }
                        <div>
                            <Checkbox
                                id={`ret-attachment-${imageIndex}`}
                                styleClass={selectedImages.filter(img => img.type == OBJECT && img.key == image.key).length > 0 ? style.selected : ''}
                                isChecked={selectedImages.filter(img => img.type == OBJECT && img.key == image.key).length > 0}
                                setIsChecked={() => handleSelectImage(image.key, OBJECT)}
                                disabled={image?.showSortLayer}
                            />
                        </div>
                    </div>
                }
            </div>
        </li>
    )
});

const SortableImagesList = SortableContainer(({ images, handleDeleteImg, handleSelectImage, selectedImages, handleUpdateFlag, handleRotateAttachments, getTrxKey, trxMode, handleClickOnImage }) => {
    return (
        <ul dir='ltr' className={!PREVENT_EDIT_ACTION_MODES.includes(trxMode) ? style.sortableImagesContainer : style.sortableImagesContainerView} >
            {images.map((image, index) => (
                <>
                    <SortableImage
                        key={`attachment-${index}`}
                        imageIndex={index}
                        index={index}
                        image={image}
                        handleDeleteImg={handleDeleteImg}
                        handleSelectImage={handleSelectImage}
                        selectedImages={selectedImages}
                        getTrxKey={getTrxKey}
                        handleRotateAttachments={handleRotateAttachments}
                        handleUpdateFlag={handleUpdateFlag}
                        trxMode={trxMode}
                        handleClickOnImage={handleClickOnImage}
                    />
                </>
            ))}
        </ul>
    );
});

const ImgPreview = forwardRef((props, ref) => {

    const { imgPreview, setImgPreview, nextImageAction, previousImageAction, getTrxKey, handleUpdateFlag, handleRotateAttachments, trxMode } = props;
    const { t } = useTranslation();
    const [openView, setOpenPreView] = useState(false);

    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const _fullScreen = () => {
        document.getElementById('imgsPreview').classList.remove(style.zoomIn)
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElementem.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
    const _zoomScreen = () => {
        const element = document.getElementById('imgsPreview');
        let newZoom = Number(element.style.getPropertyValue('--trxZoom') || 0);
        if (newZoom < 1 && newZoom > 0) {
            newZoom *= 2;
            if (newZoom >= 1) {
                newZoom = 0;
                element.classList.remove(style.zoomIn);
            }
        } else {
            newZoom += 1;
        }
        element.style.setProperty('--trxZoom', newZoom)
        if (!element.classList.contains(style.zoomIn) && newZoom > 0) {
            element.classList.add(style.zoomIn);
        }
    }

    const _zoomOutScreen = () => {
        const element = document.getElementById('imgsPreview');
        let newZoom = Number(element.style.getPropertyValue('--trxZoom') || 0);
        if (newZoom == 0) {
            newZoom = Number(1 / 2).toFixed(2);
        } else if (newZoom < 1 && newZoom > 0) {
            newZoom = Number(newZoom / 2).toFixed(2);
        } else {
            newZoom -= 1;
        }
        newZoom = Math.abs(newZoom);
        if (newZoom <= 0) {
            newZoom = 0;
            element.classList.remove(style.zoomIn);
        } else if (!element.classList.contains(style.zoomIn)) {
            element.classList.add(style.zoomIn);
        }
        element.style.setProperty('--trxZoom', newZoom);
    }

    const _nextAction = () => {
        const element = document.getElementById('imgsPreview');
        element.classList.remove(style.zoomIn);
        element.style.setProperty('--trxZoom', 0);
        if (nextImageAction) {
            nextImageAction();
        }
    }

    const _previousAction = () => {
        const element = document.getElementById('imgsPreview');
        element.classList.remove(style.zoomIn);
        element.style.setProperty('--trxZoom', 0);
        if (previousImageAction) {
            previousImageAction();
        }
    }

    const _handleChangeType = (type) => {
        let data = {
            trxKey: getTrxKey(),
            type: type,
            imgKey: imgPreview.key
        }
        updateAttachmentType(data)
            .then(() => {
                imgPreview.type = type;
                setImgPreview({ ...imgPreview })
            })
            .catch((error) => handleError(error, null, t));
    }

    const open = () => {
        setOpenPreView(true);
    }

    const close = () => {
        setOpenPreView(false);
        setImgPreview({});
        const element = document.getElementById('imgsPreview');
        element.classList.remove(style.zoomIn);
        element.style.setProperty('--trxZoom', 0);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <div className={openView ? style.imgPreviewActive : style.imgPreviewinActive}>
            <div className={style.infoSection}>
                <div className={style.imgType}>
                    <SelectMenu
                        id="type_attachment"
                        value={imgPreview?.type}
                        api={() => getDomainValues(DOMAIN_DOCUMENTS_TYPES)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleChangeType(e.value)}
                        portalTarget={document.body}
                        isDisabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    />
                </div>
                <div className={style.notifiFooter}>
                    <button
                        onClick={() => handleRotateAttachments([imgPreview.key])}
                        disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    >
                        <RotateIcon />
                    </button>
                    <button
                        onClick={() => handleUpdateFlag(imgPreview.key, IMAGE_FLAG_MAIN_IMG)}
                        disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    >
                        {imgPreview.mainImage ? <FillPreviewHomeIcon /> : <PreviewHomeIcon />}
                    </button>
                    <button
                        onClick={() => handleUpdateFlag(imgPreview.key, IMAGE_FLAG_ELECTRICITY)}
                        disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    >
                        {imgPreview.electricity ? <FilledElectricityIcon /> : <ElectricityIcon />}
                    </button>
                    <button
                        onClick={() => handleUpdateFlag(imgPreview.key, IMAGE_FLAG_WATER)}
                        disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    >
                        {imgPreview.water ? <FillWaterIcon /> : <WaterIcon />}
                    </button>
                    <button onClick={() => downloadTransactionAttachment({ imgKey: imgPreview.key, trxKey: getTrxKey() }, imgPreview.name, true)}>
                        <PrevDownLoadIcon />
                    </button>
                    <button
                        onClick={() => handleUpdateFlag(imgPreview.key, IMAGE_FLAG_FLAGGED)}
                        disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}
                    >
                        {imgPreview.flagged ? <FavImgIcon /> : <FavouriteImgIcon />}
                    </button>
                </div>
            </div>
            <div className={style.headerBtnAction}>
                <button onClick={_fullScreen}>
                    <FullScreenIcon />
                </button>
                <div className={style.closeContainer}>
                    <button className={style.closePreview} onClick={close}>
                        <X />
                    </button>
                    <a target={"_blank"} title="Open New Tab" href={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(imgPreview?.filePath)}/${imgPreview.name}`}>
                        <NewTabIcon />
                    </a>
                </div>
            </div>
            <div id='imgsPreview' className={style.imgPreview} style={{ '--trxZoom': 0 }}>
                {(imgPreview?.filePath?.includes(".doc") || imgPreview?.filePath?.includes(".docx") || imgPreview?.filePath?.includes(".pdf") || imgPreview?.filePath?.includes(".txt") || imgPreview?.filePath?.includes(".xlsx") || imgPreview?.filePath?.includes(".xls") || imgPreview?.filePath?.includes(".csv") || imgPreview?.filePath?.includes(".mp4") || imgPreview?.filePath?.includes(".mov") || imgPreview?.filePath?.includes(".avi") || imgPreview?.filePath?.includes(".wmv") || imgPreview?.filePath?.includes(".avchd") || imgPreview?.filePath?.includes(".webm") || imgPreview?.filePath?.includes(".flv")) ?
                    <div className={style.iconContainer} >
                        {(imgPreview?.filePath?.includes(".doc") || imgPreview?.filePath?.includes(".docx")) &&
                            <WordIcon />
                        }
                        {imgPreview?.filePath?.includes(".pdf") &&
                            <embed
                                src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(imgPreview?.filePath)}/${imgPreview.name}`}
                                width="100%"
                                height="100%"
                                type="application/pdf"
                                scrolling="auto"
                            />
                        }
                        {imgPreview?.filePath?.includes(".txt") &&
                            <TxtIcon />
                        }
                        {(imgPreview?.filePath?.includes(".xlsx") || imgPreview?.filePath?.includes(".xls") || imgPreview?.filePath?.includes(".csv")) &&
                            <ExcelIcon color="green" />
                        }
                        {(imgPreview?.filePath?.includes(".mp4") || imgPreview?.filePath?.includes(".mov") || imgPreview?.filePath?.includes(".avi") || imgPreview?.filePath?.includes(".wmv") || imgPreview?.filePath?.includes(".avchd") || imgPreview?.filePath?.includes(".webm") || imgPreview?.filePath?.includes(".flv")) &&
                            <video src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(imgPreview?.filePath)}`} controls />
                        }
                        {imgPreview?.name && <label>{imgPreview?.name.substring(0, imgPreview?.name.lastIndexOf('.'))}</label>}
                    </div>
                    :
                    <>
                        {imgPreview?.filePath ?
                            <img src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(imgPreview?.filePath)}`} />
                            :
                            <Spinner id="image-preview-spinner" className={style.imgPreviewSpinner} />
                        }
                    </>
                }
            </div>
            <button className={style.nextArrow} onClick={_nextAction}>
                <LeftArrow />
            </button>
            <button className={style.previousArrow} onClick={_previousAction}>
                <LeftArrow />
            </button>
            {!imgPreview?.filePath?.includes(".pdf") &&
                <div className={style.notifiLeft}>
                    <button onClick={_fullScreen}>
                        <PrevFullImgIcon />
                    </button>
                    <button onClick={_zoomScreen}>
                        <PrevPlusIcon />
                    </button>
                    <button onClick={_zoomOutScreen}>
                        <MinusPreviewIcon />
                    </button>
                </div>
            }
            <div className={style.footerBtnAction}>
                <div className={style.imgNum}>{imgPreview?.seq}</div>
                <div className={style.userSectionPreview}>
                    <div className={style.userImg}>
                        <UserProfileIcon />
                        <label>{isEnglish() ? imgPreview?.user?.nameLo : imgPreview?.user?.nameFo}</label>
                    </div>
                    <div className={style.userImg}>
                        <DatePickerIcon />
                        <div className={style.datePreview}>
                            <label>{formatDateNTime(imgPreview?.uploadDateTime)}</label>
                        </div>
                    </div>
                    <div className={style.userImg}>
                        <PreviewImageIcon />
                        <label>{imgPreview?.name}</label>
                    </div>
                </div>
            </div>
        </div>
    )
})

const ImageType = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [type, setType] = useState("130501");

    const getType = () => type;

    useImperativeHandle(ref, () => ({ getType }));

    return (
        <div className={style.imageTypeInput}>
            <label className='form-label'>{t('attachment_type')}</label>
            <FrmSelectMenu
                id="Attachment type"
                api={() => getDomainValues("1305")}
                descLo={["descLo"]}
                descFo={["descFo"]}
                value={type}
                onChange={(value) => setType(value.value)}
                portalTarget={document.body}
            />
        </div>
    )
})

const ImageRange = forwardRef((props, ref) => {

    const [range, setRange] = useState(60);

    const getRange = () => range;

    useImperativeHandle(ref, () => ({ getRange }))

    return (
        <div className={style.inputRangeContainer}>
            <label className='form-label'>{t('quality')}</label>
            <InputRange
                direction={isEnglish() ? "ltr" : "rtl"}
                maxValue={100}
                minValue={20}
                value={range}
                onChange={(value) => setRange(value)}
                step={20}
                classNames={{
                    activeTrack: `input-range__track input-range__track--active `,
                    disabledInputRange: 'input-range input-range--disabled',
                    inputRange: 'input-range',
                    labelContainer: style.inputRangeLableHide,
                    slider: `input-range__slider`,
                    sliderContainer: 'input-range__slider-container',
                    track: 'input-range__track input-range__track--background',
                    valueLabel: `input-range__label input-range__label--value ${style.inputRangeLable}`,
                }}
            />
        </div>
    )
})

const TrxUploudImages = ({ trxKey, getTrxKey }, ref) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const trxMode = useSelector(state => state.estTrnxReducer.data.TRX_MODE);
    const numOfAttachments = useSelector(state => parseNumber(state.estTrnxReducer.data.TRI_NUM_OF_ATTACH));

    const sortTimeOutRef = useRef();
    const sortedimagesRef = useRef([]);
    const imgPreviewRef = useRef([]);
    const selectedImgRef = useRef();
    const imageRangeRef = useRef();
    const imageTypeRef = useRef();
    const numberRef = useRef(0);
    const uploadedSuccessfullyCountRef = useRef(numOfAttachments);
    const sortableClickedRef = useRef(false);

    const [imgPreview, setImgPreview] = useState({});
    const [files, setFiles] = useState([]);
    const [imgsFiles, setImgsFiles] = useState([]);
    const [selectedImgs, setSelectedImgs] = useState([]);
    const [showWaitingNum, setShowWaitingNum] = useState(-1);

    const _getAttachments = () => {
        getTrxAttachment(getTrxKey())
            .then(re => {
                if (imgPreview?.key) {
                    let filtedImage = re.data?.filter((img) => img.key == imgPreview.key);
                    if (filtedImage?.length > 0) {
                        setImgPreview(filtedImage[0])
                    }
                }
                setImgsFiles(re.data)
            })
            .catch((error) => console.log("🚀  error", error));
    }

    const { getRootProps: getRootProps, getInputProps: getInputProps } = useDropzone({
        accept: ['image/*', '.pdf', '.doc', '.docx', '.txt', '.xlsx', '.xls', '.csv', 'video/*'],
        multiple: true,
        onDrop: (acceptedFile) => { _handleUpload(acceptedFile) },
        maxFiles: 10000,
        onDropRejected: (err => {
            console.log("🚀 ~ file: index.js ~ line 27 ~ UploadImg ~ err", err)
        })
    });

    useEffect(() => {
        _getAttachments();

        return () => {
            setImages([])
            setFiles([])
        };
    }, []);

    useEffect(() => {
        if (imgPreview?.filePath) {
            imgPreviewRef?.current.open()
        }
    }, [imgPreview?.filePath]);

    useEffect(() => {
        if (showWaitingNum >= 100) {
            hideWaiting(null)
            setTimeout(() => {
                setShowWaitingNum(-1);
                _getAttachments();
            }, 1000)
        }
    }, [showWaitingNum]);

    useEffect(() => {
        if (selectedImgs.length == 0 && sortableClickedRef.current) {
            _handleReorderAttachment()
        }
    }, [selectedImgs]);

    const resizeFile = (file) => {
        let range = imageRangeRef.current?.getRange();
        try {
            return new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    resize_width(range),
                    resize_height(range),
                    file.type.includes("png") ? "PNG" : "JPEG",
                    range,
                    0,
                    (uri) => {
                        resolve(uri);
                    },
                    "file"
                );
            });
        } catch (error) {
            console.log("🚀 ~ file: UploudImgDialog.js:35 ~ error", error);
        }
    }

    const _handleUpload = async (acceptedFiles) => {
        try {
            showWaiting(null, true);
            numberRef.current = 0;
            let type = imageTypeRef.current?.getType();
            const filesChunks = splitArrayToChunks(acceptedFiles, 20);
            for (let index = 0; index < filesChunks.length; index++) {
                if (index == 0) {
                    setShowWaitingNum(0);
                    numberRef.current += 1;
                    uploadedSuccessfullyCountRef.current = parseNumber(numOfAttachments);
                }
                await uploadFileChunks(filesChunks[index], type, acceptedFiles.length);
            }
            dispatch(changeValue("TRI_NUM_OF_ATTACH", uploadedSuccessfullyCountRef.current));
        } catch (error) {
            console.log("🚀  error:", error);
        }
    }

    const uploadFileChunks = async (files, type, filesLength) => {
        let promises = []
        for (let index = 0; index < files.length; index++) {
            promises.push(_uploadFile(files[index], type, filesLength));
        }
        await Promise.all(promises);
    }

    const _uploadFile = async (file, type, filesLength) => {
        try {
            if (file.type.includes("image") && (file.name.endsWith(".png") || file.name.endsWith(".jpeg"))) {
                let resizedimg = await resizeFile(file);
                if (resizedimg.size < file.size) {
                    file = resizedimg;
                }
            }
            await addTrxAttachment(getTrxKey(), file, type);
            uploadedSuccessfullyCountRef.current += 1;
        } catch (e) {
            console.log("🚀  e:", e);
        } finally {
            setShowWaitingNum(parseInt((numberRef.current / filesLength) * 100));
            numberRef.current += 1;
        }
    }

    const _handleDeleteImg = (index, filePath) => {
        if (filePath) {
            let newImgs = imgsFiles.filter((img) => img.filePath != filePath);
            setImgsFiles([...newImgs]);
        } else {
            files.splice(index, 1);
            setFiles([...files]);
        }
        dispatch(changeValue("TRI_NUM_OF_ATTACH", numOfAttachments - 1));
    }

    const _handleUpdateFlag = (imgKey, flagName) => {
        let data = {
            trxKey: getTrxKey(),
            imgKey: imgKey,
            flagName: flagName,
        }
        updateAttachmentFlag(data)
            .then(() => {
                imgsFiles.forEach((img) => {
                    if (img.key == imgKey) {
                        switch (flagName) {
                            case IMAGE_FLAG_ELECTRICITY:
                                img.electricity = !img.electricity;
                                break;
                            case IMAGE_FLAG_FLAGGED:
                                img.flagged = !img.flagged;
                                break;
                            case IMAGE_FLAG_MAIN_IMG:
                                img.mainImage = !img.mainImage;
                                break;
                            case IMAGE_FLAG_WATER:
                                img.water = !img.water;
                                break;
                        }
                    } else if (flagName == IMAGE_FLAG_MAIN_IMG) {
                        img.mainImage = false;
                    }
                })
                setImgsFiles([...imgsFiles])
            })
    }

    const _handleRotateAttachments = (imgKeys) => {
        let data = {
            imgKeys: imgKeys,
            trxKey: getTrxKey()
        }
        imgsFiles.forEach((img) => {
            if (imgKeys.includes(img.key)) {
                img.filePath = '';
            }
        })
        setImgsFiles([...imgsFiles]);
        rotateAttachments(data)
            .then(() => _getAttachments())
            .catch((error) => handleError(error, null, t));
    }

    const setImages = (files) => {
        if (typeof files === "string") {
            imgsFiles?.push(files);
            setImgsFiles([...imgsFiles]);
        } else {
            setImgsFiles(files);
            setFiles([]);
        }
    }

    const onSortFilesEnd = ({ oldIndex, newIndex }) => {
        let newOrderImgs = arrayMoveImmutable(files, oldIndex, newIndex);
        setFiles(newOrderImgs);
    }

    const onSortImagesEnd = ({ oldIndex, newIndex }) => {
        let newOrderImgs = arrayMoveImmutable(imgsFiles, oldIndex, newIndex);
        setImgsFiles(newOrderImgs);

        if (sortTimeOutRef.current) {
            clearTimeout(sortTimeOutRef.current);
        }
        let imgKeys = [];
        newOrderImgs.forEach((img) => imgKeys.push(img.key));
        sortedimagesRef.current = imgKeys;
        sortTimeOutRef.current = setTimeout(() => {
            let data = {
                imgIds: sortedimagesRef.current,
                trxKey: getTrxKey(),
            }
            sortAttachments(data)
                .catch(() => { })
        }, 2000);
    }

    const _handleSelectImage = (imageKey, type) => {
        let selectedImages = [...selectedImgs];
        if (type == OBJECT) {
            if (selectedImages.filter((img) => img.type == OBJECT && img.key == imageKey).length > 0) {
                selectedImages = selectedImages.filter((img) => (img.type == OBJECT && img.key != imageKey) || img.type == FILE);
            } else {
                selectedImages.push({ key: imageKey, type })
            }
        } else if (type == FILE) {
            if (selectedImages.filter((img) => img.type == FILE && img.file == imageKey).length > 0) {
                selectedImages = selectedImages.filter((img) => (img.type == FILE && img.file != imageKey) || img.type == OBJECT);
            } else {
                selectedImages.push({ file: imageKey, type })
            }
        }
        setSelectedImgs(selectedImages);
    }

    const _deleteSelectedAttachment = () => {
        if (selectedImgs.length > 0) {
            let newImgsFiles = imgsFiles;
            let newSelectedImgs = selectedImgs;
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, async () => {
                let data = {
                    trxKey: getTrxKey(),
                    imgKeys: [],
                }
                selectedImgs.forEach((image) => {
                    data.imgKeys.push(`${image.key}`)
                })
                return deleteTrxAttachment(data)
                    .then(() => {
                        selectedImgs.forEach((image) => {
                            newImgsFiles = newImgsFiles.filter((img) => img.key != image.key);
                            setImgsFiles(newImgsFiles);
                            newSelectedImgs = newSelectedImgs.filter((img) => (img.type == OBJECT && img.key != image.key) || img.type == FILE);
                            setSelectedImgs(newSelectedImgs);
                            dispatch(changeValue("TRI_NUM_OF_ATTACH", newImgsFiles.length - newSelectedImgs.length))
                        })
                    })
                    .catch((error) => handleError(error, null, t));
            })
        }
    }

    const _handleNextImage = () => {
        if (selectedImgRef.current == imgsFiles.length - 1) {
            setImgPreview(imgsFiles[0]);
            selectedImgRef.current = 0;
        } else {
            selectedImgRef.current = selectedImgRef.current + 1;
            setImgPreview(imgsFiles[selectedImgRef.current]);
        }
    }

    const _handlePreviousImage = () => {
        if (selectedImgRef.current == 0) {
            setImgPreview(imgsFiles[imgsFiles.length - 1]);
            selectedImgRef.current = imgsFiles.length - 1;
        } else {
            selectedImgRef.current = selectedImgRef.current - 1;
            setImgPreview(imgsFiles[selectedImgRef.current]);
        }
    }

    const _handleReorderAttachment = () => {
        if (sortableClickedRef.current) {
            sortableClickedRef.current = false
            imgsFiles.forEach((img) => {
                img.showSortLayer = false;
            })
        } else {
            sortableClickedRef.current = true
            imgsFiles.forEach((img) => {
                img.showSortLayer = true;
            })
        }
        setImgsFiles([...imgsFiles])
    }

    const _isSelectedImage = (imageKey) => {
        let selected = false;
        selectedImgs.map((img) => {
            if (img.key == imageKey) {
                selected = true;
            }
        })
        return selected;
    }

    const _handleClickOnImage = (selectedImage, imageIndex) => {
        if (sortableClickedRef.current) {
            if (selectedImgs.length == 0 || _isSelectedImage(selectedImage.key)) {
                return;
            }
            sortableClickedRef.current = false;
            let sortedImages = [];
            let imagesForSort = [];
            imgsFiles.map((img, index) => {
                img.showSortLayer = false;
                if (!_isSelectedImage(img.key)) {
                    sortedImages.push(img);
                } else {
                    imagesForSort.push(img);
                }
            })
            sortedImages.splice(imageIndex, 0, ...imagesForSort);
            let ids = sortedImages.map((i) => i.key);
            let data = {
                imgIds: ids,
                trxKey: getTrxKey(),
            }
            sortAttachments(data)
                .then(() => {
                    setImgsFiles(sortedImages);
                    setSelectedImgs([]);
                })
                .catch(() => { })
        } else {
            setImgPreview(selectedImage)
            selectedImgRef.current = imageIndex;
        }
    }

    const getImages = () => files;

    const refreshAttachments = () => {
        _getAttachments();
    }

    useImperativeHandle(ref, () => ({
        getImages,
        setImages,
        refreshAttachments,
    }))

    const isSelectedAll = () => {
        return selectedImgs?.length == imgsFiles?.length && selectedImgs?.length != 0 && imgsFiles?.length != 0;
    }

    const _handleSelectAll = () => {
        const allImages = [];
        if (selectedImgs?.length != imgsFiles?.length) {
            imgsFiles.map(img => {
                allImages.push({ key: img.key, type: OBJECT })
            })
        }
        setSelectedImgs(allImages)
    }

    return (
        <div id="block-content-0" className={`form-templates-panel block-content ${style.container}`}>
            <div className={`row`}>
                <div className="w100">
                    <div className={style.imagesActions} >
                        <div>
                            <Checkbox
                                id="select-all-transaction-images"
                                label={t('estimationTransaction:all')}
                                isChecked={isSelectedAll()}
                                setIsChecked={_handleSelectAll}
                            />
                        </div>
                        <div>
                            {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) &&
                                <button
                                    onClick={_handleReorderAttachment}
                                    disabled={selectedImgs.length == 0}
                                >
                                    <ReOrderIcon />
                                </button>
                            }
                            {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) &&
                                < button
                                    onClick={() => {
                                        const imgKeys = []
                                        selectedImgs.forEach(img => imgKeys.push(img.key))
                                        if (imgKeys.length > 0) {
                                            _handleRotateAttachments(imgKeys)
                                        }
                                    }}
                                    disabled={selectedImgs.length == 0}
                                >
                                    <RotatIcon />
                                </button>
                            }
                            <button
                                onClick={() => {
                                    const imgKeys = []
                                    selectedImgs.forEach(img => imgKeys.push(img.key))
                                    if (imgKeys.length > 0) {
                                        let data = {
                                            imgKeys: imgKeys,
                                            trxKey: getTrxKey()
                                        }
                                        downloadAttachmentsCompress(data, new Date().getTime())
                                    }
                                }
                                }
                                disabled={selectedImgs.length == 0}
                            ><DownloadIcon /></button>
                            {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) &&
                                <button
                                    onClick={_deleteSelectedAttachment}
                                    disabled={selectedImgs.length == 0}
                                >
                                    <DeleteAttachmentIcon />
                                </button>
                            }
                        </div>
                    </div>
                    <div className={style.imagesPanel}>
                        {!PREVENT_EDIT_ACTION_MODES.includes(trxMode) &&
                            <div className={style.uploadOneImg}>
                                {showWaitingNum >= 0 ?
                                    <>
                                        <Spinner
                                            id="upload-transaction-images-spinner"
                                            className={style.uploadImgSpinner}
                                        />
                                        <label className={style.uploadImgWitinglabel}>{showWaitingNum}%</label>
                                    </>
                                    :
                                    <div className={style.uploadContainer}>
                                        <ImageRange ref={imageRangeRef} />
                                        <ImageType ref={imageTypeRef} />
                                        <div className={style.uploadImgContainer}
                                            {...getRootProps({ /* className: style.uploadPanel */ })}
                                        /* onClick={() => uploadModalRef.current.open()} */
                                        >
                                            <label className='form-label'>{t('estimationTransaction:add_attachments')}</label>
                                            <div>
                                                <input {...getInputProps()} />
                                                <div>
                                                    <UpdateArrowIcon />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {imgsFiles.length > 0 &&
                            <SortableImagesList
                                images={imgsFiles}
                                handleDeleteImg={_handleDeleteImg}
                                onSortEnd={onSortImagesEnd}
                                handleSelectImage={_handleSelectImage}
                                selectedImages={selectedImgs}
                                axis="xy"
                                useDragHandle
                                getTrxKey={getTrxKey}
                                handleRotateAttachments={_handleRotateAttachments}
                                handleUpdateFlag={_handleUpdateFlag}
                                trxMode={trxMode}
                                handleClickOnImage={_handleClickOnImage}
                            />
                        }

                        {files.length > 0 &&
                            <SortableFilesList
                                files={files}
                                setImgsFiles={setImgsFiles}
                                handleDeleteImg={_handleDeleteImg}
                                onSortEnd={onSortFilesEnd}
                                handleSelectImage={_handleSelectImage}
                                selectedImages={selectedImgs}
                                axis="xy"
                                useDragHandle
                                imgPreview={imgPreview}
                                setImgPreview={setImgPreview}
                            />
                        }
                    </div>
                </div>
            </div>
            <ImgPreview
                ref={imgPreviewRef}
                imgPreview={imgPreview}
                setImgPreview={setImgPreview}
                nextImageAction={_handleNextImage}
                previousImageAction={_handlePreviousImage}
                getTrxKey={getTrxKey}
                handleRotateAttachments={_handleRotateAttachments}
                handleUpdateFlag={_handleUpdateFlag}
                trxMode={trxMode}
            />
        </div >
    )
}

export default forwardRef(TrxUploudImages);