import { formatMoney, isEnglish } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import XIcon from "../../../../icons/X";
import { RSM3250_VALUATION_REPORTS_FORM_ID } from "../../../../util/constants/forms";
import { closeTotalTableSm } from "../../../../util/util";
import style from "./style.module.scss";

const Row = (props) => {

    const { data } = props;
    const { t } = useTranslation();
    return (
        <div className={` reportsRowHeight ${style.employeesInspectorsExecutionPercentageReportRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <div>{data.sequence}</div>
            </div>

            <div title={isEnglish() ? data.partyNameLo : data.partyNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:party')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.partyNameLo : data.partyNameFo}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.messageTempltDescLo : data.messageTempltDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:task')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.messageTempltDescLo : data.messageTempltDescFo}
                    </label>
                </div>
            </div>

            <div title={data.noOfTasks} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:no_of_tasks')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.noOfTasks}
                    </label>
                </div>
            </div>

            <div title={data.noOfExecutedTasks} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:no_of_executed_tasks')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.noOfExecutedTasks}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.tasksPerc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:execution')}</label>
                <div>
                    <label className="overflowTableField">
                        {formatMoney(data.tasksPerc)}
                    </label>
                </div>
            </div>
        </div>
    );
}

const Total = ({ totals }) => {
    const { t } = useTranslation("estimationReports");

    return (
        <>
            <div id="employees-inspectors-execution-percentage-report-total" className="table-total" >
                <div className="hide-total-table-mobile"><div></div></div>
                <div><div className="tableTotalLabel">{t("total")}</div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:no_of_tasks')}</label>
                    <div id="employees-inspectors-execution-percentage-report-no-of-tasks" title={totals.noOfTasksSum}>{totals.noOfTasksSum}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:no_of_executed_tasks')}</label>
                    <div id="employees-inspectors-execution-percentage-report-no-of-executed-tasks" title={totals.noOfExecutedTasksSum}>{totals.noOfExecutedTasksSum}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:execution')}</label>
                    <div id="employees-inspectors-execution-percentage-report-execution" title={(totals.noOfExecutedTasksSum && totals.noOfTasksSum) ? formatMoney(totals.noOfExecutedTasksSum / totals.noOfTasksSum * 100) : 0}>{(totals.noOfExecutedTasksSum && totals.noOfTasksSum) ? formatMoney(totals.noOfExecutedTasksSum / totals.noOfTasksSum * 100) : 0}</div>
                </div>
                <button className="closeTotalMobile" onClick={() => closeTotalTableSm('employees-inspectors-execution-percentage-report-total')}>
                    <XIcon />
                </button>
            </div>
            <span id="employees-inspectors-execution-percentage-report-total-bg" className="totalMobileBg" onClick={() => closeTotalTableSm('employees-inspectors-execution-percentage-report-total')}></span>
        </>
    );
}

const TableEmployeesInspectorsExecutionPercentageReport = (props) => {

    const { user, estimationReportsTableRef, reports, getData, getTotals } = props;
    const { t } = useTranslation("estimationReports");
    const columns = [
        { title: '#', width: '5%' },
        { title: t('party'), width: '25%', excelColumn: "PARTY_NAME_LO,PARTY_NAME_FO" },
        { title: t('task'), width: '25%', excelColumn: "MESSAGE_TEMPLT_DESC_LO,MESSAGE_TEMPLT_DESC_FO" },
        { title: t('no_of_tasks'), width: '15%', excelColumn: "NO_OF_TASKS" },
        { title: t('no_of_executed_tasks'), width: '15%', excelColumn: "NO_OF_EXECUTED_TASKS" },
        { title: t('execution'), width: '15%', excelColumn: "TASKS_PERC" },
    ];

    const [totals, setTotals] = useState({});

    const _fetchData = (page, size) => {
        getData(page, size)
        getTotals().then(res => setTotals(res.data))
    }

    return (
        <Table
            ref={estimationReportsTableRef}
            id="table-employees-inspectors-execution-percentage-report"
            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.employeesInspectorsExecutionPercentageReportTable}
            withFilter={true}
            reportsTable
            pagingData={reports}
            getData={_fetchData}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            Total={(props) => <Total {...props} totals={totals} />}
            labelCardWidth="110px"
            user={user}
        />
    )
}

export default TableEmployeesInspectorsExecutionPercentageReport;