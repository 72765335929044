import { EditBtn, PrintBtn } from "@ipgd-gauge/table";
import { handleError, isAllowedAction, isEnglish } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { getCustomReportsList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID } from "../../../util/constants/forms";
import { formatDate } from "../../../util/dateUtil";
import style from './style.module.scss';
import Actions from "./actions";
import { getUser } from "../../../util/cookies";

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef, printDialogRef, tableRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesValuationCustomReportsRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.desc} className="table_body_label cardRowMode">
                <label className="nameLblMobile">{t('customReports:description')}</label>
                <label>{data.desc}</label>
            </div>

            <div title={formatDate(data.per1FromDateDgr)} className=" cardRowMode">
                <label className="nameLblMobile">{t('customReports:from_date_period_1')}</label>
                <label>{formatDate(data.per1FromDateDgr)}</label>
            </div>

            <div title={formatDate(data.per1ToDateDgr)} className=" cardRowMode">
                <label className="nameLblMobile">{t('customReports:to_date_period_1')}</label>
                <label>{formatDate(data.per1ToDateDgr)}</label>
            </div>

            <div title={formatDate(data.per2FromDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('customReports:from_date_period_2')}</label>
                <label>{formatDate(data.per2FromDateDgr)}</label>
            </div>

            <div title={formatDate(data.per2ToDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('customReports:to_date_period_2')}</label>
                <label>{formatDate(data.per2ToDateDgr)}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('customReports:party')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>
            <div>
                <Actions
                    user={user}
                    data={data}
                    printDialogRef={printDialogRef}
                    dialogRef={dialogRef}
                    tableRef={tableRef}
                />
            </div>
        </div>
    );
};

const PropertiesValuationCustomReportsTable = (props) => {

    const { user, tableRef, searchTextRef, dialogRef } = props;
    const { t } = useTranslation();
    const printDialogRef = useRef();

    const [customReports, setCustomReports] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('customReports:description'), width: "22%" },
        { title: t('customReports:from_date_period_1'), width: "12%" },
        { title: t('customReports:to_date_period_1'), width: "12%" },
        { title: t('customReports:from_date_period_2'), width: "12%" },
        { title: t('customReports:to_date_period_2'), width: "12%" },
        { title: t('customReports:party'), width: "15%" },
        { title: "", width: "10%" },
    ];

    const _fetchData = (page, size) => {
        getCustomReportsList(page, size, searchTextRef.current.getSearchText(), MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID)
            .then((Response) => setCustomReports(Response.data))
            .catch((error) => handleError(error, null, t))
    }

    return (
        <>
            <Slisting
                id="properties-valuation-custom-reports-table"
                ref={tableRef}
                formId={MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.propertiesValuationCustomReportsTable}
                getData={_fetchData}
                rowActions={{ user, dialogRef, printDialogRef, tableRef }}
                pagingData={customReports}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => dialogRef?.current?.open()}
                viewAction={(id) => dialogRef.current?.open(id)}
                withPrintAction
                user={getUser()}
                cardInMobile
                labelCardWidth="110px"
            />

            <PrintDialog
                ref={printDialogRef}
                id="print-property-valuation-custom-report"
                formId={MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID}
                user={user}
            />
        </>
    );
};

export default PropertiesValuationCustomReportsTable;