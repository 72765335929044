import FrmInput from "@ipgd-gauge/frm-input";
import LocalCurrencyInput from "@ipgd-gauge/local-currency";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from '@ipgd-gauge/table';
import { confirmationDialog, deleteItemByIndex, isEmpty, showNotificationToast } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ForeignCurrencyInput from "../../../components/foreign-currency";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExcelIcon from "../../../icons/Excel";
import { exportCustomLocations, getCitiesLov, getCountriesLov, getRegionsLov, getTownsList } from "../../../util/apis";
import { LOCATION_LEVEL_CITIES, LOCATION_LEVEL_COUNTRIES, LOCATION_LEVEL_REGIONS, LOCATION_LEVEL_TOWNS, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import style from './style.module.scss';
import { useRef } from "react";

const COMMON_ID = "price-agreements";

const LocationFessUpon = (props) => {
    const { data, setData, handleDataChange, lov, currentUser } = props;

    const { t } = useTranslation();
    const tableRef = useRef();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.priceListAgreementCustomLocations = [];
            setData({ ...data });
        });
    }

    const _insertAction = () => {
        data.priceListAgreementCustomLocations = [...(data.priceListAgreementCustomLocations || []), ...[{ isActive: STATUS_ACTIVE, operation: data.otherLocOperations, priceAfc: data.otherLocPriceAfc || 0, priceAlc: data.otherLocPriceAlc || 0 }]];
        setData({ ...data });
    }

    const _exportCustomLocations = () => {
        const unsavedLines = (data?.priceListAgreementCustomLocations || []).filter(line => isEmpty(line.id));
        if (unsavedLines?.length > 0) {
            showNotificationToast(t('priceAgreement:export_unsaved_records_notification'), { hideProgressBar: true, type: 'warning' })
        }
        exportCustomLocations(data.id, tableRef.current.getSelectedSettingId(), data.code || '');
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('priceAgreement:location_fess_upon')}
                    </label>
                    {data.id &&
                        <div>
                            <button
                                id={`locations-fees-export-btn`}
                                title={t('export')}
                                className={style.exportButton}
                                onClick={_exportCustomLocations}
                                disabled={(data?.priceListAgreementCustomLocations || [])?.length === 0}
                            >
                                <ExcelIcon color="green" />
                                <Spinner
                                    id={`locations-fees-export-btn-spinner`}
                                    className={style.spinnerButton}
                                />
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className={`w100 ${style.insertBtn}`}>
                        <div className={style.locationTableInputs}>
                            <div>
                                <label className='form-label'>{t('priceAgreement:other_location_prices')}</label>
                                <FrmSelectMenu
                                    id={`${COMMON_ID}-tab-3`}
                                    value={data.otherLocOperations}
                                    isClearable={true}
                                    options={lov.sign}
                                    descLo={['descLo']}
                                    descFo={['descFo']}
                                    onChange={value => handleDataChange("otherLocOperations", value.value)}
                                    portalTarget={document.body}
                                />

                            </div>
                            <div>
                                <label className='form-label'>{t('priceAgreement:price_fc')}</label>
                                <ForeignCurrencyInput
                                    id={`${COMMON_ID}-tab-3-price-afc`}
                                    value={data?.otherLocPriceAfc}
                                    onBlur={(value) => handleDataChange("otherLocPriceAfc", value)}
                                    changeAlcAmount={(value) => handleDataChange("otherLocPriceAlc", value)}
                                    currencyId={data.fcuId}
                                    exchngRate={data.exchngRate}
                                />
                            </div>
                            <div>
                                <label className='form-label'>{t('priceAgreement:price_lc')}</label>
                                <LocalCurrencyInput
                                    id={`${COMMON_ID}-tab-3-price-alc`}
                                    value={data?.otherLocPriceAlc}
                                    onBlur={(value) => handleDataChange("otherLocPriceAlc", value)}
                                />
                            </div>

                        </div>
                        <div className={style.locationTableBtns}>
                            <button className="tableHeaderActionsBtn"
                                onClick={_insertAction} title={t('priceAgreement:add')}>
                                <span>
                                    <AddHeaderBtnIcon />
                                </span>
                                <label>
                                    {t('priceAgreement:add')}
                                </label>
                            </button>

                            <button
                                className="tableHeaderDeleBtn"
                                onClick={_deleteAllAction}
                                title={t('priceAgreement:delete_all')}
                                disabled={(data?.priceListAgreementCustomLocations || [])?.length === 0}>
                                <span>
                                    <DeleteTowIcon />
                                </span>
                                <label>
                                    {t('priceAgreement:delete_all')}
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <LocationFessUponTable data={data} setData={setData} lov={lov} currentUser={currentUser} tableRef={tableRef} />
        </div>
    )
}

const LocationFessUponTable = (props) => {
    const { data, setData, lov, currentUser, tableRef } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('priceAgreement:location_level'), width: '12%', excelColumn: "LOCATION_LEVEL_DESC_LO,LOCATION_LEVEL_DESC_FO" },
        { title: t('priceAgreement:location'), width: '12%', excelColumn: "LOCATION_NAME_LO,LOCATION_NAME_FO" },
        { title: t('priceAgreement:operation'), width: isLocalCurrency(data?.fcuId) ? "16%" : '13%', excelColumn: "OPERATION_DESC_LO,OPERATION_DESC_FO" },
        { title: <span data-property="priceAfc" className="mandatory">{t('priceAgreement:price_fc')}</span>, width: isLocalCurrency(data?.fcuId) ? "0%" : '13%', excelColumn: "SIM_PRICE_AFC" },
        { title: <span data-property="priceAlc" className="mandatory">{t('priceAgreement:price_lc')}</span>, width: isLocalCurrency(data?.fcuId) ? "20%" : '15%', excelColumn: "SIM_PRICE_ALC" },
        { title: <span data-property="isActive" className="mandatory">{t('priceAgreement:status')}</span>, width: isLocalCurrency(data?.fcuId) ? "20%" : '15%', excelColumn: "STATUS_DESC_LO,STATUS_DESC_FO" },
        { title: t('priceAgreement:remarks'), width: '15%', excelColumn: "SIM_REM" },
        { title: "", width: '10%' }
    ];

    const _handleDeleteLine = (index) => {
        if (!data.priceListAgreementCustomLocations) {
            data.priceListAgreementCustomLocations = [];
        }

        deleteItemByIndex(data.priceListAgreementCustomLocations, index);
        setData({ ...data });
    }

    const _handleDataChange = (index, name, value) => {
        data.priceListAgreementCustomLocations[index][name] = value;

        if (name === "locationLevel") {
            data.priceListAgreementCustomLocations[index]["targetId"] = null;
        }

        setData({ ...data });
    }

    return (
        <Table
            id={`${COMMON_ID}-location-fess-upon-table`}
            columns={columns}
            ref={tableRef}
            Row={LocationFessUponRow}
            tableStyle={style.locationFessUponTable}
            list={data?.priceListAgreementCustomLocations || []}
            rowActions={{
                lov: lov,
                handleDataChange: _handleDataChange,
                handleDeleteLine: _handleDeleteLine,
                masterData: data
            }}
            formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
            user={currentUser}
            rowHeight={40}
        />
    );
}

const LocationLov = (props) => {
    const { index, data, handleDataChange, lov } = props;

    if (data.locationLevel == LOCATION_LEVEL_COUNTRIES) {
        return (
            <FrmSelectMenu
                id={`${COMMON_ID}-tab-3-countries-${index}`}
                key={`${COMMON_ID}-tab-3-countries-${index}-${data?.locationLevel}`}
                value={data?.targetId}
                onChange={value => handleDataChange(index, "targetId", value.value)}
                api={() => getCountriesLov([data?.targetId], STATUS_ACTIVE)}
                descLo={[`nameLo`]}
                descFo={[`nameFo`]}
                isDisabled={isEmpty(data?.locationLevel)}
                portalTarget={document.body}
            />
        );
    } else if (data.locationLevel == LOCATION_LEVEL_REGIONS) {
        return (
            <FrmSelectMenu
                id={`${COMMON_ID}-tab-3-countries-${index}`}
                key={`${COMMON_ID}-tab-3-countries-${index}-${data?.locationLevel}`}
                value={data?.targetId}
                onChange={value => handleDataChange(index, "targetId", value.value)}
                api={() => getRegionsLov([data?.targetId], STATUS_ACTIVE)}
                descLo={['descLo']}
                descFo={['descFo']}
                isDisabled={isEmpty(data?.locationLevel)}
                portalTarget={document.body}
            />
        );
    } else if (data.locationLevel == LOCATION_LEVEL_CITIES) {
        return (
            <FrmSelectMenu
                id={`${COMMON_ID}-tab-3-countries-${index}`}
                key={`${COMMON_ID}-tab-3-countries-${index}-${data?.locationLevel}`}
                value={data?.targetId}
                onChange={value => handleDataChange(index, "targetId", value.value)}
                defualtValueId={data.targetId}
                defualtValueApi={() => getCitiesLov(isEmpty(data?.targetId) ? [] : [data?.targetId])}
                api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, null, searchValue)}
                descLo={[`descLo`]}
                descFo={[`descFo`]}
                isDisabled={isEmpty(data?.locationLevel)}
                isAsync={true}
                portalTarget={document.body}
            />
        );
    } else if (data.locationLevel == LOCATION_LEVEL_TOWNS) {
        return (
            <FrmSelectMenu
                id={`${COMMON_ID}-tab-3-countries-${index}`}
                key={`${COMMON_ID}-tab-3-countries-${index}-${data?.locationLevel}`}
                value={data?.targetId}
                onChange={value => handleDataChange(index, "targetId", value.value)}
                defualtValueId={data?.targetId}
                defualtValueApi={() => getTownsList(null, isEmpty(data?.targetId) ? [] : [data?.targetId], null, STATUS_ACTIVE)}
                api={(searchKey) => getTownsList(searchKey, null, null, STATUS_ACTIVE)}
                descLo={[`nameLo`]}
                descFo={[`nameFo`]}
                isAsync={true}
                isDisabled={isEmpty(data?.locationLevel)}
                portalTarget={document.body}
            />
        );
    } else {
        return (<FrmSelectMenu id={`${COMMON_ID}-tab-3-countries-${index}`} isDisabled />);
    }
}

const LocationFessUponRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { lov, handleDataChange, handleDeleteLine, masterData } = rowActions;

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} `} style={virtualizedStyle} onClick={onRowClick}>
            <div> <div>{data?.sequence}</div></div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-tab-3-location-level-${index}`}
                        value={data?.locationLevel}
                        options={lov.locationLevel}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "locationLevel", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <LocationLov index={index} data={data} handleDataChange={handleDataChange} lov={lov} />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-tab-3-operation-${index}`}
                        value={data?.operation}
                        onChange={value => handleDataChange(index, "operation", value.value)}
                        options={lov.sign}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <ForeignCurrencyInput
                        id={`${COMMON_ID}-tab-3-price-afc-${index}`}
                        value={data?.priceAfc}
                        onBlur={(value) => handleDataChange(index, "priceAfc", value)}
                        changeAlcAmount={(value) => handleDataChange(index, "priceAlc", value)}
                        currencyId={masterData.fcuId}
                        exchngRate={masterData.exchngRate}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <LocalCurrencyInput
                        id={`${COMMON_ID}-tab-3-price-alc-${index}`}
                        value={data?.priceAlc}
                        onBlur={(value) => handleDataChange(index, "priceAlc", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-tab-3-status-${index}`}
                        value={data?.isActive}
                        options={lov.status}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "isActive", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${COMMON_ID}-tab-3-rem-${index}`}
                        value={data?.rem}
                        onBlur={value => handleDataChange(index, "rem", value)}
                    />
                </div>
            </div>

            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

export default LocationFessUpon;