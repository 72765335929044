const DB_NAME = "gauge";
const DB_VERSION = 2;

let gaugeDB = null;

function _openConnectionWithDB(onConnect) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = function (event) {
            reject('Database failed to open');
        };

        request.onsuccess = async function (event) {
            gaugeDB = event.target.result;
            if (onConnect) {
                await onConnect();
            }
            resolve();
        };

        request.onupgradeneeded = function (event) {
            gaugeDB = event.target.result;

            if (gaugeDB.objectStoreNames.contains("sessions")) {
                gaugeDB.deleteObjectStore("sessions");
            }

            const objectStore = gaugeDB.createObjectStore('sessions', { keyPath: 'id' });
            objectStore.createIndex('time', 'time', { unique: false });
            objectStore.createIndex('project', 'project', { unique: false });
        };
    });
}

const _insertRow = (key) => {
    const transaction = gaugeDB.transaction(['sessions'], 'readwrite');
    const objectStore = transaction.objectStore('sessions');

    const request = objectStore.add({ id: key, time: new Date().getTime(), project: "Valuation" });

    request.onerror = function () {
        console.error('Error adding data');
    };
}

export function addData(key) {
    if (gaugeDB === null) {
        _openConnectionWithDB(() => _insertRow(key));
        return;
    }

    _insertRow(key);
}

const _getRow = (key) => {
    const transaction = gaugeDB.transaction(['sessions'], 'readonly');
    const objectStore = transaction.objectStore('sessions');

    const request = objectStore.get(key);

    request.onerror = function () {
        console.error('Error retrieving record');
    };
}

export function getData(key) {
    let result = null;
    if (gaugeDB === null) {
        _openConnectionWithDB(() => {
            result = _getRow(key);
        });
        return;
    }

    result = _getRow(key);

    return result;
}

const _updateRow = (key) => {
    if (gaugeDB === null) {
        return;
    }
    const transaction = gaugeDB.transaction(['sessions'], 'readwrite');
    const objectStore = transaction.objectStore('sessions');

    const updateRequest = objectStore.put({ id: key, time: new Date().getTime(), project: "Valuation" });

    updateRequest.onerror = function () {
        console.error('Error updating data');
    };
}

export function updateData(key) {
    if (gaugeDB === null) {
        _openConnectionWithDB(() => _updateRow(key));
        return;
    }

    _updateRow(key);
}

const _deleteRow = (key) => {
    const transaction = gaugeDB.transaction(['sessions'], 'readwrite');
    const objectStore = transaction.objectStore('sessions');

    const request = objectStore.delete(key);

    request.onerror = function () {
        console.error('Error deleting data');
    };
}

export function deleteData(key) {
    if (gaugeDB === null) {
        _openConnectionWithDB(() => _deleteRow(key));
        return;
    }

    _deleteRow(key);
}

export function deleteDatabase() {
    const dbName = 'gauge';
    const request = indexedDB.deleteDatabase(dbName);

    request.onerror = function (event) {
        console.error('Error deleting database:', event);
    };

    request.onblocked = function (event) {
        console.warn('Database deletion is blocked:', event);
    };
}

const _getAllRows = () => {
    return new Promise((resolve, reject) => {
        const transaction = gaugeDB.transaction(['sessions'], 'readonly');
        const objectStore = transaction.objectStore('sessions');

        const request = objectStore.openCursor();
        const results = [];

        request.onsuccess = function (event) {
            let cursor = event.target.result;
            if (cursor) {
                if (cursor.value.project === "Valuation") {
                    results.push(cursor.value);
                }
                cursor.continue();
            } else {
                resolve(results);
            }
        };

        request.onerror = function (event) {
            reject('Error fetching records: ' + event.target.errorCode);
        };
    });
}

export async function getAllRecords() {
    let result = null;
    if (gaugeDB === null) {
        await _openConnectionWithDB();
        await _getAllRows()
            .then((response) => {
                result = response;
            })
            .catch((error) => console.error(error));
        return;
    } else {
        await _getAllRows()
            .then((response) => {
                result = response;
            })
            .catch((error) => console.error(error));
    }

    return result;
}