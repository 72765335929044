import { FrmSelectMenu } from '@ipgd-gauge/select-menu';
import { DeleteBtn } from "@ipgd-gauge/table";
import { isEnglish } from "@ipgd-gauge/utils";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MultiInsertDialog from '../../../../../components/multi-insert-dialog';
import Table from "../../../../../components/table";
import AddHeaderBtnIcon from "../../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../../icons/Delete2";
import { getCategories } from '../../../../../util/apis';
import { CUSTOMER_LEVEL_SPECIFIC, POLICY_LVL_GENERAL, STATUS_ACTIVE } from '../../../../../util/constants';
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from '../../../../../util/constants/forms';
import { ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES } from '../reducer';
import style from '../style.module.scss';

const CATEGORY_CONDITION = "dependsOnOrgTab";
const CustomerCategories = (props) => {
    const { user, data, lov, handleDataChanged, handleDeleteLine, handleDeleteAllLines, dispatchPropertyPolicy } = props;

    const { t } = useTranslation();
    const customerCategoriesDialogRef = useRef();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "20%" },
        { title: t('propertiesPolicies:name'), width: "60%" },
        { title: "", width: "15%" },
    ];

    const _insertCustomerCategories = (selectedData) => {
        const newLines = [];

        selectedData?.forEach(element => {
            newLines.push({
                strId: element.id,
                descLo: element.descLo,
                descFo: element.descFo,
                code: element.code
            });
        });

        dispatchPropertyPolicy({ type: ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES, name: "customerCategories", value: newLines })
        customerCategoriesDialogRef?.current?.close();
    }


    return (
        <div>
            <div className="frm-title-panel-border-toggle-table">
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:customer_categories')}
                    </label>
                </div>
                <div className={style.actionSections}>
                    <div className={style.inputSection}>
                        <label className='form-label' > {t('propertiesPolicies:level')}</label>
                        <FrmSelectMenu
                            id={`properties-policies-dialog-customer-categories`}
                            value={data.allCustCats}
                            options={lov?.customerLevel}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("allCustCats", value.value)}
                            isDisabled={!(data?.usageLevel == POLICY_LVL_GENERAL && data?.function?.allCustCat)}
                            portalTarget={document.body}
                        />
                    </div>

                    {data?.allCustCats == CUSTOMER_LEVEL_SPECIFIC &&
                        <div className="tableHeaderActionsOrg">
                            <button title={t('insert')} onClick={() => customerCategoriesDialogRef?.current.open()}>
                                <span><AddHeaderBtnIcon /></span>
                                <label>{t('insert')}</label>
                            </button>

                            <button
                                className="tableHeaderDeleBtn"
                                title={t('delete_all')}
                                onClick={() => handleDeleteAllLines('customerCategories')}
                                disabled={(data?.customerCategories || []).length === 0}>
                                <span><DeleteTowIcon /></span>
                                <label>{t('delete_all')}</label>
                            </button>
                        </div>
                    }
                </div>
            </div>

            {data?.allCustCats == CUSTOMER_LEVEL_SPECIFIC &&
                <>
                    <Table
                        id="policy-customer-categories-table"
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        columns={columns}
                        user={user}
                        Row={CustomerCategoriesRow}
                        tableStyle={style.customerCategoriesTable}
                        list={data?.customerCategories}
                        rowHeight={40}
                        rowActions={{ handleDeleteLine: handleDeleteLine }}
                    />

                    <MultiInsertDialog
                        id="properties-Policies-multi-insert-customer-categories-dialog"
                        ref={customerCategoriesDialogRef}
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        title={t('propertiesPolicies:insert_customer_categories')}
                        okBtnAction={_insertCustomerCategories}
                        api={(searchKey) => getCategories(searchKey, CATEGORY_CONDITION, data.general, data.organizations?.map((org) => org.fogId), STATUS_ACTIVE)}
                        selectedIds={(data?.customerCategories || []).map((line) => line.strId)}
                        searchPlaceholder={t('propertiesPolicies:code') + " , " + t('propertiesPolicies:name')}
                        columns={[
                            { width: '5%' },
                            { label: '#', width: '10%' },
                            { label: t('propertiesPolicies:code'), value: 'code', width: '25%' },
                            { label: t('propertiesPolicies:name'), valueLo: 'descLo', valueFo: 'descFo', width: '60%' },
                        ]}
                    />
                </>
            }
        </div>
    )
}

const CustomerCategoriesRow = (props) => {
    const { virtualizedStyle, data, rowActions, index, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.customerCategoriesRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data.sequence}</div></div>
            <div><div>{data.code}</div></div>
            <div><div>{isEnglish() ? data.descLo : data.descFo}</div></div>
            <div><div className='containerButtonTable deleteBtnSm'>
                <DeleteBtn onClick={() => handleDeleteLine(index, "customerCategories")} />
            </div>
            </div>
        </div>
    );
};

export default CustomerCategories;