import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { isEnglish } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../../../components/multi-insert-dialog";
import Table from "../../../../../components/table";
import AddHeaderBtnIcon from "../../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../../icons/Delete2";
import { getEvaluationPolicyPropertiesParties } from "../../../../../util/apis";
import { CUSTOMER_LEVEL_SPECIFIC, FORM_ESTIMATION_TRANSACTIONS_REGISTER_ERP_ID, POLICY_LVL_GENERAL } from "../../../../../util/constants";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../../util/constants/forms";
import { ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES } from "../reducer";
import style from '../style.module.scss';

const PropertiesParties = (props) => {
    const { user, data, lov, handleDataChanged, handleDeleteLine, handleDeleteAllLines, dispatchPropertyPolicy } = props;

    const { t } = useTranslation();
    const propertyPartyDialogRef = useRef();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "20%" },
        { title: t('propertiesPolicies:name'), width: "60%" },
        { title: "", width: "15%" },
    ]

    const _insertPropertiesParties = (selectedData) => {
        let newLines = [];

        selectedData?.forEach(element => {
            newLines.push({
                aprId: element.id,
                descLo: element.nameLo,
                descFo: element.nameFo,
                code: element.code
            });
        });

        dispatchPropertyPolicy({ type: ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES, name: "propertiesParties", value: newLines })
        propertyPartyDialogRef?.current?.close();
    }

    return (
        <div>
            <div className={`frm-title-panel-border-toggle-table ${style.actionHeaderSm}`}>
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:properties_parties')}
                    </label>
                </div>
                <div className={style.actionSections}>
                    <div className={style.inputSection}>
                        <label className='form-label' > {t('propertiesPolicies:level')}</label>
                        <FrmSelectMenu
                            id={`properties-policies-dialog-properties-parties`}
                            value={data.allPropParties}
                            onChange={value => handleDataChanged("allPropParties", value.value)}
                            options={lov?.customerLevel}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={!data?.usageLevel == POLICY_LVL_GENERAL}
                            portalTarget={document.body}
                        />
                    </div>

                    {data?.allPropParties == CUSTOMER_LEVEL_SPECIFIC &&
                        <div className="tableHeaderActionsOrg">
                            <button title={t('insert')} onClick={() => propertyPartyDialogRef?.current.open()}>
                                <span><AddHeaderBtnIcon /></span>
                                <label>{t('insert')}</label>
                            </button>

                            <button
                                className="tableHeaderDeleBtn"
                                title={t('delete_all')}
                                onClick={() => handleDeleteAllLines('propertiesParties')}
                                disabled={(data?.propertiesParties || []).length === 0}>
                                <span><DeleteTowIcon /></span>
                                <label>{t('delete_all')}</label>
                            </button>
                        </div>
                    }
                </div>
            </div>

            {data?.allPropParties == CUSTOMER_LEVEL_SPECIFIC &&
                <>
                    <Table
                        id="policy-properties-parties-table"
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        columns={columns}
                        user={user}
                        rowHeight={40}
                        Row={PropertiesPartiesRow}
                        tableStyle={style.propertiesPartiesTable}
                        list={data?.propertiesParties || []}
                        rowActions={{ handleDeleteLine }}
                    />

                    <MultiInsertDialog
                        id={`properties-policies-dialog-properties-parties`}
                        ref={propertyPartyDialogRef}
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        title={t('propertiesPolicies:properties_parties')}
                        okBtnAction={_insertPropertiesParties}
                        api={(searchKey) => data?.function?.ffrId == FORM_ESTIMATION_TRANSACTIONS_REGISTER_ERP_ID ? getEvaluationPolicyPropertiesParties(data.general, data.organizations?.map((org) => org.fogId), searchKey, true) : getEvaluationPolicyPropertiesParties(data.general, data.organizations?.map((org) => org.fogId), searchKey, false)}
                        selectedIds={(data.propertiesParties || []).map((line) => line.aprId)}
                        searchPlaceholder={t('propertiesPolicies:code') + " , " + t('propertiesPolicies:name')}
                        columns={[
                            { width: '5%' },
                            { label: '#', width: '10%' },
                            { label: t('propertiesPolicies:code'), value: 'code', width: '25%' },
                            { label: t('propertiesPolicies:name'), valueLo: 'nameLo', valueFo: 'nameFo', width: '60%' },
                        ]}
                    />
                </>
            }
        </div>
    )
}

const PropertiesPartiesRow = (props) => {
    const { virtualizedStyle, data, rowActions, index, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.propertiesPartiesRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data.sequence}</div></div>
            <div><div>{data.code}</div></div>
            <div><div>{isEnglish() ? data.nameLo : data.nameFo}</div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index, "propertiesParties")} />
                </div>
            </div>
        </div>
    );
};

export default PropertiesParties;