import { formatDate, formatMoney, handleError, isEnglish, parseNumber } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import ImportantTableIcon from "../../../icons/ImportantTable";
import PhoneIcon from "../../../icons/Phone";
import { deleteEstimationsQuotation, getValuationContractsList } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../../util/constants/forms";
import PrintDialog from "../.././../components/print-dialog";
import Actions from "../Actions";
import style from "./style.module.scss";
import { getUser } from "../../../util/cookies";

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef, t } = rowActions;

    return (
        <div className={`${style.valuationContractRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile"></label>
                <label>{data?.sequence}</label>
            </div>
            <div>
                <div className="actionTableMobile">
                    <button title={data.numberOfAttachments} style={{ position: 'relative' }}>
                        <ImportantTableIcon />
                        <span className={style.notifiction}>{parseNumber(data.numberOfAttachments)}</span>
                    </button>
                </div>

                <div className="hideActionMobile">
                    <button style={{ position: 'relative' }}>
                        <ImportantTableIcon />
                        <span className={style.notifiction}>{parseNumber(data.numberOfAttachments)}</span>
                    </button>
                </div>
            </div>

            <div className="columnSm">
                <div className="columnRowNew">
                    <label title={data?.code}>
                        {data?.code}
                    </label>
                    <label title={data.contractRefCode}>
                        {data.contractRefCode}
                    </label>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:code')}</label>
                    <div title={data?.code}>
                        {data?.code}
                    </div>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:contract_code')}</label>
                    <div title={data?.contractRefCode}>
                        {data?.contractRefCode}
                    </div>
                </div>
            </div>

            <div title={data?.quotationCode || t('not_found')} className="cardRowMode">
                <label className="nameLblMobile">{t('valuationContracts:quotation_num')}</label>
                <label>{data?.quotationCode || t('not_found')}</label>
            </div>

            <div className="columnSm">
                <div className="columnRowNew">
                    <label title={isEnglish() ? data?.officialNameLo : data?.officialNameFo}>
                        {isEnglish() ? data?.officialNameLo : data?.officialNameFo}
                    </label>
                    <label title={data.mobileNum} className="mobileTable">
                        {data.mobileNum &&
                            <>
                                <PhoneIcon />
                                <a href={`tel:${data.mobileNum}`}>{data.mobileNum}</a>
                            </>
                        }
                    </label>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:customer_name')}</label>
                    <div title={isEnglish() ? data?.officialNameLo : data?.officialNameFo}>
                        {isEnglish() ? data?.officialNameLo : data?.officialNameFo}
                    </div>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:mobile_num')}</label>
                    <div title={data.mobileNum} className="mobileTable">
                        <PhoneIcon /><a href={`tel:${data.mobileNum}`}>{data.mobileNum}</a>
                    </div>
                </div>
            </div>

            <div title={data?.projectName} className="cardRowMode">
                <label className="nameLblMobile">{t('valuationContracts:project_name')}</label>
                <label>{data?.projectName}</label>
            </div>
            <div title={formatMoney(data?.netPrice)} className="cardRowMode">
                <label className="nameLblMobile">{t('valuationContracts:net_price')}</label>
                <label>{formatMoney(data?.netPrice)}</label>
            </div>

            <div className="columnSm">
                <div className="columnRowNew">
                    <label>
                        <label title={data.isValid ? t("valuationContracts:valid") : t("valuationContracts:invalid")} className={data.isValid ? `status-dote-${STATUS_ACTIVE}` : `status-dote-${STATUS_IN_ACTIVE}`}>
                            {data.isValid ? t("valuationContracts:valid") : t("valuationContracts:invalid")}
                        </label>
                    </label>
                    <label title={formatDate(data.contToDateDgr)} >
                        {formatDate(data.contToDateDgr)}
                    </label>
                </div>

                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:validity_status')}</label>
                    <div>
                        <label title={data.isValid ? t("valuationContracts:valid") : t("valuationContracts:invalid")} className={data.isValid ? `status-dote-${STATUS_ACTIVE}` : `status-dote-${STATUS_IN_ACTIVE}`}>
                            {data.isValid ? t("valuationContracts:valid") : t("valuationContracts:invalid")}
                        </label>
                    </div>
                </div>

                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('valuationContracts:expiry_date')}</label>
                    <div title={formatDate(data.contToDateDgr)}>
                        {formatDate(data.contToDateDgr)}
                    </div>
                </div>
            </div>

            <div title={isEnglish() ? data.statusDescLo : data.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('valuationContracts:status')}</label>
                <label title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className={`status-${data?.globalStatus}`}>
                    {isEnglish() ? data?.statusDescLo : data?.statusDescFo}
                </label>
            </div>

            <div>
                <Actions
                    user={user}
                    isListing={true}
                    data={data}
                    tableRef={tableRef}
                    printDialogRef={printDialogRef}
                />
            </div>
        </div>
    )
}

const ValuationContractTable = (props) => {
    const { user, tableRef, filterRef, searchTextRef } = props;
    const { t } = useTranslation();
    const printDialogRef = useRef()
    const navigate = useNavigate();

    const [data, setData] = useState();

    const columns = [
        { title: '#', width: '4%' },
        { title: '', width: '2%' },
        { title: t('valuationContracts:code_contract_code'), width: '12%' },
        { title: t('valuationContracts:quotation_num'), width: '12%' },
        { title: t('valuationContracts:customer_name_mobile'), width: '14%' },
        { title: t('valuationContracts:project_name'), width: '14%' },
        { title: t('valuationContracts:net_price'), width: '12%' },
        { title: t('valuationContracts:expiry_status_date'), width: '10%' },
        { title: t('valuationContracts:status'), width: '10%' },
        { title: '', width: '10%' },
    ];

    const _fetchData = (page, size) => {
        getValuationContractsList(page, size, { ...filterRef?.current?.getFilterData(), searchKey: searchTextRef?.current?.getSearchText() })
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    }

    return (
        <>
            <Slisting
                id="valuation-contracts-listing"
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.valuationContractTable}
                withFilter
                pagingData={data}
                getData={_fetchData}
                rowActions={{ user, tableRef, printDialogRef, t }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => navigate("/valuation-contract")}
                deleteAction={deleteEstimationsQuotation}
                viewAction={(id) => navigate(`/valuation-contract?id=${id}`)}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                withPrintAction
                user={getUser()}
                cardInMobile
                labelCardWidth="80px"
            />

            <PrintDialog
                ref={printDialogRef}
                id="print-valuation-contracts"
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                user={user}
            />
        </>
    )
}

export default ValuationContractTable;