import Checkbox from "@ipgd-gauge/checkbox";
import { isEnglish } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDataChange, tableId } = rowActions;

    const { t } = useTranslation();

    return (
        <div
            dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>  <div><label>{data?.sequence}</label></div></div>
            <div>  <div><label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label></div></div>
            <div>  <div><label>{isEnglish() ? data?.fieldDescLo : data?.fieldDescFo}</label></div></div>
            <div>  <div><label>{isEnglish() ? data?.fieldUserDescLo : data?.fieldUserDescFo}</label></div></div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-show-in-approval-${index}`}
                        isChecked={data?.showInApproval}
                        setIsChecked={value => handleDataChange(index, "showInApproval", value)}
                        disabled={data.isSystemField}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:approval_visible')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-optional-approval-${index}`}
                        isChecked={data?.isOptionalApproval}
                        setIsChecked={value => handleDataChange(index, "isOptionalApproval", value)}
                        disabled={!data?.showInApproval || !data.systemIsOptional}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:approval_optional')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-disabled-approval-${index}`}
                        isChecked={data?.isDisabledApproval}
                        setIsChecked={value => handleDataChange(index, "isDisabledApproval", value)}
                        disabled={!data?.showInApproval || data.systemIsDisabled}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:approval_disabled')}
                    />
                </div>
            </div>
        </div>
    );
};

const Tab4 = (props) => {
    const { tab, user, fields, setFields, handleDataChange, isSelected } = props;

    const tableId = "estimation-forms-approval-settings";

    const { t } = useTranslation();

    const _handleChangeAllLines = (name, value) => {
        for (let index = 0; index < fields.length; index++) {

            if (name == "showInApproval" && !Boolean(fields[index].isSystemField)) {
                fields[index][name] = value;
            }

            if (name == "showInApproval" && !value) {
                if (Boolean(fields[index].systemIsOptional)) {
                    fields[index].isOptionalApproval = false;
                }
                if (!Boolean(fields[index].systemIsDisabled)) {
                    fields[index].isDisabledApproval = false;
                }
            }

            if (name == "isDisabledApproval" && !Boolean(fields[index].systemIsDisabled)) {
                if (!fields[index].showInApproval) {
                    fields[index][name] = false;
                } else {
                    fields[index][name] = value;
                }
            }

            if (name == "isOptionalApproval" && Boolean(fields[index].systemIsOptional) && fields[index].showInApproval) {
                fields[index][name] = value;
            }

        }
        setFields([...fields]);
    }

    const columns = [
        { title: '#', width: "4%" },
        { title: t('estimationFormsFieldsBuilder:block_name'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:field_user_name_a'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:description'), width: "17%" },
        {
            title:
                <Checkbox
                    id={`${tableId}-show-in-approval-all`}
                    label={t('estimationFormsFieldsBuilder:approval_visible')}
                    isChecked={isSelected("showInApproval")}
                    setIsChecked={value => _handleChangeAllLines("showInApproval", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-optional-approval-all`}
                    label={t('estimationFormsFieldsBuilder:approval_optional')}
                    isChecked={isSelected("isOptionalApproval")}
                    setIsChecked={value => _handleChangeAllLines("isOptionalApproval", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-disabled-approval-all`}
                    label={t('estimationFormsFieldsBuilder:approval_disabled')}
                    isChecked={isSelected("isDisabledApproval")}
                    setIsChecked={value => _handleChangeAllLines("isDisabledApproval", value)}
                />, width: "15%"
        },
    ]

    return (
        <div className={tab == 4 ? 'active' : ''}>
            <div className={style.valuationFormTableContainer}>
                <Table
                    id={tableId}
                    columns={columns}
                    user={user}
                    formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                    Row={Row}
                    tableStyle={style.valuationFormTable}
                    rowActions={{ handleDataChange: handleDataChange, tableId: tableId }}
                    rowHeight={40}
                    list={fields}
                    showWating={(data) => data == null}
                    showNoDataFound={(data) => (data && data.length == 0)}
                />
            </div>
        </div>
    );
};

export default Tab4;