import Checkbox from "@ipgd-gauge/checkbox";
import { isEnglish } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDataChange, tableId } = rowActions;
    const { t } = useTranslation();
    return (
        <div
            dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>  <div><label>{data?.sequence}</label> </div></div>
            <div>  <div><label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label> </div></div>
            <div>  <div><label>{isEnglish() ? data?.fieldDescLo : data?.fieldDescFo}</label> </div></div>
            <div>  <div><label>{isEnglish() ? data?.fieldUserDescLo : data?.fieldUserDescFo}</label> </div></div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-show-in-revision-${index}`}
                        isChecked={data?.showInRevision}
                        setIsChecked={value => handleDataChange(index, "showInRevision", value)}
                        disabled={data.isSystemField}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:revision_visible')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-optional-revision-${index}`}
                        isChecked={data?.isOptionalRevision}
                        setIsChecked={value => handleDataChange(index, "isOptionalRevision", value)}
                        disabled={!data?.showInRevision || !data.systemIsOptional}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:revision_optional')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-disabled-revision-${index}`}
                        isChecked={data?.isDisabledRevision}
                        setIsChecked={value => handleDataChange(index, "isDisabledRevision", value)}
                        disabled={!data?.showInRevision || data.systemIsDisabled}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:revision_disabled')}
                    />
                </div>
            </div>
        </div>
    );
};

const Tab3 = (props) => {
    const { tab, user, fields, setFields, handleDataChange, isSelected } = props;

    const tableId = "estimation-forms-revision-settings";

    const { t } = useTranslation();

    const _handleChangeAllLines = (name, value) => {
        for (let index = 0; index < fields.length; index++) {

            if (name == "showInRevision" && !Boolean(fields[index].isSystemField)) {
                fields[index][name] = value;
            }

            if (name == "showInRevision" && !value) {
                if (Boolean(fields[index].systemIsOptional)) {
                    fields[index].isOptionalRevision = false;
                }
                if (!Boolean(fields[index].systemIsDisabled)) {
                    fields[index].isDisabledRevision = false;
                }
            }

            if (name == "isDisabledRevision" && !Boolean(fields[index].systemIsDisabled)) {
                if (!fields[index].showInRevision) {
                    fields[index][name] = false;
                } else {
                    fields[index][name] = value;
                }
            }

            if (name == "isOptionalRevision" && Boolean(fields[index].systemIsOptional) && fields[index].showInRevision) {
                fields[index][name] = value;
            }

        }
        setFields([...fields]);
    }

    const columns = [
        { title: '#', width: "4%" },
        { title: t('estimationFormsFieldsBuilder:block_name'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:field_user_name_a'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:description'), width: "17%" },
        {
            title:
                <Checkbox
                    id={`${tableId}-show-in-revision-all`}
                    label={t('estimationFormsFieldsBuilder:revision_visible')}
                    isChecked={isSelected("showInRevision")}
                    setIsChecked={value => _handleChangeAllLines("showInRevision", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-optional-revision-all`}
                    label={t('estimationFormsFieldsBuilder:revision_optional')}
                    isChecked={isSelected("isOptionalRevision")}
                    setIsChecked={value => _handleChangeAllLines("isOptionalRevision", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-disabled-revision-all`}
                    label={t('estimationFormsFieldsBuilder:revision_disabled')}
                    isChecked={isSelected("isDisabledRevision")}
                    setIsChecked={value => _handleChangeAllLines("isDisabledRevision", value)}
                />, width: "15%"
        },
    ]

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <Table
                id={tableId}
                columns={columns}
                user={user}
                formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                Row={Row}
                tableStyle={style.valuationFormTable}
                rowActions={{ handleDataChange: handleDataChange, tableId: tableId }}
                rowHeight={40}
                list={fields}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
            />
        </div>
    );
};

export default Tab3;