import { DateFromToInput } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from '../../../components/status-select-menu';
import { getCommonBranches, getCommonDivisionsList, getDomainValuesWithCondition } from "../../../util/apis";
import { DOMAIN_GLOBAL_STATUS, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";

const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';
const BRANCH_CONDITION = 'dependsOnPolicyId';
const EstimatorsInspectorsInvoicesFilter = (props, ref) => {

    const { estimatorsInspectorsInvoicesTableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }))

    return (
        <AdvanceFilter
            id="estimatorsInspectorsInvoicesFilter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => estimatorsInspectorsInvoicesTableRef?.current?.refresh()}
            numOfRows={2}
            formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimatorsInspectorsInvoices:branch')}</label>
                            <FrmSelectMenu
                                id="branch"
                                isMulti={true}
                                value={filterData.branches}
                                api={() => getCommonBranches(null, filterData.branches, null, BRANCH_CONDITION)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("branches", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimatorsInspectorsInvoices:division')}</label>
                            <FrmSelectMenu
                                id="division"
                                isMulti={true}
                                value={filterData.divisions}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("divisions", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimatorsInspectorsInvoices:invoice_date_from_to')}</label>
                            <DateFromToInput
                                id="invoice_date_from_to"
                                fromValue={filterData?.dateFrom}
                                toValue={filterData?.dateTo}
                                fromOnChange={(value) => _handleDataChanged("dateFrom", value)}
                                toOnChange={(value) => _handleDataChanged("dateTo", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimatorsInspectorsInvoices:status')}</label>
                            <StatusSelectMenu
                                id="status"
                                isMulti={true}
                                value={filterData.statuses}
                                api={() => getDomainValuesWithCondition(DOMAIN_GLOBAL_STATUS, true, [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_REJECTED])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("statuses", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(EstimatorsInspectorsInvoicesFilter);