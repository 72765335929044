import { useTranslation } from "react-i18next";
import UploadImg from "../../../components/upload-img";
import { ESTIMATION_HISTORY } from "../../../util/constants";
import style from './style.module.scss';

const Tab3 = (props) => {
    const { tab, id, imageRef } = props;

    const { t } = useTranslation();

    return (
        <div className={`${tab == 3 ? `active ${style.step3}` : ''}`}>
            <UploadImg
                accept={["image/*", ".doc", ".docx", ".pdf"]}
                ref={imageRef}
                isMulti={true}
                title={t("upload")}
                masterId={id}
                formId={ESTIMATION_HISTORY}
            />
        </div>
    );
}

export default Tab3;