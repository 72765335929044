import { formatDate, formatMoney, isEnglish, parseNumber } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from "../../../../icons/EstimationMap";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED } from "../../../../util/constants";
import style from './style.module.scss';
import XIcon from "../../../../icons/X";



const Totals = (props) => {
    const { estimationQuotation } = props;
    const { t } = useTranslation("estimationQuotation");

    const price = (estimationQuotation?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.price), 0);
    const vat = (estimationQuotation?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.vat), 0);
    const netPrice = (estimationQuotation?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.netPrice), 0);

    return (
        <>
            <div className="frm-summary-card">
                <div className="summary-ref">
                    <div className={style.summaryCode}>
                        <div>#{estimationQuotation?.code}</div>
                    </div>
                    <div>
                        <label className="form-label">{t('date_g')}</label>
                        <div>{formatDate(estimationQuotation?.dateDgr) || '-'}</div>
                    </div>
                </div>

                {estimationQuotation.customerLevel == CUSTOMER_LEVEL_PREDEFINED && estimationQuotation?.customerData &&
                    <div className="desc-summary-container">
                        <label className="form-label">{t('customer')}</label>
                        <div>{isEnglish() ? estimationQuotation?.customerData?.descLo : estimationQuotation?.customerData?.descFo || '-'}</div>
                    </div>
                }

                {estimationQuotation.customerLevel == CUSTOMER_LEVEL_NEW && estimationQuotation?.customerName &&
                    <div className="desc-summary-container">
                        <label className="form-label">{t('customer_name')}</label>
                        <div>{estimationQuotation?.customerName || '-'}</div>
                    </div>
                }
            </div>
            <div className={style.totalsTrx}>
                <div>
                    <div className='totalsLbl'>
                        <label>{t('customersEstimationInvoices:count')}</label>
                    </div>
                    <div className='totalsVal'>
                        {(estimationQuotation?.lines || []).length || '-'}
                    </div>
                </div>
                <div>
                    <div className='totalsLbl'>
                        <label>{t('totlal_price')}</label>
                    </div>
                    <div className='totalsVal'>
                        {formatMoney(price) || '-'}
                    </div>
                </div>
                <div>
                    <div className='totalsLbl'>
                        <label>{t('vat')}</label>
                    </div>
                    <div className='totalsVal'>
                        {formatMoney(vat) || '-'}
                    </div>
                </div>
                <div>
                    <div className='totalsLbl'>
                        <label>{t('net_price')}</label>
                    </div>
                    <div className='totalsVal'>
                        {formatMoney(netPrice) || '-'}
                    </div>
                </div>
            </div>
        </>
    )
}

const Summary = (props) => {
    const { estimationQuotation , handleOpenSummary} = props;
    const { t } = useTranslation();

    return (
        <div className="summary-container-open-close">
            <div className="summary-header-title-open-close">
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('customersEstimationInvoices:summary')}</label>
                </div>
                <div className="attach-summary-container">
                    <button onClick={handleOpenSummary}>
                        <XIcon />
                    </button>
                </div>
            </div>
            <div className="summaryContainerWithTotal">
                <Totals estimationQuotation={estimationQuotation} />
            </div>
        </div>

    );
}

export default Summary;