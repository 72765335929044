import { DeleteBtn } from "@ipgd-gauge/table";
import { isEnglish } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../../../components/multi-insert-dialog";
import Table from "../../../../../components/table";
import AddHeaderBtnIcon from "../../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../../icons/Delete2";
import { getEvaluationPolicyActions } from "../../../../../util/apis";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../../util/constants/forms";
import { ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES } from "../reducer";
import style from '../style.module.scss';

const Actions = (props) => {
    const { user, data, handleDeleteLine, handleDeleteAllLines, dispatchPropertyPolicy } = props;

    const { t } = useTranslation();

    const actionDialogRef = useRef();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "20%" },
        { title: t('propertiesPolicies:name'), width: "60%" },
        { title: "", width: "15%" },
    ];

    const _insertActions = (selectedData) => {
        const newLines = [];
        selectedData?.forEach((element) => newLines.push({ ddvId: element.id, descLo: element.descLo, descFo: element.descFo }));
        dispatchPropertyPolicy({ type: ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES, name: "actions", value: newLines });
        actionDialogRef?.current?.close();
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle-table">
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:actions')}
                    </label>
                    <div className="tableHeaderActionsOrg">
                        <button title={t('insert')} onClick={() => actionDialogRef.current.open()}>
                            <span><AddHeaderBtnIcon /></span>
                            <label>{t('insert')}</label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={() => handleDeleteAllLines("actions")}
                            disabled={(data.actions || []).length === 0}>
                            <span><DeleteTowIcon /></span>
                            <label>{t('delete_all')}</label>
                        </button>
                    </div>
                </div>
            </div>

            <Table
                id="policy-actions-table"
                formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                columns={columns}
                user={user}
                Row={ActionsRow}
                rowHeight={40}
                tableStyle={style.actionsTable}
                list={data?.actions || []}
                rowActions={{ handleDeleteLine }}
            />

            <MultiInsertDialog
                id="properties-Policies-multi-insert-action-dialog"
                ref={actionDialogRef}
                formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                title={t('propertiesPolicies:insert_actions')}
                okBtnAction={_insertActions}
                api={getEvaluationPolicyActions}
                apiParams={data.ipeId}
                selectedIds={(data?.actions || []).map((line) => line.ddvId)}
                searchPlaceholder={`${t('propertiesPolicies:code')} , ${t('propertiesPolicies:name')}`}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '10%' },
                    { label: t('propertiesPolicies:code'), value: 'id', width: '25%' },
                    { label: t('propertiesPolicies:name'), valueLo: 'descLo', valueFo: 'descFo', width: '60%' }
                ]}
            />
        </div>
    );
}

const ActionsRow = (props) => {
    const { virtualizedStyle, data, rowActions, index, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;
    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.actionsRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data.sequence}</div></div>
            <div><div>{data.ddvId}</div></div>
            <div><div>{isEnglish() ? data.descLo : data.descFo}</div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index, "actions")} />
                </div>
            </div>
        </div>
    );
};

export default Actions;