import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const BranchesDivisionsRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.branchesDivisionsRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data?.sequence}</div></div>
            <div>
                <div title={isEnglish() ? data?.organizationDescLo : data?.organizationDescFo}>
                    <label>{isEnglish() ? data?.organizationDescLo : data?.organizationDescFo}</label>
                </div>
            </div>
            <div>
                <div title={isEnglish() ? data?.branchDescLo : data?.branchDescFo}>
                    <label>{isEnglish() ? data?.branchDescLo : data?.branchDescFo}</label>
                </div>
            </div>
            <div>
                <div title={isEnglish() ? data?.divisionDescLo : data?.divisionDescFo}>
                    <label>{isEnglish() ? data?.divisionDescLo : data?.divisionDescFo}</label>
                </div>
            </div>
            <div>
                <div>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

const BranchesDivisionsTable = (props) => {
    const { user, data, setData } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.branchesNDivisionsList) {
            data.branchesNDivisionsList = [];
        }

        deleteItemByIndex(data.branchesNDivisionsList, index);
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: <span className="form-label mandatory">{t('propertiesUsersDefinition:organization')}</span>, width: "25%" },
        { title: <span className="form-label mandatory">{t('propertiesUsersDefinition:branch')}</span>, width: "30%" },
        { title: <span className="form-label mandatory">{t('propertiesUsersDefinition:division')}</span>, width: "30%" },
        { title: "", width: "10%" }
    ];

    return (
        <Table
            id="branches-divisions-table"
            columns={columns}
            Row={BranchesDivisionsRow}
            tableStyle={style.branchesDivisionsTable}
            rowHeight={40}
            list={data?.branchesNDivisionsList || []}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            user={user}
            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
        />
    );
}

const Tab2 = (props) => {
    const { tab, data, setData, user, multiInsertDialogRef } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.branchesNDivisionsList = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('propertiesUsersDefinition:insert')}
                            onClick={() => multiInsertDialogRef?.current?.open()}
                        >
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('propertiesUsersDefinition:insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            title={t('propertiesUsersDefinition:delete_all')}
                            disabled={(data?.branchesNDivisionsList || []).length === 0}
                        >
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('propertiesUsersDefinition:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            <BranchesDivisionsTable user={user} data={data} setData={setData} />
        </div>
    )
}

export default Tab2;