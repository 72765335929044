import { formatMoney, isEnglish } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import XIcon from "../../../../icons/X";
import { RSM3250_VALUATION_REPORTS_FORM_ID } from "../../../../util/constants/forms";
import { closeTotalTableSm } from "../../../../util/util";
import style from "./style.module.scss";

const Row = (props) => {
    const { data } = props;

    return (
        <div className={` reportsRowHeight ${style.employeesInspectorsReportRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <div>{data.sequence}</div>
            </div>

            <div title={isEnglish() ? data.partyNameLo : data.partyNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:party')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.partyNameLo : data.partyNameFo}
                    </label>
                </div>
            </div>

            <div title={data.count} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:no_trxs')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.count}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.execFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:fees_commissions')}</label>
                <div>
                    <label className="overflowTableField">
                        {formatMoney(data.execFeesAlc)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.hoursCount)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:no_hours')}</label>
                <div>
                    <label className="overflowTableField">
                        {formatMoney(data.hoursCount)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.avg)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationReports:average')}</label>
                <div>
                    <label className="overflowTableField">
                        {formatMoney(data.avg)}
                    </label>
                </div>
            </div>
        </div>
    );
}

const Total = ({ totals }) => {
    const { t } = useTranslation("estimationReports");

    return (
        <>
            <div id="employees-inspectors-report-total" className="table-total" >
                <div className="hide-total-table-mobile"><div></div></div>
                <div><div className="tableTotalLabel">{t("total")}</div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:no_trxs')}</label>
                    <div id="employees-inspectors-report-no-trxs" title={totals.sum}>{totals.sum}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:fees_commissions')}</label>
                    <div id="employees-inspectors-report-fees-commissions" title={formatMoney(totals.execFeesAlcSum)}>{formatMoney(totals.execFeesAlcSum)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:no_hours')}</label>
                    <div id="employees-inspectors-report-no-hours" title={formatMoney(totals.hoursCountSum)}>{formatMoney(totals.hoursCountSum)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationReports:average')}</label>
                    <div id="employees-inspectors-report-average" title={formatMoney(totals.avgSum)}>{formatMoney(totals.avgSum)}</div>
                </div>
                <button className="closeTotalMobile" onClick={() => closeTotalTableSm('employees-inspectors-report-total')}>
                    <XIcon />
                </button>
            </div>
            <span id="employees-inspectors-report-total-bg" className="totalMobileBg" onClick={() => closeTotalTableSm('employees-inspectors-report-total')}></span>
        </>
    );
}

const TableEmployeesInspectorsReport = (props) => {
    const { user, estimationReportsTableRef, reports, getData, getTotals } = props;

    const { t } = useTranslation("estimationReports");

    const columns = [
        { title: '#', width: '3%' },
        { title: t('party'), width: '20%', excelColumn: "PARTY_NAME_LO,PARTY_NAME_FO" },
        { title: t('no_trxs'), width: '20%', excelColumn: "TRX_COUNT" },
        { title: t('fees_commissions'), width: '20%', excelColumn: "MSE_EXEC_FEES_ALC" },
        { title: t('no_hours'), width: '20%', excelColumn: "HOURS_COUNT" },
        { title: t('average'), width: '17%', excelColumn: "TRX_AVG" },
    ];

    const [totals, setTotals] = useState({});

    const _fetchData = (page, size) => {
        getData(page, size);
        getTotals().then(res => setTotals(res.data));
    }

    return (
        <Table
            ref={estimationReportsTableRef}
            id="employees-inspectors-report-table"
            columns={columns}
            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
            Row={Row}
            withFilter={true}
            reportsTable
            tableStyle={style.employeesInspectorsReportTable}
            pagingData={reports}
            getData={_fetchData}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            Total={(props) => <Total {...props} totals={totals} />}
            user={user}
            labelCardWidth="110px"
        />
    );
}

export default TableEmployeesInspectorsReport;