import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { deleteItemByIndex } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { getDomainValues, getEstimationDictionaryList } from "../../../util/apis";
import { TASK_SUBJECT_TYPE_DOMAIN, TASK_SUBJECT_TYPE_DOMAIN_FIELD, TASK_SUBJECT_TYPE_DOMAIN_TEXT } from "../../../util/constants";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import { confirmationDialog, isEnglish } from "../../../util/util";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const dialogId = "estimation-messages-templates-dialog";

const MessageBodyRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine, handleDataChanged } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data?.sequence}</div></div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        value={data.type}
                        api={() => getDomainValues(TASK_SUBJECT_TYPE_DOMAIN)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "type", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-message-body-text-${index}`}
                        value={data?.text}
                        onBlur={(value) => handleDataChanged(index, "text", value)}
                        disabled={data.type != TASK_SUBJECT_TYPE_DOMAIN_TEXT}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-message-body-field-${index}`}
                        value={data.sdiId}
                        api={() => getEstimationDictionaryList()}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "sdiId", value.value)}
                        isDisabled={data.type != TASK_SUBJECT_TYPE_DOMAIN_FIELD}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};


const MessageBodyTable = (props) => {
    const { data, setData, user } = props;

    const { t } = useTranslation('estimationMessagesTemplates');

    const _handleDeleteLine = (index) => {
        if (!data.estimationMessageBodys) {
            data.estimationMessageBodys = [];
        }

        deleteItemByIndex(data.estimationMessageBodys, index)
        setData({ ...data });
    }

    const _handleDataChanged = (index, name, value) => {
        if (!data.estimationMessageBodys) {
            data.estimationMessageBodys = [];
        }

        if (name === "type") {
            data.estimationMessageBodys[index].text = null;
            data.estimationMessageBodys[index].sdiId = null;
        }

        data.estimationMessageBodys[index][name] = value;
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "3%" },
        { title: <span data-property="type" className="mandatory">{t('type')}</span>, width: "29%" },
        { title: <span data-property="text" className="mandatory">{t('text')}</span>, width: "29%" },
        { title: <span data-property="sdiId" className="mandatory">{t('field')}</span>, width: "29%" },
        { title: "", width: "10%" }
    ];

    return (
        <Table
            id={`${dialogId}-message-body-table`}
            columns={columns}
            list={data?.estimationMessageBodys || []}
            rowActions={{ handleDataChanged: _handleDataChanged, handleDeleteLine: _handleDeleteLine }}
            Row={MessageBodyRow}
            rowHeight={40}
            tableStyle={style.messageBodyTable}
            user={user}
            formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
        />
    );
}


const Tab3 = (props) => {
    const { tab, data, setData, user } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.estimationMessageBodys = [];
            setData({ ...data });
        });
    }

    const _insertAction = () => {
        if (!data.estimationMessageBodys) {
            data.estimationMessageBodys = [];
        }

        data.estimationMessageBodys = [...data.estimationMessageBodys, ...[{}]];
        setData({ ...data });
    }

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('add')}
                            onClick={_insertAction}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('add')}
                            </label>
                        </button>
                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            title={t('estimationMessagesTemplates:delete_all')}
                            disabled={(data?.estimationMessageBodys || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationMessagesTemplates:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

            <MessageBodyTable data={data} setData={setData} user={user} />
        </div>
    )
}

export default Tab3;