import { DeleteBtn } from "@ipgd-gauge/table";
import { useTranslation } from "react-i18next";
// import AccountSelectMenu from "../../../components/select-menu/account-select-menu";
import AccountSelectMenu from "../../../components/account-select-menu";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import { isEnglish } from "../../../util/util";
import style from './style.module.scss';

const dialogId = "additional-items-dialog";

const Tab2 = (props) => {
    const { tab, data, expenseAccount, locationGroupsDialogRef, handleDeleteAllLines, handleDeleteLine, handleLineDataChange, user, formId } = props;

    const { t } = useTranslation('additionalItems');

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('insert')}
                            onClick={() => locationGroupsDialogRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={() => handleDeleteAllLines('locationGroups')}
                            disabled={(data?.locationGroups || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>{t('delete_all')}</label>
                        </button>
                    </div>
                </div>
            </div>

            <LocationGroupsFinancialTable
                data={data}
                expenseAccount={expenseAccount}
                handleDeleteLine={handleDeleteLine}
                handleLineDataChange={handleLineDataChange}
                user={user}
                formId={formId}
            />
        </div>
    )
}

const LocationGroupsFinancialTable = (props) => {
    const { data, expenseAccount, handleDeleteLine, handleLineDataChange, user, formId } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('additionalItems:group'), width: "32%" },
        { title: t('additionalItems:type'), width: "32%" },
        { title: <span data-property="gstId" className="mandatory">{t('additionalItems:account')}</span>, width: "21%" },
        { title: "", width: "10%" },
    ];

    return (
        <div className={style.locationGroupsFinancialTableContainer}>
            <Table
                id="location-groups-financial-table"
                columns={columns}
                Row={LocationGroupsFinancialRow}
                tableStyle={style.locationGroupsFinancialTable}
                rowHeight={40}
                list={data.locationGroups || []}
                rowActions={{ handleDeleteLine: handleDeleteLine, handleLineDataChange: handleLineDataChange, expenseAccount: expenseAccount }}
                user={user}
                formId={formId}
            />
        </div>
    );
}

const LocationGroupsFinancialRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine, handleLineDataChange, expenseAccount } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} ` } style={virtualizedStyle} onClick={onRowClick}>
            <div title={data.sequence}> <div>{data.sequence}</div></div>
            <div title={`${isEnglish() ? data?.groupDescLo : data?.groupDescFo}`}> <div>{isEnglish() ? data?.groupDescLo : data?.groupDescFo}</div></div>
            <div title={isEnglish() ? data?.typeDescLo : data?.typeDescFo}><div>{isEnglish() ? data?.typeDescLo : data?.typeDescFo}</div></div>
            <div>
                <div className="inputRow">
                    <AccountSelectMenu
                        id={`${dialogId}-location-groups-financial-table-account-${index}`}
                        value={data.gstId}
                        options={expenseAccount.gstGroups}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        onChange={value => handleLineDataChange(index, "locationGroups", value.value)}
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>
            <div>
                <div className="containerButtonTable deleteBtnSm">
                    <DeleteBtn onClick={() => handleDeleteLine(index, "locationGroups")} />
                </div>
            </div>
        </div>
    );
};

export default Tab2;