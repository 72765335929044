import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const FieldsRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} ${style.fieldsRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div title={data?.sequence}><div>{data?.sequence}</div></div>
            <div title={isEnglish() ? data?.descLo : data?.descFo}><div>{isEnglish() ? data?.descLo : data?.descFo}</div></div>
            <div>
                <div className="containerButtonTable deleteBtnSm">
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};
const FieldsTable = (props) => {
    const { dialogId, data, setData, user } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '7%' },
        { title: <span className="mandatory">{t('estimationQuotationsTypes:fields')}</span>, width: '75%' },
        { title: '', width: '18%' },
    ]

    const _handleDeleteLine = (index) => {
        if (!data.fields) {
            data.fields = [];
        }

        deleteItemByIndex(data.fields, index);
        setData({ ...data });
    }

    return (
        <Table
            id={`${dialogId}-fields-table`}
            columns={columns}
            formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
            Row={FieldsRow}
            tableStyle={style.fieldsTable}
            list={data?.fields || []}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            rowHeight={50}
            user={user}
        />
    );
}

const Tab2 = (props) => {
    const { tab, dialogId, data, setData, user, fieldsRef } = props;

    const { t } = useTranslation();

    const _handleDeleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.fields = []
            setData({ ...data })
        });
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('estimationQuotationsTypes:insert')}
                            onClick={() => fieldsRef?.current?.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('estimationQuotationsTypes:insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_handleDeleteAll}
                            title={t('estimationQuotationsTypes:delete_all')}
                            disabled={(data?.fields || [])?.length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationQuotationsTypes:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            <FieldsTable dialogId={dialogId} data={data} setData={setData} user={user} />
        </div>
    )
}

export default Tab2;