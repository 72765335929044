import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEnglish } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const REF_CODE_ID = 18

const Row = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDataChange, tableId, status, refCodeFeilds, levels } = rowActions;
    const { t } = useTranslation();
    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} `} style={virtualizedStyle} onClick={onRowClick}>
            <div> <div><label>{data?.sequence}</label></div></div>
            <div> <div> <label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label> </div></div>
            <div> <div> <label>{isEnglish() ? data?.fieldDescLo : data?.fieldDescFo}</label> </div></div>
            <div> <div> <label>{isEnglish() ? data?.fieldUserDescLo : data?.fieldUserDescFo}</label> </div></div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${tableId}-level-${index}`}
                        options={levels.current?.get(data.blockId)}
                        descFo={["label"]}
                        descLo={["label"]}
                        value={data?.fieldSequence}
                        onChange={value => handleDataChange(index, "fieldSequence", value.value)}
                        isDisabled={data.blockId == 1}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-viewed-in-tasks-${index}`}
                        isChecked={data?.isViewedInTasks}
                        setIsChecked={value => handleDataChange(index, "isViewedInTasks", value)}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:view_in_task')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-searchable-${index}`}
                        isChecked={data?.isSearchable}
                        setIsChecked={value => handleDataChange(index, "isSearchable", value)}
                        disabled={!data.systemIsSearchable}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:searchable')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-unique-${index}`}
                        isChecked={data?.isUnique}
                        setIsChecked={value => handleDataChange(index, "isUnique", value)}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:duplication_alert')}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        isDisabled={data.fieldId != REF_CODE_ID}
                        options={refCodeFeilds}
                        id={`${tableId}-ref-code-${index}`}
                        value={data?.refCode}
                        onChange={value => handleDataChange(index, "refCode", value.value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${tableId}-default-value-${index}`}
                        value={data?.defaultValue}
                        onBlur={value => handleDataChange(index, "defaultValue", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${tableId}-status-${index}`}
                        options={status}
                        value={data?.isActive}
                        onChange={value => handleDataChange(index, "isActive", value.value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    );
};

const Tab5 = (props) => {
    const { tab, user, fields, setFields, handleDataChange, isSelected, status, refCodeFeilds, levelMenus } = props;

    const tableId = "estimation-forms-other-settings";

    const { t } = useTranslation();

    const _handleChangeAllLines = (name, value) => {
        for (let index = 0; index < fields.length; index++) {

            if (name === "isViewedInTasks" || name === "isUnique") {
                fields[index][name] = value;
            }

            if (name === "isSearchable" && Boolean(fields[index].systemIsSearchable)) {
                fields[index][name] = value;
            }
        }
        setFields([...fields]);
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: t('estimationFormsFieldsBuilder:block_name'), width: "10%" },
        { title: t('estimationFormsFieldsBuilder:field_user_name_a'), width: "9%" },
        { title: t('estimationFormsFieldsBuilder:description'), width: "10%" },
        { title: t('estimationFormsFieldsBuilder:level'), width: "7%" },
        {
            title:
                <Checkbox
                    id={`${tableId}-viewed-in-tasks-all`}
                    label={t('estimationFormsFieldsBuilder:view_in_task')}
                    isChecked={isSelected("isViewedInTasks")}
                    setIsChecked={value => _handleChangeAllLines("isViewedInTasks", value)}
                />, width: "9%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-searchable-all`}
                    label={t('estimationFormsFieldsBuilder:searchable')}
                    isChecked={isSelected("isSearchable")}
                    setIsChecked={value => _handleChangeAllLines("isSearchable", value)}
                />, width: "9%"
        },

        {
            title:
                <Checkbox
                    id={`${tableId}-unique-all`}
                    label={t('estimationFormsFieldsBuilder:duplication_alert')}
                    isChecked={isSelected("isUnique")}
                    setIsChecked={value => _handleChangeAllLines("isUnique", value)}
                />, width: "9%"
        },
        { title: t('estimationFormsFieldsBuilder:ref_code'), width: "11%" },
        { title: t('estimationFormsFieldsBuilder:default_value'), width: "11%" },
        { title: t('status'), width: "11%" },
    ];

    return (
        <div className={tab == 5 ? 'active' : ''}>
            <Table
                id={tableId}
                columns={columns}
                user={user}
                formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                Row={Row}
                tableStyle={style.otherSettingsTable}
                rowActions={{ handleDataChange: handleDataChange, tableId: tableId, status: status, refCodeFeilds: refCodeFeilds, levels: levelMenus }}
                list={fields}
                rowHeight={40}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
            />
        </div>
    );
};

export default Tab5;