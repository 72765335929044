import { formatDateNTime, formatMoney, handleError, isEnglish } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import XIcon from "../../../icons/X";
import { getEstimationInvoiceRegisterList, getEstimationInvoiceRegisterTotals } from "../../../util/apis";
import { RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID } from "../../../util/constants/forms";
import { formatDate } from "../../../util/dateUtil";
import { closeTotalTableSm } from "../../../util/util";
import style from './style.module.scss';

const Row = (props) => {
    const { data } = props;

    return (
        <div className={` reportsRowHeight ${style.estimationInvoicesRegisterRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <div>{data.sequence}</div>
            </div>
            {/* <div title={t(`source_${data.source}`)} className="overflowTableField">{t(`source_${data.source}`)}</div> */}
            <div title={isEnglish() ? data?.sourceDescLo : data?.sourceDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:source')}</label>
                <div>
                    <label className={`overflowTableField ${style.source}`}>
                        {isEnglish() ? data?.sourceDescLo : data?.sourceDescFo}
                    </label>
                </div>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:invoice_code')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.code}
                    </label>
                </div>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:invoice_date')}</label>
                <div><label>{formatDate(data.dateDgr)}</label></div>
            </div>

            <div title={data.refCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:invoice_ref_code')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.refCode}
                    </label>
                </div>
            </div>

            <div title={`${data.customerCode || ''} - ${(isEnglish() ? data.officialNameLo : data.officialNameFo) || ''}`} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:party_customer_code_name')}</label>
                <div>
                    <label className="overflowTableField">
                        {`${data.customerCode || ''} - ${(isEnglish() ? data.officialNameLo : data.officialNameFo) || ''}`}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.totalPriceAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:total_price')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {formatMoney(data.totalPriceAlc)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.discountAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:discount')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {formatMoney(data.discountAlc)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.taxAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:vat')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {formatMoney(data.taxAlc)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.netPriceAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:net')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {formatMoney(data.netPriceAlc)}
                    </label>
                </div>
            </div>

            <div title={data.desc} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:description')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.desc}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.typeDescLo : data.typeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:transaction_type')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.typeDescLo : data.typeDescFo}
                    </label>
                </div>
            </div>

            <div title={data.trxCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:trx_code')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {data.trxCode}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.trxDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:transaction_date')}</label>
                <div>
                    <label className="amountLbl">
                        {formatDateNTime(data.trxDateDgr)}
                    </label>
                </div>
            </div>

            <div title={data.trxRefCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:ref_code')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.trxRefCode}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.orderDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:order_date')}</label>
                <div><label>{formatDateNTime(data.orderDateDgr)}</label></div>
            </div>

            <div title={data.orderNum} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:order_num')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {data.orderNum}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.cityDescLo : data.cityDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:city')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.cityDescLo : data.cityDescFo}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.townDescLo : data.townDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:town')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.townDescLo : data.townDescFo}
                    </label>
                </div>
            </div>

            <div title={data.owner} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:owner')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.owner}
                    </label>
                </div>
            </div>

            <div title={data.ownerId} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:owner_id')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.ownerId}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.officialNameLo : data.officialNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:customer')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.officialNameLo : data.officialNameFo}
                    </label>
                </div>
            </div>

            <div title={data.clientName} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:client_name')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {data.clientName}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.propertyDescLo : data.propertyDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:property_type')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.propertyDescLo : data.propertyDescFo}
                    </label>
                </div>
            </div>

            <div title={data.propertyCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:property_code')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.propertyCode}
                    </label>
                </div>
            </div>

            <div title={data.titleDeedNum} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:title_deed_num')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.titleDeedNum}
                    </label>
                </div>
            </div>

            <div title={data.parcelArea} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:parcel_area')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.parcelArea}
                    </label>
                </div>
            </div>

            <div title={data.totalBuildArea} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:total_buildings_area')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.totalBuildArea}
                    </label>
                </div>
            </div>

            <div title={data.noOfFloors} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:floors_num')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.noOfFloors}
                    </label>
                </div>
            </div>

            <div title={data.estimationValueAlc} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:market_price')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.estimationValueAlc}
                    </label>
                </div>
            </div>

            <div title={data.stage} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:stage')}</label>
                <div>
                    <label className="overflowTableField">
                        {data.stage}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.statusDescLo : data.statusDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:application_status')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.statusDescLo : data.statusDescFo}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.createdByDescLo : data.createdByDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:created_by')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.createdByDescLo : data.createdByDescFo}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.firstInspDescLo : data.firstInspDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:inspector')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.firstInspDescLo : data.firstInspDescFo}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.firstInspDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:inspection_date')}</label>
                <div>
                    <label className="overflowTableField">
                        {formatDateNTime(data.firstInspDateTime)}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.lastInspDescLo : data.lastInspDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:inspection_auditor')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.lastInspDescLo : data.lastInspDescFo}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.lastInspDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:inspection_date_2')}</label>
                <div>
                    <label>
                        {formatDateNTime(data.lastInspDateTime)}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.firstRevDescLo : data.firstRevDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:reviewed_by')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.firstRevDescLo : data.firstRevDescFo}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.firstRevDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:revision_date')}</label>
                <div>
                    <label>
                        {formatDateNTime(data.firstRevDateTime)}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.lastRevDescLo : data.lastRevDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:reviewing_auditor')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.lastRevDescLo : data.lastRevDescFo}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.lastRevDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:reviewing_date')}</label>
                <div>
                    <label>
                        {formatDateNTime(data.lastRevDateTime)}
                    </label>
                </div>
            </div>

            <div title={isEnglish() ? data.approvedByDescLo : data.approvedByDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:approved_by')}</label>
                <div>
                    <label className="overflowTableField">
                        {isEnglish() ? data.approvedByDescLo : data.approvedByDescFo}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.approvalDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:approval_date')}</label>
                <div>
                    <label>
                        {formatDateNTime(data.approvalDateTime)}
                    </label>
                </div>
            </div>

            <div title={formatDateNTime(data.invApprovalDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:invoice_approval_date')}</label>
                <div>
                    <label>
                        {formatDateNTime(data.invApprovalDateDgr)}
                    </label>
                </div>
            </div>

            <div title={formatMoney(data.orgFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoicesRegister:incomes_total')}</label>
                <div>
                    <label className="overflowTableField amountLbl">
                        {formatMoney(data.orgFeesAlc)}
                    </label>
                </div>
            </div>

        </div >
    );
};

const Total = (props) => {
    const { totals } = props;

    const { t } = useTranslation("estimationInvoicesRegister");

    return (
        <>
            <div id="estimation-invoices-register-total" className="table-total" >
                <div className="hide-total-table-mobile"><div></div></div>
                <div><div className="tableTotalLabel">{t("total")}</div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoicesRegister:total_price')}</label>
                    <div id="estimation-invoices-register-total-price" title={formatMoney(totals?.totalPriceAlc)}>{formatMoney(totals?.totalPriceAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoicesRegister:discount')}</label>
                    <div id="estimation-invoices-register-discount" title={formatMoney(totals?.discountAlc)}>{formatMoney(totals?.discountAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoicesRegister:vat')}</label>
                    <div id="estimation-invoices-register-vat" title={formatMoney(totals?.taxAlc)}>{formatMoney(totals?.taxAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoicesRegister:net')}</label>
                    <div id="estimation-invoices-register-net" title={formatMoney(totals?.netPriceAlc)}>{formatMoney(totals?.netPriceAlc)}</div>
                </div>
                {/* <div title={formatMoney(data.totalPriceAlc)} className="overflowTableField">{formatMoney(data.totalPriceAlc)}</div>
            <div title={formatMoney(data.discountAlc)} className="overflowTableField">{formatMoney(data.discountAlc)}</div>
            <div title={formatMoney(data.taxAlc)} className="overflowTableField">{formatMoney(data.taxAlc)}</div>
            <div title={data.netPriceAlc} className="overflowTableField">{data.netPriceAlc}</div> */}
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                {/* <div title={formatMoney(totals?.orgFeesAlc)}><div>{formatMoney(totals?.orgFeesAlc)}</div></div> */}
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoicesRegister:incomes_total')}</label>
                    <div id="estimation-invoices-register-incomes-total" title={formatMoney(totals?.orgFeesAlc)}>{formatMoney(totals?.orgFeesAlc)}</div>
                </div>

                <button className="closeTotalMobile" onClick={() => closeTotalTableSm('estimation-invoices-register-total')}>
                    <XIcon />
                </button>
            </div>
            <span id="estimation-invoices-register-total-bg" className="totalMobileBg" onClick={() => closeTotalTableSm('estimation-invoices-register-total')}></span>
        </>
    )
}

const EstimationInvoicesRegisterTable = (props) => {
    const { user, estimationInvoicesRegisterTableRef, filtersRef } = props;

    const { t } = useTranslation();

    const [data, setData] = useState({});
    const [totals, setTotals] = useState();

    const columns = [
        { title: '#', width: '1%' },
        { title: t('estimationInvoicesRegister:source'), width: '3%', excelColumn: "EIL_SOURCE_DESC_LO,EIL_SOURCE_DESC_FO" },
        { title: t('estimationInvoicesRegister:invoice_code'), width: '3%', excelColumn: "EIL_CODE" },
        { title: t('estimationInvoicesRegister:invoice_date'), width: '3%', excelColumn: "EIL_DATE_DGR" },
        { title: t('estimationInvoicesRegister:invoice_ref_code'), width: '3%', excelColumn: "EIL_REF_CODE" },
        { title: t('estimationInvoicesRegister:party_customer_code_name'), width: '4%', excelColumn: "CUSTOMER_CODE_AND_NAME_LO,CUSTOMER_CODE_AND_NAME_FO" },
        { title: t('estimationInvoicesRegister:total_price'), width: '3%', excelColumn: "EIL_TOTAL_PRICE_ALC" },
        { title: t('estimationInvoicesRegister:discount'), width: '3%', excelColumn: "EIL_DISCOUNT_ALC" },
        { title: t('estimationInvoicesRegister:vat'), width: '2%', excelColumn: "EIL_TAX_ALC" },
        { title: t('estimationInvoicesRegister:net'), width: '2%', excelColumn: "EIL_NET_PRICE_ALC" },
        { title: t('estimationInvoicesRegister:description'), width: '2%', excelColumn: "EIL_DESC" },
        { title: t('estimationInvoicesRegister:transaction_type'), width: '3%', excelColumn: "TRX_TYPE_DESC_LO,TRX_TYPE_DESC_FO" },
        { title: t('estimationInvoicesRegister:trx_code'), width: '2%', excelColumn: "EIL_TRX_CODE" },
        { title: t('estimationInvoicesRegister:transaction_date'), width: '2%', excelColumn: "EIL_TRX_DATE_DGR" },
        { title: t('estimationInvoicesRegister:ref_code'), width: '2%', excelColumn: "EIL_TRX_REF_CODE" },
        { title: t('estimationInvoicesRegister:order_date'), width: '2%', excelColumn: "EIL_ORDER_DATE_DGR" },
        { title: t('estimationInvoicesRegister:order_num'), width: '2%', excelColumn: "EIL_ORDER_NUM" },
        { title: t('estimationInvoicesRegister:city'), width: '2%', excelColumn: "ECD_CITY_DESC_LO,ECD_CITY_DESC_FO" },
        { title: t('estimationInvoicesRegister:town'), width: '2%', excelColumn: "CRI_TOWN_DESC_LO,CRI_TOWN_DESC_FO" },
        { title: t('estimationInvoicesRegister:owner'), width: '2%', excelColumn: "EIL_OWNER" },
        { title: t('estimationInvoicesRegister:owner_id'), width: '2%', excelColumn: "EIL_OWNER_ID" },
        { title: t('estimationInvoicesRegister:customer'), width: '2%', excelColumn: "ATR_OFFICIAL_NAME_LO,ATR_OFFICIAL_NAME_FO" },
        { title: t('estimationInvoicesRegister:client_name'), width: '2%', excelColumn: "EIL_CLIENT_NAME" },
        { title: t('estimationInvoicesRegister:property_type'), width: '2%', excelColumn: "MYO_PROPERTY_DESC_LO,MYO_PROPERTY_DESC_FO" },
        { title: t('estimationInvoicesRegister:property_code'), width: '2%', excelColumn: "EIL_PROPERTY_CODE" },
        { title: t('estimationInvoicesRegister:title_deed_num'), width: '2%', excelColumn: "EIL_TITLE_DEED_NUM" },
        { title: t('estimationInvoicesRegister:parcel_area'), width: '2%', excelColumn: "EIL_PARCEL_AREA" },
        { title: t('estimationInvoicesRegister:total_buildings_area'), width: '2%', excelColumn: "EIL_TOTAL_BUILD_AREA" },
        { title: t('estimationInvoicesRegister:floors_num'), width: '2%', excelColumn: "EIL_NO_OF_FLOORS" },
        { title: t('estimationInvoicesRegister:market_price'), width: '2%', excelColumn: "EIL_ESTIMATION_VALUE_ALC" },
        { title: t('estimationInvoicesRegister:stage'), width: '2%', excelColumn: "EIL_STAGE" },
        { title: t('estimationInvoicesRegister:application_status'), width: '2%', excelColumn: "STATUS_DESC_LO,STATUS_DESC_FO" },
        { title: t('estimationInvoicesRegister:created_by'), width: '2%', excelColumn: "CREATED_BY_DESC_LO,CREATED_BY_DESC_FO" },
        { title: t('estimationInvoicesRegister:inspector'), width: '2%', excelColumn: "FIRST_INSP_DESC_LO,FIRST_INSP_DESC_FO" },
        { title: t('estimationInvoicesRegister:inspection_date'), width: '2%', excelColumn: "EIL_FIRST_INSP_DATE_TIME" },
        { title: t('estimationInvoicesRegister:inspection_auditor'), width: '2%', excelColumn: "LAST_INSP_DESC_LO,LAST_INSP_DESC_FO" },
        { title: t('estimationInvoicesRegister:inspection_date_2'), width: '2%', excelColumn: "EIL_LAST_INSP_DATE_TIME" },
        { title: t('estimationInvoicesRegister:reviewed_by'), width: '2%', excelColumn: "FIRST_REV_DESC_LO,FIRST_REV_DESC_FO" },
        { title: t('estimationInvoicesRegister:revision_date'), width: '2%', excelColumn: "EIL_FIRST_REV_DATE_TIME" },
        { title: t('estimationInvoicesRegister:reviewing_auditor'), width: '2%', excelColumn: "LAST_REV_DESC_LO,LAST_REV_DESC_FO" },
        { title: t('estimationInvoicesRegister:reviewing_date'), width: '3%', excelColumn: "EIL_LAST_REV_DATE_TIME" },
        { title: t('estimationInvoicesRegister:approved_by'), width: '3%', excelColumn: "APPROVED_BY_DESC_LO,APPROVED_BY_DESC_FO" },
        { title: t('estimationInvoicesRegister:approval_date'), width: '2%', excelColumn: "EIL_APPROVAL_DATE_TIME" },
        { title: t('estimationInvoicesRegister:invoice_approval_date'), width: '2%', excelColumn: "EIL_INV_APPROVAL_DATE_DGR" },
        { title: t('estimationInvoicesRegister:incomes_total'), width: '2%', excelColumn: "EIL_ORG_FEES_ALC" }
    ];

    const _fetchData = (page, size) => {
        getEstimationInvoiceRegisterList(size, page, null, filtersRef.current.getFilterData())
            .then(res => setData(res.data))
            .catch((error) => handleError(error, null, t));

        getEstimationInvoiceRegisterTotals(filtersRef.current.getFilterData())
            .then(res => setTotals(res.data))
            .catch((error) => handleError(error, null, t));
    }

    return (
        <div className={style.estimationInvoicesRegisterTableContainer}>
            <Table
                id="estimation-invoices-register-table"
                ref={estimationInvoicesRegisterTableRef}
                columns={columns}
                formId={RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID}
                Row={Row}
                pagingData={data}
                tableStyle={style.estimationInvoicesRegisterTable}
                getData={_fetchData}
                rowHeight={60}
                Total={() => <Total totals={totals} />}
                tableWidth={340}
                withFilter
                reportsTable
                showNoDataFound={(data) => (data && data.length == 0)}
                showWating={(data) => data == null}
                labelCardWidth="140px"
                user={user}
            />
        </div>
    );
};

export default EstimationInvoicesRegisterTable;