import { formatDateNTime, handleError, isEnglish, useIsMobile } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import Table from "../../../components/table";
import { WorkflowStatusIcon } from "../../../icons/EstimationTrx";
import QuestionMarkIcon from "../../../icons/QuestionMark";
import SuccessIcon from "../../../icons/Success";
import { getWorkflowStatus } from "../../../util/apis";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { virtualizedStyle, data, onRowClick, rowClassName } = props;
    const { t } = useTranslation('estimationTransaction');

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} ${style.workflowStatusTableRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>
                <div>
                    <label className={style.mobileLbl}>#</label>
                    <div title={data.sequence}>
                        {data.sequence}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:mission')}</label>
                    <div title={data?.description} className={style.workFlowDesc}>
                        {data?.description}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:status')}</label>
                    <div>
                        {data?.excutorDescLo &&
                            <span className={style.success}>
                                <SuccessIcon />
                            </span>
                            ||
                            <span className={style.info}>
                                <QuestionMarkIcon />
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:user')}</label>
                    <div title={isEnglish() ? data?.descLo : data?.descFo}>
                        <label className={style.userDec}>{isEnglish() ? data?.descLo : data?.descFo}</label>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:sending_date')}</label>
                    <div title={formatDateNTime(data?.dueDateTime)}>
                        {formatDateNTime(data?.dueDateTime)}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:execution_date')}</label>
                    <div title={formatDateNTime(data?.execDateTime)}>
                        {formatDateNTime(data?.execDateTime)}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:executor')}</label>
                    <div title={isEnglish() ? data?.excutorDescLo : data?.excutorDescFo}>
                        {isEnglish() ? data?.excutorDescLo : data?.excutorDescFo}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label className={style.mobileLbl}>{t('estimationTransaction:transaction_status')}</label>
                    <div title={isEnglish() ? data?.outputStatusLo : data?.outputStatusFo}>
                        {isEnglish() ? data?.outputStatusLo : data?.outputStatusFo}
                    </div>
                </div>
            </div>
        </div>
    )
}

const WorkFlowStatusDialog = (props, ref) => {
    const { user, postClose } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const WorkFlowStatusTableRef = useRef();
    const [statuses, setStatuses] = useState([]);

    const columns = [
        { title: '#', width: '2%' },
        { title: t('estimationTransaction:mission'), width: '13%' },
        { title: t('estimationTransaction:status'), width: '13%' },
        { title: t('estimationTransaction:user'), width: '26%' },
        { title: t('estimationTransaction:sending_date'), width: '10%' },
        { title: t('estimationTransaction:execution_date'), width: '10%' },
        { title: t('estimationTransaction:executor'), width: '13%' },
        { title: t('estimationTransaction:transaction_status'), width: '13%' }
    ];

    const _postClose = () => {
        setStatuses(null)
        if (postClose) {
            postClose();
        }
    }

    const open = (trxId) => {
        getWorkflowStatus(trxId)
            .then((response) => setStatuses(response.data))
            .catch((error) => handleError(error, null, t));
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))
    const isMobileState = useIsMobile();

    return (
        <Modal
            id="workflow-status-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={
                <>
                    <WorkflowStatusIcon />
                    {t('estimationTransaction:workflow_status')}
                </>
            }
            withFooter={isMobileState ? false : true}
            withCancelButton
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={_postClose}
            isTableModal
            className={style.workFlowStatusDialog}
        >
            <div className={`container ${style.workflowTableContainerSm}`}>
                <Table
                    id="workflow-status-dialog-table"
                    ref={WorkFlowStatusTableRef}
                    formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                    rowHeight={isMobileState ? 341 : 85}
                    columns={columns}
                    Row={Row}
                    tableStyle={style.workflowStatusTable}
                    list={statuses}
                    showWating={(data) => data == null}
                    showNoDataFound={(data) => (data && data.length == 0)}
                    user={user}
                />
            </div>
        </Modal>
    )
}

export default forwardRef(WorkFlowStatusDialog);