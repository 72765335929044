import ActionsButton from "@ipgd-gauge/actions-button";
import { isAllowedAction } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import PloicyIcon from "../../icons/PloicyIcon";
import { exportEstimationTransactionsRegisterToExcel } from "../../util/apis";
import { PROPERTIES_USERS_ESTIMATION_POLICY_FORM_ID } from "../../util/constants";
import { EXPORT_ACTION } from "../../util/constants/actions";
import { RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import { openTotalTableSm } from "../../util/util";
import PloicyFilter from "../common/filters/policy-filter";
import EstimationTransactionsRegisterFilter from "./listing/Filters";
import EstimationTransactionsRegisterTable from "./listing/index";

const EstimationTransactionsRegister = () => {
    const { t } = useTranslation();

    const tableRef = useRef();
    const filterRef = useRef();
    const policyFilterRef = useRef();

    const user = getUser();


    const _exportExcelAction = () => {
        const filterData = { ...filterRef.current?.getFilterData(), columnSetting: tableRef.current.getSelectedSettingId() };
        exportEstimationTransactionsRegisterToExcel(filterData, "Estimation Transactions Register", "export-transactoions-register")
    }

    const _getActions = () => {
        const actions = [];
        if (isAllowedAction(user, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, EXPORT_ACTION)) {
            actions.push({
                id: `export-excel-sheet`,
                icon: <ExcelIcon />,
                label: t('export'),
                action: _exportExcelAction
            })
        }
        return actions;
    }

    return (
        <>
            {/* <div className="headerPagesPanel">
                <FormTitle formId={RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID} />
                <div className="headerSearchPanel">
                    <PloicyFilter
                        id="estimation-invoices-register-policy-filter"
                        ref={policyFilterRef}
                        filterRef={filterRef}
                        formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div> */}
            <div className="headerPagesPanelReportSm">
                <FormTitle formId={RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID} />
                <div className="headerSearchPanel policieHeaderSearchReprtsSm">
                    <div className="ploicyFilterContainer">
                        <PloicyFilter
                            id="estimation-invoices-register-policy-filter"
                            ref={policyFilterRef}
                            filterRef={filterRef}
                            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                            functionFormId={PROPERTIES_USERS_ESTIMATION_POLICY_FORM_ID}
                        />
                    </div>
                    <div className="reports-header-actions">
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('estimation-transactions-register-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                        <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                            <FiltersIcon />
                        </button>
                    </div>
                </div>
            </div>


            <EstimationTransactionsRegisterTable user={user} tableRef={tableRef} filterRef={filterRef} />

            <EstimationTransactionsRegisterFilter
                user={user}
                ref={filterRef}
                tableRef={tableRef}
                policyFilterRef={policyFilterRef}
            />

            <ActionsButton actions={_getActions()} />
        </>
    )
}

export default EstimationTransactionsRegister;