import FrmTimeDatePickr from "@ipgd-gauge/date-picker/dist/FrmTimeDatePickr";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, formatDate, formatMoney } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExportIcon from "../../../icons/Export";
import { exportCreditNoteCustomerEstimation, getCommonPartiesLov, getCommonPropertyTypes, getEstimationDomains, getTransactionList } from "../../../util/apis";
import { ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, ESTIMATOR_PARTY, INSPECTOR_PARTY } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { isEnglish, isLocalCurrency } from "../../../util/util";
import MultiInsertDialog from "../../common/dialogs/multi-insert-dialog";
import { ACTION_ADD_LINES, ACTION_DELETE_ALL_LINES, ACTION_DELETE_LINE } from "./reducer";
import style from './style.module.scss';

export const ESTIMATION_FEES = 128601;
export const ITEM = 128602;

const AdvanceFiltersPanel = forwardRef((props, ref) => {

    const { t } = useTranslation("creditNotesForCustomersEstimation");
    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return filterData
    }

    const clearFilterData = () => {
        setFilterData({})
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        clearFilterData
    }))

    return (
        <div className={`container ${style.advanceFiltersPanelContainer}`}>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('approval_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Approval-Date-From"
                        value={filterData.approvalFromDate}
                        onChange={value => _handleDataChanged("approvalFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('approval_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Approval-Date-To"
                        value={filterData.approvalToDate}
                        onChange={value => _handleDataChanged("approvalToDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('order_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Order-From-Date"
                        value={filterData.orderFromDate}
                        onChange={value => _handleDataChanged("orderFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('order_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Order-To-Date"
                        value={filterData.orderToDate}
                        onChange={value => _handleDataChanged("orderToDate", value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('transaction_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Transaction-From-Date"
                        value={filterData.transactionFromDate}
                        onChange={value => _handleDataChanged("transactionFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('transaction_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Transaction-To-Date"
                        value={filterData.transactionToDate}
                        onChange={value => _handleDataChanged("transactionToDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('city')}</label>
                    <FrmInput
                        id="city"
                        value={filterData.city}
                        onBlur={(value) => _handleDataChanged("city", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('town')}</label>
                    <FrmInput
                        id="towns"
                        value={filterData.town}
                        onBlur={(value) => _handleDataChanged("town", value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('transaction_type')}</label>
                    <FrmSelectMenu
                        id="Transaction-Type"
                        isMulti={true}
                        value={filterData.trxTypes}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("trxTypes", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('property_type')}</label>
                    <FrmSelectMenu
                        id="property_type"
                        isMulti={true}
                        value={filterData.propertyTypes}
                        api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyTypes)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("propertyTypes", value)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('stage')}</label>
                    <FrmSelectMenu
                        id="Stage"
                        isMulti={true}
                        value={filterData.stages}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_STAGES)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("stages", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('inspector')}</label>
                    <FrmSelectMenu
                        id="Inspector"
                        key="Inspector"
                        isMulti={true}
                        value={filterData.inspectorIds}
                        api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectorIds, null, null, 1)}
                        descLo={['nameLo']}
                        descFo={['nameFo']}
                        onChange={e => _handleDataChanged("inspectorIds", e)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    )
})

const DetailsRow = (props) => {
    const { t } = useTranslation("creditNotesForCustomersEstimation");
    const { data, rowActions, virtualizedStyle, index, onRowClick, rowClassName } = props;
    const { handelDeleteLine } = rowActions

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} `} style={virtualizedStyle} onClick={onRowClick}>
            <div><div><label>{data.sequence}</label></div></div>
            <div title={data?.seq}><div>{data?.seq}</div></div>
            <div><div><label title={t(`source_${data.source}`)}>{t(`source_${data.source}`)}</label></div></div>
            <div><div><label title={isEnglish() ? data.itemLo : data.itemFo}>{isEnglish() ? data.itemLo : data.itemFo}</label></div></div>
            <div><div><label title={data.desc || ''}>{data.desc || ''}</label></div></div>
            <div><div><label title={data?.applicationNum}>{data?.applicationNum}</label></div></div>
            <div><div><label title={formatMoney(data.dueAmountAlc)}>{formatMoney(data.dueAmountAlc)}</label></div></div>
            <div><div><label title={formatMoney(data.invoiceAmountAfc)}>{formatMoney(data.invoiceAmountAfc)}</label></div></div>
            <div><div><label title={formatMoney(data.invoiceAmountAlc)}>{formatMoney(data.invoiceAmountAlc)}</label></div></div>
            <div><div><label title={formatMoney(data.discountAfc)}>{formatMoney(data.discountAfc)}</label></div></div>
            <div><div><label title={formatMoney(data.discountAlc)}>{formatMoney(data.discountAlc)}</label></div></div>
            <div><div><label title={data.vatPerc || ''}>{data.vatPerc || ''}</label></div></div>
            <div><div><label title={formatMoney(data.vatAmountAfc)}>{formatMoney(data.vatAmountAfc)}</label></div></div>
            <div><div><label title={formatMoney(data.vatAmountAlc)}>{formatMoney(data.vatAmountAlc)}</label></div></div>
            <div><div><label title={formatMoney(data.netAfc)}>{formatMoney(data.netAfc)}</label></div></div>
            <div><div><label title={formatMoney(data.netAlc)}>{formatMoney(data.netAlc)}</label></div></div>
            <div><div><label title={formatDate(data.approvalDateDgr)}>{formatDate(data.approvalDateDgr)}</label></div></div>
            <div><div><label title={isEnglish() ? data?.trnxTypeDescLo : data?.trnxTypeDescFo}>{isEnglish() ? data?.trnxTypeDescLo : data?.trnxTypeDescFo}</label> </div></div>
            <div><div><label title={data?.refCode}>{data?.refCode}</label> </div></div>
            <div><div><label title={data?.orderNo}>{data?.orderNo}</label> </div></div>
            <div><div><label title={formatDate(data?.orderDateTime)}>{formatDate(data?.orderDateTime)}</label></div></div>
            <div><div><label title={formatDate(data?.trnxDateTime)}>{formatDate(data?.trnxDateTime)}</label></div></div>
            <div><div><label title={isEnglish() ? data?.cityDescLo : data?.cityDescFo}>{isEnglish() ? data?.cityDescLo : data?.cityDescFo}</label></div></div>
            <div><div><label title={isEnglish() ? data?.districtDescLo : data?.districtDescFo}>{isEnglish() ? data?.districtDescLo : data?.districtDescFo}</label></div></div>
            <div><div><label title={data?.ownerName}>{data?.ownerName}</label></div></div>
            <div><div><label title={isEnglish() ? data?.officialNameLo : data?.officialNameFo}>{isEnglish() ? data?.officialNameLo : data?.officialNameFo}</label></div></div>
            <div><div><label title={data?.clientName}>{data?.clientName}</label></div></div>
            <div><div><label title={data?.ownerCardNum}>{data?.ownerCardNum}</label></div></div>
            <div><div><label title={isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo}>{isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo}</label></div></div>
            <div><div><label title={data?.propertyCode}>{data?.propertyCode}</label></div></div>
            <div><div><label title={data?.titleDeedNo}>{data?.titleDeedNo}</label></div></div>
            <div><div><label title={data?.parcelArea}>{data?.parcelArea}</label></div></div>
            <div><div><label title={data?.totalBuildArea}>{data?.totalBuildArea}</label></div></div>
            <div><div><label title={data?.noOfFloors}>{data?.noOfFloors}</label></div></div>
            <div><div><label title={formatMoney(data?.finalPrice)}> {formatMoney(data?.finalPrice)}</label></div></div>
            <div><div><label title={isEnglish() ? data?.stageDescLo : data?.stageDescFo}>{isEnglish() ? data?.stageDescLo : data?.stageDescFo}</label></div></div>
            <div><div><label title={isEnglish() ? data?.trnxStatusLo : data?.trnxStatusFo}>{isEnglish() ? data?.trnxStatusLo : data?.trnxStatusFo}</label></div></div>
            <div><div><label title={isEnglish() ? data?.inspectorNameLo : data?.inspectorNameFo}>{isEnglish() ? data?.inspectorNameLo : data?.inspectorNameFo}</label></div></div>
            <div><div><DeleteBtn onClick={() => handelDeleteLine(index)} /></div></div>
        </div>
    );
};

const DetailsTable = (props) => {
    const { creditNote, dispatchCreditNote, user, detailsTableRef } = props;
    const { t } = useTranslation("creditNotesforCustomersEstimation");

    const columns = [
        { title: '#', width: '1%' },
        { title: t('creditNotesForCustomersEstimation:line_num'), width: '2%' },
        { title: t('creditNotesForCustomersEstimation:source'), width: '2%', excelColumn: "sourceDescLo,sourceDescFo" },
        { title: t('creditNotesForCustomersEstimation:item_code_name'), width: '3%', excelColumn: "descLo,descFo" },
        { title: t('creditNotesForCustomersEstimation:description'), width: '2%', excelColumn: "desc" },
        { title: t('creditNotesForCustomersEstimation:num_application'), width: '2%', excelColumn: "applicationNum" },
        { title: t('creditNotesForCustomersEstimation:due_amount'), width: '3%', excelColumn: "dueAmountAlc" },
        { title: t('creditNotesForCustomersEstimation:invoice_amount_fc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '0%' : '2%', excelColumn: "invoiceAmountAfc" },
        { title: t('creditNotesForCustomersEstimation:invoice_amount_lc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '4%' : '2%', excelColumn: "invoiceAmountAlc" },
        { title: t('creditNotesForCustomersEstimation:discount_fc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '0%' : '2%', excelColumn: "discountAfc" },
        { title: t('creditNotesForCustomersEstimation:discount_lc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '4%' : '2%', excelColumn: "discountAlc" },
        { title: t('creditNotesForCustomersEstimation:vat_per'), width: '2%', excelColumn: "vatPerc" },
        { title: t('creditNotesForCustomersEstimation:vat_fc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '0%' : '2%', excelColumn: "vatAmountAfc" },
        { title: t('creditNotesForCustomersEstimation:vat_lc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '4%' : '2%', excelColumn: "vatAmountAlc" },
        { title: t('creditNotesForCustomersEstimation:net_fc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '0%' : '2%', excelColumn: "netAfc" },
        { title: t('creditNotesForCustomersEstimation:net_lc'), width: isLocalCurrency(creditNote?.header?.fcuId) ? '4%' : '2%', excelColumn: "netAlc" },
        { title: t('creditNotesForCustomersEstimation:approval_date'), width: '3%', excelColumn: "approvalDateDgr" },
        { title: t('creditNotesForCustomersEstimation:transaction_type'), width: '3%', excelColumn: "transactionTypeLo,transactionTypeFo" },
        { title: t('creditNotesForCustomersEstimation:ref_code'), width: '2%', excelColumn: "refCode" },
        { title: t('creditNotesForCustomersEstimation:order_num'), width: '3%', excelColumn: "orderNo" },
        { title: t('creditNotesForCustomersEstimation:order_date'), width: '3%', excelColumn: "orderDateTime" },
        { title: t('creditNotesForCustomersEstimation:application_date'), width: '3%', excelColumn: "trnxDateTime" },
        { title: t('creditNotesForCustomersEstimation:city'), width: '3%', excelColumn: "cityDescLo,cityDescFo" },
        { title: t('creditNotesForCustomersEstimation:town'), width: '3%', excelColumn: "districtDescLo,districtDescFo" },
        { title: t('creditNotesForCustomersEstimation:owner'), width: '3%', excelColumn: "ownerName" },
        { title: t('creditNotesForCustomersEstimation:customer'), width: '3%', excelColumn: "officialNameLo,officialNameFo" },
        { title: t('creditNotesForCustomersEstimation:client_name'), width: '3%', excelColumn: "clientName" },
        { title: t('creditNotesForCustomersEstimation:owner_id'), width: '3%', excelColumn: "ownerCardNum" },
        { title: t('creditNotesForCustomersEstimation:property_type'), width: '3%', excelColumn: "propertyTypeDescLo,propertyTypeDescFo" },
        { title: t('creditNotesForCustomersEstimation:property_code'), width: '3%', excelColumn: "propertyCode" },
        { title: t('creditNotesForCustomersEstimation:num_title_deed'), width: '3%', excelColumn: "titleDeedNo" },
        { title: t('creditNotesForCustomersEstimation:land_area'), width: '3%', excelColumn: "parcelArea" },
        { title: t('creditNotesForCustomersEstimation:building_area'), width: '3%', excelColumn: "totalBuildArea" },
        { title: t('creditNotesForCustomersEstimation:number_of_floors'), width: '3%', excelColumn: "noOfFloors" },
        { title: t('creditNotesForCustomersEstimation:estimation_price'), width: '3%', excelColumn: "finalPrice" },
        { title: t('creditNotesForCustomersEstimation:stage'), width: '3%', excelColumn: "stageDescLo,stageDescFo" },
        { title: t('creditNotesForCustomersEstimation:trnx_Status'), width: '3%', excelColumn: "trnxStatusLo,trnxStatusFo" },
        { title: t('creditNotesForCustomersEstimation:inspector'), width: '3%', excelColumn: "inspectorNameLo,inspectorNameFo" },
        { title: '', width: '2%' }
    ];

    const _handelDeleteLine = (index) => {
        dispatchCreditNote({ index: index, type: ACTION_DELETE_LINE })
    }

    return (
        <Table
            id="details-table"
            columns={columns}
            ref={detailsTableRef}
            Row={DetailsRow}
            tableStyle={style.detailsTable}
            rowHeight={40}
            tableWidth={400}
            rowActions={{
                handelDeleteLine: _handelDeleteLine,
            }}
            list={creditNote.lines}
            formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
            user={user}
        />
    )
}

const Details = (props) => {

    const { user, dispatchCreditNote, creditNote } = props;
    const { t } = useTranslation("creditNotesForCustomersEstimation");
    const insertTransactionDialog = useRef(null);
    const detailsTableRef = useRef();

    const _insertTransaction = (selectedData) => {
        let rows = [];
        selectedData.forEach(data => {
            let row = {};
            row.additionsId = data.additionsId;
            row.source = ESTIMATION_FEES;
            row.triId = data.id;
            row.atrId = data.atrId
            row.cdaId = data.cdaId;
            row.itemLo = data.contractDescLo
            row.itemFo = data.contractDescFo
            row.desc = data.desc
            row.vatPerc = data.vatPerc
            row.applicationNum = data.code
            row.dueAmountAlc = data.dueAmountAlc
            row.dueAmountAfc = data.dueAmountAfc
            row.invoiceAmountAlc = data.invoiceAmountAlc
            row.invoiceAmountAfc = data.invoiceAmountAfc
            row.discountAlc = data.discountAlc
            row.discountAfc = data.discountAfc
            row.vatAmountAlc = data.vatAmountAlc
            row.vatAmountAfc = data.vatAmountAfc
            row.netAlc = data.netAlc
            row.netAfc = data.netAfc
            row.approvalDateDgr = data.approvalDateTime
            row.trnxTypeDescLo = data.typeDescLo
            row.trnxTypeDescFo = data.typeDescFo
            row.refCode = data.refCode
            row.orderNo = data.orderNo
            row.orderDateTime = data.orderDateTime
            row.trnxDateTime = data.dateTime
            row.cityDescLo = data.cityDescLo
            row.cityDescFo = data.cityDescFo
            row.districtDescLo = data.districtDescLo
            row.districtDescFo = data.districtDescFo
            row.ownerName = data.ownerName
            row.officialNameLo = data.officialNameLo
            row.officialNameFo = data.officialNameFo
            row.clientName = data.clientName
            row.ownerCardNum = data.ownerCardNum
            row.propertyTypeDescLo = data.propertyTypeDescLo
            row.propertyTypeDescFo = data.propertyTypeDescFo
            row.propertyCode = data.propertyCode
            row.titleDeedNo = data.titleDeedNo
            row.parcelArea = data.parcelArea
            row.totalBuildArea = data.totalBuildArea
            row.noOfFloors = data.noOfFloors
            row.finalPrice = data.finalPrice
            row.stageDescLo = data.stageDescLo
            row.stageDescFo = data.stageDescFo
            row.trnxStatusLo = data.trnxStatusLo
            row.trnxStatusFo = data.trnxStatusFo
            row.inspectorNameLo = data.inspectorNameLo
            row.inspectorNameFo = data.inspectorNameFo
            row.seq = data.seq
            rows.push(row);
        })
        dispatchCreditNote({ value: rows, type: ACTION_ADD_LINES })
        insertTransactionDialog.current.close();
    }

    const _deleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            dispatchCreditNote({ type: ACTION_DELETE_ALL_LINES })
        })
    }

    return (
        <>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('creditNotesForCustomersEstimation:details')}
                        </label>
                    </div>
                </div>
                <div className={style.headerLines}>
                    <div className={style.headerBtn}>
                        <button
                            title={t('insert_transactions')}
                            className="tableHeaderActionsBtn"
                            onClick={() => insertTransactionDialog.current.open()}
                            disabled={!creditNote?.header?.dateDgr || !creditNote?.header?.isnId}
                        >
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('creditNotesForCustomersEstimation:insert_transactions')}
                            </label>
                        </button>

                        <button
                            title={t('export')}
                            className={`tableHeaderActionsBtn ${style.marLeft}`}
                            // onClick={() => exportCreditNoteCustomerEstimation(creditNote.lines, 'customers-estimation-invoice', "export-credit-notes-for-customers-estimation-spinner")}
                            onClick={() => exportCreditNoteCustomerEstimation({ lines: creditNote.lines, columnSetting: detailsTableRef.current.getSelectedSettingId() }, 'customers-estimation-invoice', "export-credit-notes-for-customers-estimation-spinner")}
                            disabled={(creditNote.lines || []).length === 0}>
                            <Spinner id="export-credit-notes-for-customers-estimation-spinner" className={style.spinner} />
                            <span>
                                <ExportIcon />
                            </span>
                            <label>
                                {t('creditNotesForCustomersEstimation:export')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={_deleteAll}
                            disabled={creditNote?.lines?.length == 0}
                        >
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
                <DetailsTable creditNote={creditNote} dispatchCreditNote={dispatchCreditNote} user={user} detailsTableRef={detailsTableRef} />
            </div>

            <MultiInsertDialog
                id='insert_transactions'
                ref={insertTransactionDialog}
                title={t('insert_transactions')}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                okBtnAction={_insertTransaction}
                selectedIds={creditNote?.lines.map((line) => line.additionsId)}
                idSelectors={["additionsId"]}
                api={getTransactionList}
                apiParams={{ invoiceId: creditNote.header.isnId, Ids: creditNote?.lines.map((line) => line.additionsId) }}
                rowHeight={45}
                tableWidth={200}
                searchPlaceholder={t('num_application') + " , " + t('order_num') + " , " + t('ref_code') + " , " + t('owner') + " , " + t('client_name') + " , " + t('owner_card') + " , " + t('title_deed_no')}
                columns={[
                    { label: '', width: '2%' },
                    { label: '#', width: '3%' },
                    { label: t('num_application'), value: 'code', width: '4%' },
                    { label: t('approval_date'), value: 'approvalDateTime', isDate: true, width: '4%' },
                    { label: t('due_amount'), value: 'orgFeesAlc'/* , isMoney: true */, width: '4%' },
                    { label: t('transaction_type'), valueLo: 'transactionTypeLo', valueFo: 'transactionTypeFo', width: '5%' },
                    { label: t('ref_code'), value: 'refCode', width: '4%' },
                    { label: t('order_num'), value: 'orderNo', width: '4%' },
                    { label: t('order_date'), value: 'orderDateTime', isDate: true, width: '4%' },
                    { label: t('application_date'), value: 'dateTime', isDate: true, width: '4%' },
                    { label: t('city'), valueLo: 'cityDescLo', valueFo: 'cityDescFo', width: '5%' },
                    { label: t('town'), valueLo: 'districtDescLo', valueFo: 'districtDescFo', width: '5%' },
                    { label: t('owner'), value: 'ownerName', width: '5%' },
                    { label: t('client_name'), value: 'clientName', width: '5%' },
                    { label: t('owner_card'), value: 'ownerCardNum', width: '5%' },
                    { label: t('property_type'), valueLo: 'propertyTypeDescLo', valueFo: 'propertyTypeDescFo', width: '4%' },
                    { label: t('property_code'), value: 'propertyCode', width: '4%' },
                    { label: t('title_deed_no'), value: 'titleDeedNo', width: '5%' },
                    { label: t('land_area'), value: 'parcelArea', width: '4%' },
                    { label: t('buildding_area'), value: 'totalBuildArea', width: '4%' },
                    { label: t('number_of_floors'), value: 'NoOfFloors', width: '4%' },
                    { label: t('estimation_price'), value: 'finalPrice', isMoney: true, width: '4%' },
                    { label: t('stage'), valueLo: 'stageDescLo', valueFo: 'stageDescFo', width: '4%' },
                    { label: t('status'), valueLo: 'trnxStatusLo', valueFo: 'trnxStatusFo', width: '4%' },
                ]}
                AdvanceFilters={AdvanceFiltersPanel}
            />
        </>
    )
}

export default Details;