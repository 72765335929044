import { formatDate, formatMoney, handleError, isEnglish, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from "../../../../icons/EstimationMap";
import style from './style.module.scss';
import { useEffect, useState } from "react";
import { getDomainValue } from "../../../../util/apis";
import ClipIcon from "../../../../icons/Clip";
import { CUSTOMER_LEVEL_PREDEFINED } from "../../../../util/constants";
import XIcon from "../../../../icons/X";

const Totals = (props) => {
    const { valuationContract } = props;
    const { t } = useTranslation("valuationContracts");

    const price = (valuationContract?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.price), 0);
    const vat = (valuationContract?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.vat), 0);
    const netPrice = (valuationContract?.lines || []).reduce((accumulator, currentValue) => parseNumber(accumulator) + parseNumber(currentValue.netPrice), 0);

    return (
        <div className={style.totalsTrx}>
            <div>
                <div className='totalsLbl'>
                    <label>{t('customersEstimationInvoices:count')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{(valuationContract?.lines || []).length}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('totlal_price')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(price)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('vat')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(vat)}</label>
                    </div>
                </div>
            </div>
            <div>
                <div className='totalsLbl'>
                    <label>{t('net_price')}</label>
                </div>

                <div className='totalsContainerVal'>
                    <div className='netPrice'>
                        <label>{formatMoney(netPrice)}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GlobalStatus = (props) => {
    const { data } = props;

    const { t } = useTranslation("moneyTransferVouchers");

    const [status, setStatus] = useState([]);

    useEffect(() => {
        if (isNotEmpty(data?.globalStatus)) {
            getDomainValue(data?.globalStatus)
                .then((response) => setStatus(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, [data?.globalStatus])

    if (isNotEmpty(status?.id)) {
        return (
            <div className={`status-${status?.id}`}>
                <label>
                    {isEnglish() ? status?.descLo : status?.descFo}
                </label>
            </div>
        );
    } else {
        return (<></>);
    }
}

const Summary = (props) => {
    const { valuationContract, handleOpenSummary } = props;
    const { t } = useTranslation();

    return (
        <div className="summary-container-open-close">
            <div className="summary-header-title-open-close">
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('summary')}</label>
                </div>

                <div className="attach-summary-container">
                    <div className="attachment-summary">
                        <span>
                            <ClipIcon />
                        </span>
                        <div>{valuationContract?.numberOfAttachments}</div>
                    </div>
                    <button onClick={handleOpenSummary}>
                        <XIcon />
                    </button>
                </div>
            </div>
            <div className="summaryContainerWithTotal">
                <div className="summary-table">
                    <div className="frm-summary-card">
                        <div className={style.summaryCode}>
                            <div className="td-column">#{valuationContract?.code}</div>
                            <div>
                                <GlobalStatus data={valuationContract} />
                            </div>
                        </div>
                        {valuationContract?.contractRefCode || formatDate(valuationContract?.contractDateDgr) ?
                            <div className="summary-ref">
                                <div>
                                    <label className="form-label">{t('valuationContracts:contract_code')}</label>
                                    <div>{valuationContract?.contractRefCode}</div>
                                </div>
                                <div>
                                    <label className="form-label">{t('valuationContracts:contract_date')}</label>
                                    <div>{formatDate(valuationContract?.contractDateDgr) || '-'}</div>
                                </div>
                            </div>
                            :
                            <></>
                        }

                        {((valuationContract.customerLevel == CUSTOMER_LEVEL_PREDEFINED) ? (isEnglish() ? valuationContract?.customerData?.descLo : valuationContract?.customerData?.descFo) : valuationContract?.customerName) || ((valuationContract.customerLevel == CUSTOMER_LEVEL_PREDEFINED) ? (valuationContract?.customerData?.mobileNum) : valuationContract?.mobileNumber) ?
                            <div className="summary-ref">
                                <div>
                                    <label className="form-label">{t('valuationContracts:customer_name')}</label>
                                    <div>{(valuationContract.customerLevel == CUSTOMER_LEVEL_PREDEFINED) ? (isEnglish() ? valuationContract?.customerData?.descLo : valuationContract?.customerData?.descFo) : valuationContract?.customerName}</div>
                                </div>
                                <div>
                                    <label className="form-label">{t('valuationContracts:mobile_num')}</label>
                                    <div>{(valuationContract.customerLevel == CUSTOMER_LEVEL_PREDEFINED) ? (valuationContract?.customerData?.mobileNum) : valuationContract?.mobileNumber}</div>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        {valuationContract?.projectName &&
                            <div className="desc-summary-container">
                                <label>{t('valuationContracts:project_name')}</label>
                                <div className="desc-summary" title={valuationContract?.projectName}>
                                    {valuationContract?.projectName}
                                </div>
                            </div>
                        }
                        {valuationContract?.contractDescription &&
                            <div className="desc-summary-container">
                                <label>{t('valuationContracts:contract_desc')}</label>
                                <div className="desc-summary" title={valuationContract?.contractDescription}>
                                    {valuationContract?.contractDescription}
                                </div>
                            </div>
                        }
                        {valuationContract?.contractDetails &&
                            <div className="desc-summary-container">
                                <label>{t('valuationContracts:contract_details')}</label>
                                <div className="desc-summary" title={valuationContract?.contractDetails}>
                                    {valuationContract?.contractDetails}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* <Addons data={data} /> */}
                {/* <Deductions data={data} /> */}
                <Totals valuationContract={valuationContract} />
            </div>
        </div>

    );
}

export default Summary;