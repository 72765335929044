import ActionsButton from "@ipgd-gauge/actions-button";
import { isAllowedAction } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import PloicyIcon from "../../icons/PloicyIcon";
import { exportEstimationInvoiceFollowUpToExcel } from "../../util/apis";
import { PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID } from "../../util/constants";
import { EXPORT_ACTION } from "../../util/constants/actions";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import { openTotalTableSm } from "../../util/util";
import PolicyFilter from "../../views/common/filters/policy-filter";
import EstimationInvoicesFollowUpTable from "./listing";
import EstimationInvoicesFollowUpFilter from "./listing/Filters";

const EstimationInvoicesFollowUp = () => {
    const { t } = useTranslation("");

    const tableRef = useRef();
    const filterRef = useRef();
    const policyFilterRef = useRef();

    const user = getUser();

    const _exportExcelAction = () => {
        const filterData = { ...filterRef?.current?.getFilterData(), columnSetting: tableRef.current.getSelectedSettingId() };
        exportEstimationInvoiceFollowUpToExcel(filterData, "Estimation Invoices Follow Up", "export-transactions-invoices")
    }

    const _getActions = () => {
        const actions = [];

        if (isAllowedAction(user, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, EXPORT_ACTION)) {
            actions.push({ id: `export-excel-sheet`, icon: <ExcelIcon />, label: t('export'), action: _exportExcelAction });
        }
        return actions;
    }

    return (
        <>
            <div className="headerPagesPanelReportSm">
                <FormTitle formId={RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID} />

                <div className="headerSearchPanel policieHeaderSearchReprtsSm">
                    <div className="ploicyFilterContainer">
                        <PolicyFilter
                            id='estimation-invoices-follow-up-policy-filter'
                            ref={policyFilterRef}
                            formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                            filterRef={filterRef}
                            functionFormId={PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID}
                        />
                    </div>
                    <div className="reports-header-actions">
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('estimation-invoices-follow-up-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                        <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                            <FiltersIcon />
                        </button>
                    </div>
                </div>
            </div>


            <EstimationInvoicesFollowUpTable user={user} tableRef={tableRef} filterRef={filterRef} />

            <EstimationInvoicesFollowUpFilter
                user={user}
                ref={filterRef}
                tableRef={tableRef}
                policyFilterRef={policyFilterRef}
            />

            <ActionsButton actions={_getActions()} />
        </>
    )
}

export default EstimationInvoicesFollowUp;