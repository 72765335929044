import FrmInput from "@ipgd-gauge/frm-input";
import LocalCurrencyInput from "@ipgd-gauge/local-currency";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from '@ipgd-gauge/table';
import { confirmationDialog, deleteItemByIndex, isEmpty, showNotificationToast } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ForeignCurrencyInput from "../../../components/foreign-currency";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExcelIcon from "../../../icons/Excel";
import { exportCustPropertyType, getCommonPropertyTypes } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import style from './style.module.scss';
import { useRef } from "react";

const COMMON_ID = "price-agreements";

const PropertyTypeFeesUpon = (props) => {
    const { data, setData, lov, currentUser } = props;

    const { t } = useTranslation();
    const tableRef = useRef();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.priceListAgreementCustPropertyTypes = [];
            setData({ ...data });
        });
    }

    const _insertAction = () => {
        data.priceListAgreementCustPropertyTypes = [...(data.priceListAgreementCustPropertyTypes || []), ...[{ isActive: STATUS_ACTIVE, priceAfc: 0, priceAlc: 0 }]];
        setData({ ...data });
    }

    const _exportCustPropertyType = () => {
        const unsavedLines = (data?.priceListAgreementCustPropertyTypes || []).filter(line => isEmpty(line.id));
        if (unsavedLines?.length > 0) {
            showNotificationToast(t('priceAgreement:export_unsaved_records_notification'), { hideProgressBar: true, type: 'warning' })
        }
        exportCustPropertyType(data.id, tableRef.current.getSelectedSettingId(), data.code || '');
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('priceAgreement:property_type_fees_upon')}
                    </label>
                    {data.id &&
                        <div>
                            <button
                                id={`property-type-fees-export-btn`}
                                title={t('export')}
                                className={style.exportButton}
                                onClick={_exportCustPropertyType}
                                disabled={(data?.priceListAgreementCustPropertyTypes || [])?.length === 0}
                            >
                                <ExcelIcon color="green" />
                                <Spinner
                                    id={`property-type-fees-export-btn-spinner`}
                                    className={style.spinnerButton}
                                />
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActionsOrg">
                        <button
                            onClick={_insertAction}
                            title={t('priceAgreement:add')}
                        >
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('priceAgreement:add')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            disabled={(data?.priceListAgreementCustPropertyTypes || [])?.length === 0}
                            title={t('priceAgreement:delete_all')}
                        >
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('priceAgreement:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            <PropertyTypeFeesUponTable data={data} setData={setData} lov={lov} currentUser={currentUser} tableRef={tableRef} />
        </div>
    )
}

const PropertyTypeFeesUponTable = (props) => {
    const { data, setData, lov, currentUser ,tableRef } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.priceListAgreementCustPropertyTypes) {
            data.priceListAgreementCustPropertyTypes = [];
        }

        deleteItemByIndex(data.priceListAgreementCustPropertyTypes, index)
        setData({ ...data });
    }

    const _handleDataChange = (index, name, value) => {
        data.priceListAgreementCustPropertyTypes[index][name] = value;
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: '5%' },
        { title: t('priceAgreement:property_type'), width: isLocalCurrency(data?.fcuId) ? "20%" : '15%', excelColumn: "PROPERTY_TYPE_DESC_LO,PROPERTY_TYPE_DESC_FO" },
        { title: <label data-property="priceAfc" className="mandatory">{t('priceAgreement:price_fc')}</label>, width: isLocalCurrency(data?.fcuId) ? "0%" : '15%', excelColumn: "IMM_PRICE_AFC" },
        { title: <label data-property="priceAlc" className="mandatory">{t('priceAgreement:price_lc')}</label>, width: isLocalCurrency(data?.fcuId) ? "20%" : '15%', excelColumn: "IMM_PRICE_ALC" },
        { title: <label data-property="isActive" className="mandatory">{t('priceAgreement:status')}</label>, width: isLocalCurrency(data?.fcuId) ? "20%" : '15%', excelColumn: "STATUS_DESC_LO,STATUS_DESC_FO" },
        { title: t('priceAgreement:remarks'), width: '25%', excelColumn: "IMM_REM" },
        { title: "", width: '10%' }
    ]

    return (
        <>
            <div className={style.propertyTypeFeesUponTableContainer}>
                <Table
                    id={`${COMMON_ID}-property-type-fees-upon-table`}
                    columns={columns}
                    ref={tableRef}
                    Row={PropertyTypeFeesUponRow}
                    tableStyle={style.propertyTypeFeesUponTable}
                    rowActions={{
                        handleDeleteLine: _handleDeleteLine,
                        handleDataChange: _handleDataChange,
                        lov,
                        masterData: data
                    }}
                    list={data?.priceListAgreementCustPropertyTypes || []}
                    formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
                    user={currentUser}
                    rowHeight={40}
                />
            </div>
        </>
    );
}

const PropertyTypeFeesUponRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDeleteLine, handleDataChange, lov, masterData } = rowActions;

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.propertyTypeFeesUponRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div> <div>{data?.sequence}</div></div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-tab-2-property-type-${index}`}
                        value={data?.myoId}
                        onChange={value => handleDataChange(index, "myoId", value.value)}
                        api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, [], false, isEmpty(data?.myoId) ? [] : [data?.myoId])}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <ForeignCurrencyInput
                        id={`${COMMON_ID}-tab2-price-afc-${index}`}
                        value={data?.priceAfc}
                        onBlur={(value) => handleDataChange(index, "priceAfc", value)}
                        changeAlcAmount={(value) => handleDataChange(index, "priceAlc", value)}
                        currencyId={masterData.fcuId}
                        exchngRate={masterData.exchngRate}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <LocalCurrencyInput
                        id={`${COMMON_ID}-tab-2-price-alc-${index}`}
                        value={data?.priceAlc}
                        onBlur={value => handleDataChange(index, "priceAlc", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-tab-2-status-${index}`}
                        value={data?.isActive}
                        options={lov.status}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "isActive", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${COMMON_ID}-tab-2-remark-${index}`}
                        value={data?.rem}
                        onBlur={(value) => handleDataChange(index, "rem", value)}
                    />
                </div>
            </div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div >
    );
};

export default PropertyTypeFeesUpon;