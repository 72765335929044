import ActionsButton from "@ipgd-gauge/actions-button";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isAllowedAction } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import PloicyIcon from "../../icons/PloicyIcon";
import { exportTransactionReportToExcel, getDomainValues } from "../../util/apis";
import { DOMAIN_REPORT_NAME, REPORT_CUSTOMERS, REPORT_CUSTOMERS_ACCORDING_REGION_CITY, REPORT_EMPLOYEES_INSPECTORS, REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY, REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE, REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS, REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK, REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE } from "../../util/constants";
import { EXPORT_ACTION } from "../../util/constants/actions";
import { RSM3250_VALUATION_REPORTS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import { openTotalTableSm } from "../../util/util";
import EstimationReportsTable from "./listing";
import EstimationReportsFilter from "./listing/Filter";
import style from './style.module.scss';

const EstimationReports = () => {
    const { t } = useTranslation();

    const searchTextRef = useRef();
    const estimationReportsTableRef = useRef();
    const estimationReportsFilterRef = useRef();

    const user = getUser();

    const [reportNumber, setReportNumber] = useState(REPORT_EMPLOYEES_INSPECTORS);

    const _exportExcelAction = () => {
        const filterData = { ...estimationReportsFilterRef?.current?.getFilterData(), columnSetting: estimationReportsTableRef.current.getSelectedSettingId() };
        exportTransactionReportToExcel(reportNumber, filterData, t("estimationTransaction:report_" + reportNumber), `export-excel-sheet`)
    }

    const _getActions = () => {
        const actions = [];
        if (isAllowedAction(user, RSM3250_VALUATION_REPORTS_FORM_ID, EXPORT_ACTION)) {
            actions.push({
                id: `export-excel-sheet`,
                icon: <ExcelIcon />,
                label: t('export'),
                action: _exportExcelAction
            });
        }

        return actions;
    }

    return (
        <>
            <div className={`headerPagesPanel ${style.headerAction}`}>
                <FormTitle formId={RSM3250_VALUATION_REPORTS_FORM_ID} />

                <div className="headerSearchPanel">

                    {
                        reportNumber == REPORT_EMPLOYEES_INSPECTORS &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:party')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> ||
                        reportNumber == REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:party') + ' , ' + t('estimationReports:region') + ' , ' + t('estimationReports:city')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_CUSTOMERS &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:customer')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_CUSTOMERS_ACCORDING_REGION_CITY &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:customer') + ' , ' + t('estimationReports:region') + ' , ' + t('estimationReports:city')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:region') + ' , ' + t('estimationReports:property_type')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:party') + ' , ' + t('estimationReports:task')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:party') + ' , ' + t('estimationReports:customer')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        /> || reportNumber == REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE &&
                        <QuickSearch
                            ref={searchTextRef}
                            placeholder={t('estimationReports:party') + ' , ' + t('estimationReports:task')}
                            search={() => estimationReportsTableRef?.current?.refresh()}
                            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
                        />
                    }
                    <button className="headerPanelBtn" onClick={() => estimationReportsFilterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                    {reportNumber == REPORT_EMPLOYEES_INSPECTORS &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('employees-inspectors-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('employees-inspectors-according-region-city-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_CUSTOMERS &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('customers-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_CUSTOMERS_ACCORDING_REGION_CITY &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('customers-according-region-city-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('regions-according-properties-type-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('inspectors-employees-according-task-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('employees-inspectors-report-according-customers-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                    {reportNumber == REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE &&
                        <button className="totalsBtnSm" onClick={() => openTotalTableSm('employees-inspectors-execution-percentage-report-total')}>
                            <label>{t('veiw_total')}</label>
                            <PloicyIcon />
                        </button>
                    }
                </div>
            </div>

            <div className={style.reportName}>
                <div>
                    <FrmSelectMenu
                        id="report-name"
                        value={reportNumber}
                        api={() => getDomainValues(DOMAIN_REPORT_NAME)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => setReportNumber(e.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <EstimationReportsTable
                user={user}
                estimationReportsTableRef={estimationReportsTableRef}
                estimationReportsFilterRef={estimationReportsFilterRef}
                searchTextRef={searchTextRef}
                reportNumber={reportNumber}
            />

            <EstimationReportsFilter
                ref={estimationReportsFilterRef}
                estimationReportsTableRef={estimationReportsTableRef}
            />

            <ActionsButton actions={_getActions()} />
        </>
    );
}

export default EstimationReports;