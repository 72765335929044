import Image from "@ipgd-gauge/image";
import { fixImagePath, formatDateNTime, isEmpty, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map from "../../../../components/map";
import MapCard from "../../../../components/map-card";
import Modal from "../../../../components/modal";
import LocationMapIcon from "../../../../icons/LocationMap";
import MapPinIcon from "../../../../icons/MapPin";
import StarIcon from "../../../../icons/Star";
import UserMapIcon from "../../../../icons/UserMap";
import { getEstimationTrxsMapList, getTransactionForCompare, getTrxAttachment } from "../../../../util/apis";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const TrnxInfo = (props) => {
    const { data, getTrxKey } = props;
    const { t } = useTranslation("estimationMap");
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages([]);
        if (data.id) {
            getTrxAttachment(getTrxKey())
                .then(response => {
                    setImages(response.data)
                })
                .catch((error) => {
                    console.log("🚀  error", error)
                })
        }
    }, [data.id])

    return (
        <div className={style.newCardContainer}>
            <div className={style.imgSection}>
                <Image src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.mainImgPath)}`} />
                <div className={style.typeNewCard}>
                    <label className={style.locationLbl}>
                        {isEnglish() ? data.propertyTypeDescLo : data.propertyTypeDescLo}
                    </label>
                </div>
            </div>
            <div className={style.infoSection}>
                <div className={style.newCardCode}>
                    <label>{data.code || ''}</label>
                </div>
                <div className={style.newCardPriceNum}>
                    <label>{data.evaluationPrice || 0}</label>
                    <span>{isEnglish() ? data.currencyDescLo : data.currencyDescFo}</span>
                </div>
                <div className={style.newCardDate}>
                    <label>{formatDateNTime(data.dateTime)}</label>
                </div>
                <div className={style.newCardUser}>
                    <UserMapIcon />
                    <label>
                        {isEnglish() ? data.officialNameLo : data.officialNameFo}
                    </label>
                </div>
                <div className={style.newCardLocation}>
                    <LocationMapIcon />
                    <label>
                        {isEnglish() ? data.addressLo || '' : data.addressFo || ''}
                    </label>
                </div>
                <div className={style.userInfoBox}>
                    <div className={style.areaSection}>
                        <div>
                            <label className={style.lblName}>{t('land_area')}</label>
                            <label className={style.lblVal}>
                                {data.landArea || ''}
                                <span>{isEnglish() ? 'M2' : 'م2'}</span>
                            </label>
                        </div>
                        <div>
                            <label className={style.lblName}>{t('price_of_one_meter')}</label>
                            <label className={style.lblVal}>
                                {data.parcelPricePerM || ''}
                                <span>{isEnglish() ? data.currencyDescLo : data.currencyDescFo}</span>
                            </label>
                        </div>
                    </div>
                    <div className={style.buildingSection}>
                        <div>
                            <label className={style.lblName}>{t('building_area')}</label>
                            <label className={style.lblVal}>
                                {data.buildingArea || ''}
                                <span>{isEnglish() ? 'M2' : 'م2'}</span>
                            </label>
                        </div>
                        <div>
                            <label className={style.lblName}>{t('price_of_one_meter')}</label>
                            <label className={style.lblVal}>
                                {data.buildMeterValue || ''}
                                <span>{isEnglish() ? data.currencyDescLo : data.currencyDescFo}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {images.length > 0 &&
                <div className={style.containerGallery}>
                    <div className={style.galleryTitle}>
                        <label>Images</label>
                    </div>
                    <div className={style.multiImages}>
                        {images.map((img, index) => {
                            return (
                                <div key={index} className={style.imgs}>
                                    <Image src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(img.filePath)}`} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

const MapPinCard = (props) => {
    const { data, transactionId } = props;
    const mapCardId = `similars-map-card-${data.estimationTrxId ? data.estimationTrxId : data.estimationHistoryId}`;

    return (
        <>
            <div
                className={style.estimationMapPin}
                onMouseOver={() => document.getElementById(mapCardId)?.classList?.add('active')}
                onMouseLeave={() => document.getElementById(mapCardId)?.classList?.remove('active')}
            >
                <div>
                    {transactionId == data?.estimationTrxId ?
                        <StarIcon />
                        :
                        <MapPinIcon />
                    }
                </div>
                <MapCard id={mapCardId} data={data} />
            </div>
        </>
    )
}

const MapPanel = (props) => {
    const { mapRef, mapData, keyNumber, selectedTrx, setSelectedTrx, transactionId, getTransactionMapList, value, zoom } = props;

    const _handleSelectTransaction = (data, count) => {
        if (selectedTrx.transactions.filter(trx => trx.estimationTrxId == data.estimationTrxId).length > 0) {
            selectedTrx.transactions = selectedTrx.transactions.filter(trx => trx.estimationTrxId != data.estimationTrxId)
        } else if (count < 5) {
            selectedTrx.transactions.push(data);
        }
        setSelectedTrx({ ...selectedTrx });
    }

    const _handleSelectHistoryTransaction = (data, count) => {
        if (selectedTrx.historyTransactions.filter(trx => trx.estimationHistoryId == data.estimationHistoryId).length > 0) {
            selectedTrx.historyTransactions = selectedTrx.historyTransactions.filter(trx => trx.estimationHistoryId != data.estimationHistoryId)
        } else if (count < 5) {
            selectedTrx.historyTransactions.push(data);
        }
        setSelectedTrx({ ...selectedTrx });
    }

    const _handleSelect = (data) => {
        const count = [...selectedTrx.transactions, ...selectedTrx.historyTransactions].length;
        if (isNotEmpty(data.estimationTrxId)) {
            _handleSelectTransaction(data, count);
        } else {
            _handleSelectHistoryTransaction(data, count);
        }
    }

    return (
        <div id="similar-land-n-bulid-map-container" className={style.mapContainer}>
            <Map
                key={`similar-land-n-bulid-${keyNumber}`}
                ref={mapRef}
                MapPin
                withSearch
                data={mapData || {}}
                value={value}
                zoom={zoom}
                MapPinCard={(props) => <MapPinCard {...props} transactionId={transactionId} />}
                onPinClick={_handleSelect}
                onChange={(e) => getTransactionMapList(e)}
            />
            {[...selectedTrx.transactions, ...selectedTrx.historyTransactions]?.length > 0 &&
                <div id="similarRealStateCard" className={style.similarRealState}>
                    {[...selectedTrx.transactions, ...selectedTrx.historyTransactions].map((data) => {
                        return (
                            <MapCard data={data} withCancelButton cancelButtonAction={() => _handleSelect(data)} showAlways />
                        );
                    })}
                </div>
            }
        </div>
    )
}

const AddSimilarLandNBulidFromMapDialog = (props, ref) => {

    const { getTrxKey, okAction, postClose } = props;
    const { t } = useTranslation("estimationMap");
    const dialogRef = useRef(null);
    const mapRef = useRef();
    const firstTimeRef = useRef(true);
    const mapDataChangedRef = useRef(false);

    const [data, setData] = useState({});
    const [mapData, setMapData] = useState({});
    const [selectedTrx, setSelectedTrx] = useState({ transactions: [], historyTransactions: [] });
    const forceRender = useRef(1)

    const compareType = useRef();

    const open = async (trnx, type) => {
        firstTimeRef.current = false;
        mapDataChangedRef.current = false;
        forceRender.current = forceRender.current + 1;
        compareType.current = type;
        setData({ ...trnx, id: trnx?.TRI_ID || -1 });
        const transactionLat = trnx.TRI_LATITUDE;
        const transactionLong = trnx.TRI_LONGITUDE;
        const apis = [getTransactionForCompare({ trxKey: getTrxKey() })];
        if (isEmpty(transactionLat) || isEmpty(transactionLong)) {
            const mapData = mapRef.current?.getMapData();
            let data = {
                triId: trnx?.TRI_ID || -1,
                trxKey: getTrxKey(),
                // onlyTrnxIds: '1'
                zoom: mapData?.zoom || 4,
                minLat: mapData?.bounds?.sw?.lat,
                minLng: mapData?.bounds?.sw?.lng,
                maxLat: mapData?.bounds?.ne?.lat,
                maxLng: mapData?.bounds?.ne?.lng,
            }
            apis.push(getEstimationTrxsMapList(0, 100, data));
        } else {
            mapRef.current?.changePinLocation(transactionLat, transactionLong);
        }
        Promise.all(apis)
            .then((response) => {
                setData({ ...trnx, ...(response[0].data || {}) })
                if (response.length > 1) {
                    setMapData({ ...trnx, ...(response[1].data || {}) });
                }
            });
        dialogRef.current.open();
    }

    const _getTransactionMapList = (mapData) => {
        if (firstTimeRef.current) {
            return;
        }
        mapDataChangedRef.current = true
        const filterData = {
            triId: data.id || -1,
            trxKey: getTrxKey(),
            zoom: mapData?.zoom || 4,
            minLat: mapData?.bounds?.sw?.lat,
            minLng: mapData?.bounds?.sw?.lng,
            maxLat: mapData?.bounds?.ne?.lat,
            maxLng: mapData?.bounds?.ne?.lng,
        }
        getEstimationTrxsMapList(0, 100, filterData)
            .then((response) => setMapData(response.data || {}))
            .catch(() => { });
    }

    const close = () => {
        dialogRef.current.close();
    }

    const _postClose = () => {
        compareType.current = null;
        mapDataChangedRef.current = false;
        setData({})
        setMapData({})
        setSelectedTrx({ transactions: [], historyTransactions: [] });
        if (postClose) {
            postClose();
        }
    }

    const _okAction = () => {
        if (okAction) {
            okAction(selectedTrx || [], compareType.current);
        }
    }

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="add-similar-land-n-bulid-from-map-dialog"
            ref={dialogRef}
            title={t('add_map')}
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.comperDialog}
            okBtnAction={_okAction}
            postClose={_postClose}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            withFooter
            withCancelButton
            withOkButton
        >
            <div className={style.mainComper}>
                <TrnxInfo
                    data={data}
                    getTrxKey={getTrxKey}
                />
                <MapPanel
                    mapRef={mapRef}
                    mapData={mapData}
                    keyNumber={forceRender.current}
                    selectedTrx={selectedTrx}
                    setSelectedTrx={setSelectedTrx}
                    transactionId={data.id || "-1"}
                    getTransactionMapList={_getTransactionMapList}
                    value={(isEmpty(data.TRI_LATITUDE) || isEmpty(data.TRI_LONGITUDE)) ? null : `${data.TRI_LATITUDE},${data.TRI_LONGITUDE}`}
                    zoom={isEmpty(data.TRI_MAP_ZOOM) || mapDataChangedRef.current ? null : Number(data.TRI_MAP_ZOOM)}
                />
            </div>
        </Modal>
    )
}

export default forwardRef(AddSimilarLandNBulidFromMapDialog);