import Checkbox from "@ipgd-gauge/checkbox";
import { FrmDecimalInput } from "@ipgd-gauge/decimal-input";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { getUrlParam, isEmpty, isEnglish, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import FrmGHDatePicker from "../../../../components/frm-g-h-date";
import { getCitiesLov, getCommonPropertyTypes, getTownsList } from "../../../../util/apis";
import { ESTIMATION_QUOTATION_AREA_LEVEL_FIELD, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_PRICE_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_REM_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, ESTIMATION_QUOTATION_VAT_FIELD, ESTIMATION_QUOTATION_VAT_PERC_FIELD, STATUS_ACTIVE } from '../../../../util/constants';
import style from "../style.module.scss";

const ID = "valation-contract";
const Row = (props) => {
    const { virtualizedStyle, data, rowActions, index, onRowClick, rowClassName } = props
    const { sequence, id, newId } = data;
    const { handleRowChange, lovs, deleteRow, valuationContract, dialogRef, editRow } = rowActions;
    return (
        <div key={newId || id} dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.tableRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div>
                <div>
                    {sequence}
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-description`}
                        value={data.description}
                        onBlur={value => handleRowChange(index, 'description', value)}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-deed-number`}
                        value={data.deedNum}
                        onBlur={value => handleRowChange(index, 'deedNum', value)}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmGHDatePicker

                        id={`${ID}-${index}-buy-instrument-date`}
                        disabledHijri={false}
                        onGregorianChange={(value) => handleRowChange(index, "titleDeedDgr", value)}
                        hijriDate={data?.titleDeedDhi}
                        gregorianDate={data?.titleDeedDgr}
                        onHijriChange={(value) => handleRowChange(index, "titleDeedDhi", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-license-number`}
                        value={data.licenceNum}
                        onBlur={value => handleRowChange(index, 'licenceNum', value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmGHDatePicker
                        id={`${ID}-${index}-license-date-d-h`}
                        gregorianDate={data?.licenceDateDgr}
                        onGregorianChange={(value) => handleRowChange(index, "licenceDateDgr", value)}
                        hijriDate={data?.licenceDateDhi}
                        disabledHijri={false}
                        onHijriChange={(value) => handleRowChange(index, "licenceDateDhi", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-property-description`}
                        value={data?.propertyDescription}
                        onBlur={(val) => handleRowChange(index, "propertyDescription", val)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-region`}
                        value={data.frsId}
                        onChange={value => handleRowChange(index, "frsId", value)}
                        options={lovs.regions}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-city`}
                        key={`${ID}-${index}-city-${data.frsId}`}
                        value={data.ecdId}
                        onChange={value => handleRowChange(index, "ecdId", value)}
                        defualtValueId={data.ecdId}
                        defualtValueApi={() => getCitiesLov(isEmpty(data.ecdId) ? [] : [data.ecdId])}
                        api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, data.frsId, searchValue)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!data.frsId || !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD)}
                        isAsync={true}
                        portalTarget={document.body}
                        onDataRetrieved={(list) => {
                            const id = getUrlParam("id");
                            if (isNotEmpty(id) && data.ecdId) {
                                list?.forEach((element) => {
                                    if (element.id == data.ecdId) {
                                        handleRowChange(index, "cityData", { ...element })
                                        return;
                                    }
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-town`}
                        key={`${ID}-${index}-town-${data.ecdId}`}
                        value={data.criId}
                        onChange={value => handleRowChange(index, "criId", value)}
                        api={(searchKey) => getTownsList(searchKey, null, data.ecdId, STATUS_ACTIVE, true, null, true)}
                        defualtValueId={data.criId}
                        defualtValueApi={() => getTownsList(null, isEmpty(data.criId) ? [] : [data.criId], data.ecdId, STATUS_ACTIVE, true, null, true)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isAsync
                        isDisabled={!data.ecdId || !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD)}
                        portalTarget={document.body}
                        onDataRetrieved={(list) => {
                            const id = getUrlParam("id");
                            if (isNotEmpty(id) && data.criId) {
                                list?.forEach((element) => {
                                    if (element.id == data.criId) {
                                        handleRowChange(index, "townData", { ...element })
                                        return;
                                    }
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-type`}
                        value={data.myoId}
                        api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, false, true, isEmpty(data.myoId) ? [] : [data.myoId])}
                        onChange={value => handleRowChange(index, "myoId", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-area-level`}
                        value={data.areaLevel}
                        options={lovs.areaLevels}
                        onChange={value => handleRowChange(index, "areaLevel", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isDisabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-area-from`}
                        value={data.landAreaFrom || ''}
                        onBlur={value => handleRowChange(index, "landAreaFrom", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-area-to`}
                        value={data.landAreaTo || ''}
                        onBlur={value => handleRowChange(index, "landAreaTo", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>


            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-no-of-floors-from`}
                        value={data.noOfFloorsFrom || ''}
                        onBlur={value => handleRowChange(index, "noOfFloorsFrom", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-no-of-floors-to`}
                        value={data.noOfFloorsTo || ''}
                        onBlur={value => handleRowChange(index, "noOfFloorsTo", value)}
                        decimalScale={-1}
                        allowDecimals={true}
                        withoutFormat
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-cost-approch`}
                        isChecked={data?.aproachCost}
                        setIsChecked={value => handleRowChange(index, "aproachCost", value)}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-market-approch`}
                        isChecked={data?.aproachMarket}
                        setIsChecked={value => handleRowChange(index, "aproachMarket", value)}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-income-approch`}
                        isChecked={data?.aproachIncome}
                        setIsChecked={value => handleRowChange(index, "aproachIncome", value)}
                    />
                </div>
            </div>

            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-replacement-cost`}
                        isChecked={data?.methodReplaceCost}
                        setIsChecked={value => handleRowChange(index, "methodReplaceCost", value)}
                        disabled={!data?.aproachCost}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-comparison`}
                        isChecked={data?.methodComparision}
                        setIsChecked={value => handleRowChange(index, "methodComparision", value)}
                        disabled={!data?.aproachMarket}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-income-capitalization-investment`}
                        isChecked={data?.methodIncomeCaptal}
                        setIsChecked={value => handleRowChange(index, "methodIncomeCaptal", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-remaining-method`}
                        isChecked={data?.methodRemainValue}
                        setIsChecked={value => handleRowChange(index, "methodRemainValue", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-discounted-cashflows`}
                        isChecked={data?.methodCashFlow}
                        setIsChecked={value => handleRowChange(index, "methodCashFlow", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkboxContainer}>
                    <Checkbox
                        id={`${ID}-${index}-profits`}
                        isChecked={data?.methodInvestment}
                        setIsChecked={value => handleRowChange(index, "methodInvestment", value)}
                        disabled={!data?.aproachIncome}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-area-level`}
                        value={data.estimationReason}
                        options={lovs.estimationReason}
                        onChange={value => handleRowChange(index, "estimationReason", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-value-assumption`}
                        value={data.valueAssumption}
                        options={lovs.valueAssumption}
                        onChange={value => handleRowChange(index, "valueAssumption", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-use`}
                        value={data.propertyUse}
                        options={lovs.propertyUse}
                        onChange={value => handleRowChange(index, "propertyUse", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${ID}-${index}-property-use`}
                        value={data.estimationBase}
                        options={lovs.estimationBase}
                        onChange={value => handleRowChange(index, "estimationBase", value)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>


            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-price`}
                        value={data.price || ''}
                        onBlur={value => handleRowChange(index, "price", value)}
                        allowDecimals={true}
                        defaultValue={''}
                        minValue={0}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-vat-percentage`}
                        value={data.vatPerc}
                        onBlur={value => handleRowChange(index, "vatPerc", value)}
                        minValue={0}
                        allowDecimals={true}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-vat`}
                        value={parseNumber(data.vat)}
                        onBlur={value => handleRowChange(index, "vat", value)}
                        allowDecimals={true}
                        minValue={0}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${ID}-${index}-net-price`}
                        value={data.netPrice}
                        allowDecimals={true}
                        minValue={0}
                        disabled={true}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${ID}-${index}-rem`}
                        value={data.rem}
                        onBlur={value => handleRowChange(index, 'rem', value)}
                        disabled={!valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD)}
                    />
                </div>
            </div>
            <div>
                <div className={` ${style.tableBtns} deleteBtnSm`}>
                    <EditBtn onClick={() => editRow(index)} />
                    <DeleteBtn onClick={() => deleteRow(index)} />
                </div>
            </div>
        </div>
    )
}
export default Row;