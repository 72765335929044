import FrmInput from "@ipgd-gauge/frm-input";
import LocalCurrencyInput from "@ipgd-gauge/local-currency";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from '@ipgd-gauge/table';
import { confirmationDialog, deleteItemByIndex, isEmpty, showNotificationToast } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import ForeignCurrencyInput from "../../../components/foreign-currency";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExcelIcon from "../../../icons/Excel";
import { exportCustomFees, getCitiesLov, getCommonCustomers, getCommonPropertyTypes, getRegionsLov, getTownsList } from "../../../util/apis";
import { AREA_LEVEL_LAND_AREA, PARTY_LEVEL_CUSTOMER, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import style from './style.module.scss';

const COMMON_ID = "price-agreements";

const CustomFees = (props) => {
    const { data, setData, lov, currentUser } = props;
    const { t } = useTranslation();
    const tableRef = useRef();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.priceListAgreementFieldsCustoms = [];
            setData({ ...data });
        });
    }

    const _insertAction = () => {
        data.priceListAgreementFieldsCustoms = [...(data.priceListAgreementFieldsCustoms || []), ...[{ isActive: STATUS_ACTIVE, landAreaFrom: null, landAreaTo: null, noOfFloorsFrom: null, noOfFloorsTo: null, estimationPriceFrom: null, estimationPriceTo: null, priceAlc: 0, priceAfc: 0, areaLevel: AREA_LEVEL_LAND_AREA }]];
        setData({ ...data });
    }

    const _exportCustomFees = () => {
        const unsavedLines = (data?.priceListAgreementFieldsCustoms || []).filter(line => isEmpty(line.id));
        if (unsavedLines?.length > 0) {
            showNotificationToast(t('priceAgreement:export_unsaved_records_notification'), { hideProgressBar: true, type: 'warning' })
        }
        exportCustomFees(data.id, tableRef.current.getSelectedSettingId(), data.code || '');
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('priceAgreement:custom_fees')}
                    </label>
                    {data.id &&
                        <div>
                            <button
                                id={`custom-fees-export-btn`}
                                title={t('export')}
                                className={style.exportButton}
                                onClick={_exportCustomFees}
                                disabled={(data?.priceListAgreementFieldsCustoms || [])?.length === 0}
                            >
                                <ExcelIcon color="green" />
                                <Spinner
                                    id={`custom-fees-export-btn-spinner`}
                                    className={style.spinnerButton}
                                />
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActionsOrg">
                        <button onClick={_insertAction} title={t('priceAgreement:add')}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('priceAgreement:add')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            title={t('priceAgreement:delete_all')}
                            disabled={(data?.priceListAgreementFieldsCustoms || [])?.length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('priceAgreement:delete_all')}
                            </label>

                        </button>
                    </div>
                </div>
            </div>
            <CustomFeesTable data={data} setData={setData} lov={lov} currentUser={currentUser} tableRef={tableRef}/>
        </div>
    );
}

const CustomFeesTable = (props) => {
    const { data, setData, lov, currentUser,tableRef } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '1%' },
        { title: t('priceAgreement:desc'), width: '6%', excelColumn: "MIM_DESCRIPTION" },
        { title: t('priceAgreement:transaction_type'), width: '4%', excelColumn: "TRANSACTION_TYPE_DESC_LO,TRANSACTION_TYPE_DESC_FO" },
        { title: t('priceAgreement:stage'), width: '4%', excelColumn: "STAGE_DESC_LO,STAGE_DESC_FO" },
        { title: t('priceAgreement:visit_on'), width: '4%', excelColumn: "VISIT_NO_DESC_LO,VISIT_NO_DESC_FO" },
        { title: t('priceAgreement:customer'), width: '4%', excelColumn: "ATR_OFFICIAL_NAME_LO,ATR_OFFICIAL_NAME_FO" },
        { title: t('priceAgreement:property_type'), width: '4%', excelColumn: "MYO_DESC_LO,MYO_DESC_FO" },
        { title: t('priceAgreement:region'), width: '4%', excelColumn: "FRS_NAME_LO,FRS_NAME_FO" },
        { title: t('priceAgreement:city'), width: '4%', excelColumn: "ECD_DESC_LO,ECD_DESC_FO" },
        { title: t('priceAgreement:town'), width: '4%', excelColumn: "CRI_DESC_LO,CRI_DESC_FO " },
        { title: t('priceAgreement:deed_num'), width: '4%', excelColumn: "MIM_DEED_NUM" },
        { title: t('priceAgreement:area_level'), width: '4%', excelColumn: "AREA_LEVEL_DESC_LO,AREA_LEVEL_DESC_FO" },
        { title: t('priceAgreement:area_from'), width: '4%', excelColumn: "MIM_LAND_AREA_FROM" },
        { title: t('priceAgreement:area_to'), width: '4%', excelColumn: "MIM_LAND_AREA_TO" },
        { title: t('priceAgreement:no_of_floors_from'), width: '4%', excelColumn: "MIM_NO_OF_FLOORS_FROM" },
        { title: t('priceAgreement:no_of_floors_to'), width: '4%', excelColumn: "MIM_NO_OF_FLOORS_TO" },
        { title: t('priceAgreement:estimation_value_from'), width: '4%', excelColumn: "MIM_ESTIMATION_PRICE_FROM" },
        { title: t('priceAgreement:estimation_value_to'), width: '4%', excelColumn: "MIM_ESTIMATION_PRICE_TO" },
        { title: t('priceAgreement:variable_1'), width: '4%', excelColumn: "LOV_1_DESC_LO,LOV_1_DESC_FO" },
        { title: t('priceAgreement:variable_2'), width: '4%', excelColumn: "LOV_2_DESC_LO,LOV_2_DESC_FO" },
        { title: t('priceAgreement:variable_3'), width: '4%', excelColumn: "LOV_3_DESC_LO,LOV_3_DESC_FO" },
        { title: <span data-property="priceAfc" className="mandatory">{t('priceAgreement:price_fc')}</span>, width: isLocalCurrency(data?.fcuId) ? "0%" : "4%", excelColumn: "MIM_PRICE_AFC" },
        { title: <span data-property="priceAlc" className="mandatory">{t('priceAgreement:price_lc')}</span>, width: isLocalCurrency(data?.fcuId) ? "8%" : '4%', excelColumn: "MIM_PRICE_ALC" },
        { title: t('priceAgreement:remarks'), width: '6%', excelColumn: "MIM_REM" },
        { title: "", width: '3%' }
    ];

    const _handleDeleteLine = (index) => {
        if (!data.priceListAgreementFieldsCustoms) {
            data.priceListAgreementFieldsCustoms = [];
        }

        deleteItemByIndex(data.priceListAgreementFieldsCustoms, index);
        setData({ ...data });
    }

    const _handleDataChange = (index, name, value) => {
        data.priceListAgreementFieldsCustoms[index][name] = value;

        if (name === "frsId") {
            data.priceListAgreementFieldsCustoms[index]["ecdId"] = null;
            data.priceListAgreementFieldsCustoms[index]["criId"] = null;
        } else if (name === "ecdId") {
            data.priceListAgreementFieldsCustoms[index]["criId"] = null;
        }

        setData({ ...data });
    }

    return (
        <Table
            id={`${COMMON_ID}-custom-fees-table`}
            columns={columns}
            ref={tableRef}
            Row={CustomFeesRow}
            tableStyle={style.customFeesTable}
            list={data?.priceListAgreementFieldsCustoms || []}
            rowActions={{
                handleDataChange: _handleDataChange,
                handleDeleteLine: _handleDeleteLine,
                party: data.party,
                lov: lov,
                masterData: data,
            }}
            formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
            user={currentUser}
            rowHeight={40}
            tableWidth={280}
        />
    );
}

const CustomFeesRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, onRowClick, rowClassName } = props;
    const { handleDataChange, handleDeleteLine, party, lov, masterData } = rowActions;

    useEffect(() => {
        if (party == PARTY_LEVEL_CUSTOMER) {
            handleDataChange(index, "atrId", null)
        }
    }, [party])

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} `} style={virtualizedStyle} onClick={onRowClick}>
            <div><div>{data?.sequence}</div></div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${COMMON_ID}-custom-fees-description-${index}`}
                        value={data?.description}
                        onBlur={value => handleDataChange(index, "description", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-transaction-type-${index}`}
                        value={data?.transactionType}
                        onChange={value => handleDataChange(index, "transactionType", value.value)}
                        options={lov.estimationDomains1048}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-stage-${index}`}
                        value={data?.stage}
                        onChange={value => handleDataChange(index, "stage", value.value)}
                        options={lov.estimationDomains1064}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-visit-number-${index}`}
                        value={data?.visitNo}
                        options={lov.estimationDomains5009}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "visitNo", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-customers-${index}`}
                        value={data?.atrId}
                        onChange={value => handleDataChange(index, "atrId", value.value)}
                        defualtValueId={data?.atrId}
                        defualtValueApi={() => getCommonCustomers(isEmpty(data?.atrId) ? [] : [data?.atrId], STATUS_ACTIVE, null, false, false, false, [], false)}
                        api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, false, false, false, [], false)}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        isAsync={true}
                        isDisabled={party == PARTY_LEVEL_CUSTOMER}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-property-types-${index}`}
                        value={data?.myoId}
                        onChange={value => handleDataChange(index, "myoId", value.value)}
                        api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, [], false, isEmpty(data?.myoId) ? [] : [data?.myoId])}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-region-${index}`}
                        value={data?.frsId}
                        api={() => getRegionsLov([data?.frsId], STATUS_ACTIVE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "frsId", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-city-${index}`}
                        key={`${COMMON_ID}-custom-fees-city-${index}-${data?.frsId}`}
                        value={data?.ecdId}
                        defualtValueId={data?.ecdId}
                        defualtValueApi={() => getCitiesLov(isEmpty(data?.ecdId) ? [] : [data?.ecdId])}
                        api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, data?.frsId, searchValue)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        isAsync={true}
                        isDisabled={isEmpty(data?.frsId)}
                        onChange={value => handleDataChange(index, "ecdId", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-town-${index}`}
                        key={`${COMMON_ID}-custom-fees-town-${index}-${data?.ecdId}`}
                        isAsync
                        value={data?.criId}
                        defualtValueApi={() => getTownsList(null, isEmpty(data.criId) ? [] : [data.criId], data.ecdId, STATUS_ACTIVE, false, null, false)}
                        api={(searchKey) => getTownsList(searchKey, null, data.ecdId, STATUS_ACTIVE, false, null, false)}
                        defualtValueId={data?.criId}
                        descLo={['nameLo']}
                        descFo={['nameFo']}
                        isDisabled={isEmpty(data?.ecdId)}
                        onChange={value => handleDataChange(index, "criId", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${COMMON_ID}-custom-fees-dees-number-${index}`}
                        value={data.deedNum}
                        onBlur={value => handleDataChange(index, "deedNum", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-property-types-${index}`}
                        value={data?.areaLevel}
                        options={lov.areaLevel}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "areaLevel", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-area-from-${index}`}
                        value={data?.landAreaFrom}
                        onBlur={(value) => handleDataChange(index, "landAreaFrom", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-area-to-${index}`}
                        value={data?.landAreaTo}
                        onBlur={(value) => handleDataChange(index, "landAreaTo", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-number-of-floors-from-${index}`}
                        value={data?.noOfFloorsFrom}
                        onBlur={(value) => handleDataChange(index, "noOfFloorsFrom", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-number-of-floors-to-${index}`}
                        value={data?.noOfFloorsTo}
                        onBlur={(value) => handleDataChange(index, "noOfFloorsTo", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-estimation-value-from-${index}`}
                        value={data?.estimationPriceFrom}
                        onBlur={(value) => handleDataChange(index, "estimationPriceFrom", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${COMMON_ID}-custom-fees-estimation-value-to-${index}`}
                        value={data?.estimationPriceTo}
                        onBlur={(value) => handleDataChange(index, "estimationPriceTo", value)}
                        allowDecimals={false}
                        nullable
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-variable-1-${index}`}
                        value={data?.agrLov1}
                        options={lov?.estimationDomains5010}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "agrLov1", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-variable-2-${index}`}
                        value={data?.agrLov2}
                        options={lov?.estimationDomains5011}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "agrLov2", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${COMMON_ID}-custom-fees-variable-3-${index}`}
                        value={data?.agrLov3}
                        options={lov?.estimationDomains5012}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChange(index, "agrLov3", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <ForeignCurrencyInput
                        id={`${COMMON_ID}-custom-fees-price-afc-${index}`}
                        value={data?.priceAfc}
                        onBlur={(value) => handleDataChange(index, "priceAfc", value)}
                        decimalScale={-1}
                        withoutFormat
                        changeAlcAmount={(value) => handleDataChange(index, "priceAlc", value)}
                        currencyId={masterData.fcuId}
                        exchngRate={masterData.exchngRate}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <LocalCurrencyInput
                        id={`${COMMON_ID}-custom-fees-price-alc-${index}`}
                        value={data?.priceAlc}
                        onBlur={(value) => handleDataChange(index, "priceAlc", value)}
                        decimalScale={-1}
                        withoutFormat

                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${COMMON_ID}-custom-fees-rem-${index}`}
                        value={data?.rem}
                        onBlur={(value) => handleDataChange(index, "rem", value)}
                    />
                </div>
            </div>


            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

export default CustomFees;