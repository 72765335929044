import Timer from '@ipgd-gauge/timer';
import { formatDateNTime, handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { getCurrentTime, getTasksMonitoring } from "../../../util/apis";
import { RSM3252_TASKS_LIST_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { currentTimeRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div>
            <div className={`${style.tasksListRow}`}>
                <div title={data.sequence} className="cardRowMode">
                    <label className="nameLblMobile">#</label>
                    <label>{data.sequence}</label>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={data.trnxCode} className={style.excutor}>
                            {data.trnxCode}
                        </label>
                        {(isEnglish() ? data.excutorDescLo : data.excutorDescFo) &&
                            <label title={isEnglish() ? data.excutorDescLo : data.excutorDescFo} className={`overflowTableField`} style={{ color: 'var(--black)' }}>
                                {isEnglish() ? data.excutorDescLo : data.excutorDescFo}
                            </label>
                            ||
                            <label style={{ color: 'var(--black)' }} title={t('not_found')}>{t('not_found')}</label>
                        }
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:transaction')}</label>
                        <div title={data.trnxCode}>
                            {data.trnxCode}
                        </div>
                    </div>


                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:status')}</label>
                        <div title={isEnglish() ? data.excutorDescLo : data.excutorDescFo} className="overflowTableField">
                            {isEnglish() ? data.excutorDescLo : data.excutorDescFo}
                        </div>
                    </div>
                </div>

                <div title={data.text} className={`table_body_label cardRowMode`}>
                    <label className="nameLblMobile">{t('tasksList:task_details')}</label>
                    <label className={`cardRowDesc ${style.textDesc}`}>{data.text}</label>
                </div>

                <div title={isEnglish() ? data.descLo : data.descFo} className="cardRowMode">
                    <label className="nameLblMobile">{t('tasksList:user_list')}</label>
                    <label>{isEnglish() ? data.descLo : data.descFo} </label>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isNotEmpty(data.sendingDateTime) && formatDateNTime(data.sendingDateTime) || '-'}>
                            {isNotEmpty(data.sendingDateTime) && formatDateNTime(data.sendingDateTime) || '-'}
                        </label>
                        <label title={isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"}>
                            <label className={`status-dote-${data?.progressStatus || 192801}`}>{isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"}</label>
                        </label>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:task_date')}</label>
                        <div title={isNotEmpty(data.sendingDateTime) && formatDateNTime(data.sendingDateTime) || '-'}>
                            {isNotEmpty(data.sendingDateTime) && formatDateNTime(data.sendingDateTime) || '-'}
                        </div>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:status')}</label>
                        <div title={isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"}>
                            {isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"}
                        </div>
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isNotEmpty(data.dueDateTime) && (formatDateNTime(data.dueDateTime) || '-')}>
                            {isNotEmpty(data.dueDateTime) && (formatDateNTime(data.dueDateTime) || '-')}
                        </label>
                        <label title={data.expectedTime} className={style.time}>
                            {data.expectedTime}
                        </label>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:expected_date')}</label>
                        <div title={isNotEmpty(data.dueDateTime) && (formatDateNTime(data.dueDateTime) || '-')}>
                            {isNotEmpty(data.dueDateTime) && (formatDateNTime(data.dueDateTime) || '-')}
                        </div>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:expected_hrs')}</label>
                        <div title={data.expectedTime}>
                            {data.expectedTime}
                        </div>
                    </div>
                </div>

                <div className="columnSm">
                    <div className="columnRowNew">
                        <label title={isNotEmpty(data.executionDateTime) && (formatDateNTime(data.executionDateTime) || '-')}>
                            {isNotEmpty(data.executionDateTime) && (formatDateNTime(data.executionDateTime) || '-')}
                        </label>
                        <label title={data.takenTime} className={style.time}>
                            {data.takenTime}
                        </label>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:execution_date')}</label>
                        <div title={isNotEmpty(data.executionDateTime) && (formatDateNTime(data.executionDateTime) || '-')}>
                            {isNotEmpty(data.executionDateTime) && (formatDateNTime(data.executionDateTime) || '-')}
                        </div>
                    </div>

                    <div className="cardRowTrxMode">
                        <label className="nameLblMobile">{t('tasksList:elapsed_hrs')}</label>
                        <div title={data.takenTime}>
                            {data.takenTime}
                        </div>
                    </div>
                </div>
                <div>
                    {data?.progressStatus != 192803 ?
                        <Timer
                            fromDate={currentTimeRef.current}
                            toDate={data.dueDateTime}
                            textColor={currentTimeRef.current < data.dueDateTime ? '#09a4f2' : '#ff0000'}
                            className={style.timerContainer}
                            showDays
                            showSeconds
                            showIcon
                            withMilliseconds
                            isCountdown={currentTimeRef.current < data.dueDateTime}
                        />
                        :
                        <>-</>
                    }
                </div>
            </div>
        </div>
    );
};

const TasksListTable = (props) => {
    const { user, tasksListTableRef, tasksListFilterRef, searchTextRef } = props;

    const { t } = useTranslation("tasksList");
    const [tasks, setTasks] = useState({});
    const currentTimeRef = useRef();

    const columns = [
        { title: '#', width: "3%" },
        { title: `${t('tasksList:transaction')} | ${t('tasksList:executor')}`, width: "14%" , excelColumn: "TRNX_CODE,EXCUTOR_DESC_LO,EXCUTOR_DESC_FO" },
        { title: t('tasksList:task_details'), width: "20%" , excelColumn: "SSE_TEXT" },
        { title: t('tasksList:user_list'), width: "20%" , excelColumn: "SSU_DESC_LO,SSU_DESC_FO" },
        { title: `${t('tasksList:task_date')} | ${t('tasksList:status')}`, width: "12%" , excelColumn: "SSE_SENDING_DATE_TIME,PROG_STATUS_LO,PROG_STATUS_FO" },
        { title: t('tasksList:expected_date'), width: "11%", excelColumn: "SSE_DUE_DATE_TIME"  },
        { title: `${t('tasksList:execution_date')} | ${t('tasksList:elapsed_hrs')}`, width: "10%" , excelColumn: "EXECUTION_DATE_TIME,takenTime" },
        { title: ``, width: "10%" },
    ];

    const _fetchData = (page, size) => {
        let filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...tasksListFilterRef?.current?.getFilterData(),
        }

        getTasksMonitoring(page, size, filterData)
            .then((response) => setTasks(response.data))
            .catch((error) => handleError(error, null, t));

        getCurrentTime()
            .then((response) => currentTimeRef.current = response.data)
            .catch((error) => currentTimeRef.current = new Date().getTime());
    }

    return (
        <Table
            id="tasks-list-table-ref-table"
            ref={tasksListTableRef}
            columns={columns}
            formId={RSM3252_TASKS_LIST_FORM_ID}
            Row={Row}
            tableStyle={style.tasksListTable}
            pagingData={tasks}
            withFilter
            getData={_fetchData}
            rowActions={{ currentTimeRef }}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            user={user}
            cardInMobile
            labelCardWidth="100px"
        />
    );
};

export default TasksListTable;