import { DeleteBtn } from "@ipgd-gauge/table";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../../components/multi-insert-dialog";
import Table from "../../../../components/table";
import AddHeaderBtnIcon from "../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../icons/Delete2";
import { getExpenseAccountList, getFinancialIntegration } from "../../../../util/apis";
import { getUser } from "../../../../util/cookies";
import style from './style.module.scss';
import AccountSelectMenu from '../../../../components/account-select-menu'
import { confirmationDialog, isEnglish } from "@ipgd-gauge/utils";
const FinancialIntegration = (props) => {
    const { data, setData, type, formId } = props;

    const { t } = useTranslation()

    const financialIntegrationDialogRef = useRef();

    const _handleDeleteLine = (index) => {
        if (!data.financialIntegration) {
            data.financialIntegration = [];
        }

        data.financialIntegration.splice(index, 1);
        setData({ ...data });
    }

    const _handleLineDataChange = (index, value) => {
        data.financialIntegration[index].gstId = value;
        setData({ ...data });
    }

    const _handleDeleteAllLines = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.financialIntegration = [];
            setData({ ...data });
        });
    }

    const _insertFinancialIntegration = (selectedData) => {
        if (!data.financialIntegration) {
            data.financialIntegration = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                id: element.id,
                fogId: element.fogId,
                ddvId: element.ddvId,
                organizationNameLo: element.organizationNameLo,
                organizationNameFo: element.organizationNameFo,
                typeDescLo: element.typeDescLo,
                typeDescFo: element.typeDescFo,
                organizationCode: element.organizationCode
            });
        }

        data.financialIntegration = [...data.financialIntegration, ...newLines];
        setData({ ...data });

        financialIntegrationDialogRef?.current?.close();
    }

    return (
        <>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('insert')}
                            onClick={() => financialIntegrationDialogRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={() => _handleDeleteAllLines('financialIntegration')}
                            disabled={(data?.financialIntegration || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

            <FinancialIntegrationTable
                data={data}
                handleDeleteLine={_handleDeleteLine}
                handleLineDataChange={_handleLineDataChange}
                formId={formId}
            />

            <MultiInsertDialog
                id={`financial-integration-dialog`}
                tableStyle={style.financialIntegrationMultiTable}
                ref={financialIntegrationDialogRef}
                title={t('locationsGroups:insert_financial_integration')}
                okBtnAction={_insertFinancialIntegration}
                api={(searchValue) => getFinancialIntegration({ type: type, isAllOrganization: data?.general, organizationIds: (data?.organizations ? (data?.organizations || []).map(organization => organization.fogId) : [data?.fogId]), searchValue: searchValue })}
                idSelectors={["fogId", "ddvId"]}
                selectedIds={(data.financialIntegration || []).map((line) => { return { fogId: line.fogId, ddvId: line.ddvId } })}
                searchPlaceholder={t('locationsGroups:organization') + " , " + t('locationsGroups:type')}
                formId={formId}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '10%' },
                    { label: t('locationsGroups:organization'), valueLo: 'organizationNameLo', valueFo: 'organizationNameFo', width: '28%' },
                    { label: t('locationsGroups:type_code'), valueLo: 'ddvId', valueFo: 'ddvId', width: '28%' },
                    { label: t('locationsGroups:type'), valueLo: 'typeDescLo', valueFo: 'typeDescFo', width: '29%' },
                ]}
            />
        </>
    )
}

const FinancialIntegrationTable = (props) => {
    const { data, handleDeleteLine, handleLineDataChange, formId } = props;

    const { t } = useTranslation("locationsGroups");

    const columns = [
        { title: '#', width: "5%" },
        { title: t('organization'), width: "22%" },
        { title: t('type_code'), width: "21%" },
        { title: t('type'), width: "21%" },
        { title: <span data-property="gstId" className="mandatory" >{t('account')}</span>, width: "21%" },
        { title: "", width: "10%" },
    ];

    return (
        <div className={style.financialIntegrationTableContainer}>
            <Table
                id="financial-integration-table"
                key={data.forceUpdate}
                columns={columns}
                Row={FinancialIntegrationRow}
                tableStyle={style.financialIntegrationTable}
                rowHeight={40}
                list={data.financialIntegration || []}
                rowActions={{
                    handleDeleteLine: handleDeleteLine,
                    handleLineDataChange: handleLineDataChange
                }}
                user={getUser()}
                formId={formId}
            />
        </div>
    );
}

const FinancialIntegrationRow = (props) => {
    const { data, index, rowActions, virtualizedStyle ,onRowClick, rowClassName  } = props;
    const { handleDeleteLine, handleLineDataChange } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.financialIntegrationRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div title={data.sequence}> <div>{data.sequence}</div> </div>
            <div title={isEnglish() ? data.organizationNameLo : data.organizationNameFo}><div>{isEnglish() ? data.organizationNameLo : data.organizationNameFo}</div></div>
            <div title={` ${data?.ddvId} `}><div>{`${data?.ddvId}`}</div></div>
            <div title={`${isEnglish() ? data?.typeDescLo : data?.typeDescFo}`}><div>{`${isEnglish() ? data?.typeDescLo : data?.typeDescFo}`}</div></div>
            <div>
                <div className="inputRow">
                    <AccountSelectMenu
                        id={`locations-groups-dialog-table-account-${index}`}
                        key={data.fogId}
                        value={data.gstId}
                        onChange={value => handleLineDataChange(index, value.value)}
                        api={() => getExpenseAccountList(data.fogId, data.gstId)}
                        descFo={['code', 'descFo']}
                        descLo={['code', 'descLo']}
                        menuWidth="250px"
                    />
                </div>
            </div>

            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index, "financialIntegration")} />
                </div>
            </div>
        </div>
    );
};

export default FinancialIntegration;