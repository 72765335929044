import { createUUID } from '@ipgd-gauge/utils';
import nookies, { destroyCookie, setCookie } from 'nookies';

const domain = new URL(window.location.href).hostname;
const path = "/";

let savedUUID = sessionStorage.getItem("CUUID");
if (!savedUUID) {
    savedUUID = createUUID();
    sessionStorage.setItem("CUUID", savedUUID)
}

const _setCookies = (name, value, expiryDate) => {
    setCookie(null, name, value, {
        expires: new Date(expiryDate),
        path: path,
        domain: domain,
    });
}

const _getCookies = (name) => {
    switch (name) {
        case "user":
            return nookies.get()[`gauge_rs_est_user_${savedUUID}`];
        case "token":
            return nookies.get()[`gauge_rs_est_token_${savedUUID}`];
        case "expiry":
            return nookies.get()[`gauge_rs_est_ex_${savedUUID}`];
        default:
            return null;
    }
}

const _destroyCookie = (name) => {
    destroyCookie(null, name, {
        domain: domain,
        path: path,
    });
}

export const getUser = () => {
    try {
        return JSON.parse(JSON.parse(sessionStorage.getItem("persist:rs_est_root")).userReducer)?.user;
    } catch (error) {
        return null
    }
}

export const setToken = (token, tempExpiryDate, currentDate) => {
    let expiryDate = tempExpiryDate ? new Date((parseInt(tempExpiryDate)) + currentDate) : _getCookies("expiry");
    _setCookies(`gauge_rs_est_token_${savedUUID}`, token, expiryDate);
}

export const getToken = () => {
    return _getCookies("token");
}

export const setExpiryDate = (date, currentDate) => {
    let expiryDate = new Date((parseInt(date)) + currentDate);
    _setCookies(`gauge_rs_est_ex_${savedUUID}`, expiryDate, expiryDate);
}

export const destroyAllCookie = () => {
    _destroyCookie(`gauge_rs_est_token_${savedUUID}`);
    _destroyCookie(`gauge_rs_est_ex_${savedUUID}`);
}

export const destroyAllCookieByKey = (key) => {
    _destroyCookie(`gauge_rs_est_token_${key}`);
    _destroyCookie(`gauge_rs_est_ex_${key}`);
}