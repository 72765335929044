import { UtilContainer, fixImagePath, isAllowedForm, showNotificationToast } from '@ipgd-gauge/utils';
import { useEffect, useRef } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import notificationAlarm from './assets/media/notificationAlarm.mp3';
import SessionTimeOut from './components/count-down';
import Header from './components/header';
import Image from './components/image';
import NavMenu from './components/menu';
import NextPrevBtns from './components/next-prev-records-panel';
import DeniedServiceError from './components/notification-dialogs/denied-service-error';
import DeniedServiceWarning from './components/notification-dialogs/denied-service-worning';
import "./i18n";
import Router from './router';
import { persistor, store } from './store';
import { saveFormsFilter } from './store/formsFilter/actions';
import { changeUnreadedMsgsCount } from './store/user/actions';
import style from './style.module.scss';
import { getUnreadedMessagesCount, getUserMessage, logoutUser, setAuthorizationHeader } from './util/apis';
import { RSM3246_INBOX_FORM_ID } from './util/constants/forms';
import { destroyAllCookie, destroyAllCookieByKey, getToken, getUser } from './util/cookies';
import { getFormUrl } from './util/formUtil';
import { deleteData, getAllRecords, updateData } from './util/gaueIndexedDB';
import { confirmationDialog, getFormAttributeByPath, getFormId, isEmpty, isEnglish, isNotEmpty, purgeSession } from './util/util';
import Login from './views/login';

const App = () => {
  const token = getToken();
  const path = useLocation();
  const user = getUser();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const serviceExpireWarning = useRef();
  const serviceExpireError = useRef();
  const activeTabIntervalRef = useRef();
  const removeInactiveSessionsIntervalRef = useRef();

  useEffect(() => {
    document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`)
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`)
    })
    window.addEventListener("orientationchange", () => {
      document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`)
    });

    removeInactiveSessionsIntervalRef.current = setInterval(() => _removeInactiveSessions(), 2000);

    return () => {
      if (removeInactiveSessionsIntervalRef.current) {
        clearInterval(removeInactiveSessionsIntervalRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (!user && path.pathname != '/') {
      navigate('/');
    }

    if (user) {
      activeTabIntervalRef.current = setInterval(() => {
        const sessionKey = sessionStorage.getItem("CUUID");
        if (isNotEmpty(sessionKey)) {
          updateData(sessionKey);
        }
      }, 1000);
    }

    return () => {
      if (activeTabIntervalRef.current) {
        clearInterval(activeTabIntervalRef.current);
      }
    }
  }, [user]);

  const _removeInactiveSessions = async () => {
    let lines = await getAllRecords();
    const currentDate = new Date().getTime();
    (lines || []).forEach(line => {
      if (currentDate - Number(line.time) > 1830000) {
        destroyAllCookieByKey(line.id);
        deleteData(line.id);
      }
    });
  }

  const logoutAction = (withConfirmationDialog, withoutReload) => {
    if (withConfirmationDialog) {
      confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () =>
        persistor.purge()
          .then(() => {
            destroyAllCookie();
            logoutUser();
            localStorage.clear();
            sessionStorage.clear();
            if (!withoutReload) {
              window.location.reload(true);
            }
          })
      );
    } else {
      persistor.purge()
        .then(() => {
          destroyAllCookie();
          logoutUser();
          localStorage.clear();
          sessionStorage.clear();
          if (!withoutReload) {
            window.location.reload(true);
          }
        })
    }
    if (activeTabIntervalRef.current) {
      clearInterval(activeTabIntervalRef.current);
    }
  }

  const isTrnxForm = () => {
    return path.pathname === "/estimation-trx"
      || path.pathname === "/customers-estimation-invoice"
      || path.pathname === "/estimators-inspectors-invoice"
      || path.pathname === "/estimation-fees-commissions-calculation"
      || path.pathname === "/trx-summary"
      || path.pathname === "/profile"
      || path.pathname === "/estimation-quotation"
      || path.pathname === "/valuation-contract"
      || path.pathname === "/evaluation-policie"
      || path.pathname === "/price-agreement"
      || path.pathname === "/evaluation-policy"
      || path.pathname === "/credit-notes-for-customer-estimation"
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Container logoutAction={logoutAction} >
          <Translation>
            {() => {
              return (
                <>
                  <div id='lineProgressLoad' className='lineProgress'></div>
                  {/* <ToastContainer enableMultiContainer rtl={isEnglish() ? false : true} /> */}
                  <UtilContainer />
                  <DeniedServiceError ref={serviceExpireError} logoutAction={logoutAction} />
                  <DeniedServiceWarning ref={serviceExpireWarning} />
                  {!token ?
                    <><Login
                      serviceExpireErrorRef={serviceExpireError}
                      serviceExpireWarningRef={serviceExpireWarning}
                    />
                    </>
                    :
                    <div
                      className={style.masterPanel}
                      dir={isEnglish() ? "ltr" : "rtl"}>
                      <NavMenu logoutAction={logoutAction} />
                      <div id='mainPanel' className={style.mainPanel}>
                        <Header
                          formAttr={{ ...getFormAttributeByPath(path.pathname), path: getFormUrl(getFormId(), true) }}
                          logoutAction={logoutAction}
                        />
                        <div className={`${style.content} ${isTrnxForm() ? style.trnxContent : ''}`}>
                          <Router />
                        </div>
                        {isTrnxForm() &&
                          <NextPrevBtns
                            customStyle={style.nextPrevBtns}
                          />
                        }
                      </div>
                    </div>
                  }
                </>
              )
            }}
          </Translation>
          <div className="disabledLayer" id="disabledLayer"></div>

        </Container>
      </PersistGate>
    </Provider>
  );
}

const Container = ({ children, logoutAction }) => {
  const { t } = useTranslation();
  const sessionTimeOutRef = useRef()
  const dispatch = useDispatch()
  const token = getToken();
  const user = useSelector(state => state.persistedReducer.userReducer.user);
  const intervalRef = useRef(null)
  const unreadedMessagesCount = useSelector(state => state?.persistedReducer?.userReducer?.user?.unreadedMsgsCount || 0);
  const navigate = useNavigate();

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    message,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onIdle: () => {
      if (!isEmpty(token)) {
        // destroyAllCookie();
        // dispatch(userLogout())
        // dispatch(clearFormsFilter())
        // localStorage.clear();
        logoutAction(false, true);
        if (sessionTimeOutRef.current) {
          sessionTimeOutRef.current.open()
          sessionTimeOutRef.current.setCountDwon(true)
        }
      }
    },
    syncTimers: 1,
    timeout: 30 * 60 * 1000,
    crossTab: true,
    element: document,
    onPrompt: () => {
      if (token && token.length > 0 && sessionTimeOutRef.current) {
        sessionTimeOutRef.current.open()
      }
    },
    promptTimeout: 30 * 1000,
  })

  if (!token) {
    purgeSession();
  } else {
    setAuthorizationHeader(token);
  }

  const _isUserExists = () => {
    return isNotEmpty(user?.id);
  }

  const _onMessageClick = (messageCode) => {
    dispatch(saveFormsFilter(`${RSM3246_INBOX_FORM_ID}-inbox-searchText`, messageCode));
    navigate("/inbox")
  }

  useEffect(() => {
    if (_isUserExists() && unreadedMessagesCount > 0) {
      getUserMessage()
        .then(response => {
          const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;
          const data = response.data;
          if (data) {
            new Audio(notificationAlarm).play()
            showNotificationToast(
              <div className={isEnglish() ? `toast-en-type-${data.type}` : `toast-ar-type-${data.type}`} onClick={() => _onMessageClick(data.code)}>
                <div className='toast-numbers'>
                  <div>
                    <label>{t('num_application')} :</label>
                    <div>{data.transactionCode}</div>
                  </div>
                  <div className='msg-number'>{data.code}</div>
                </div>
                <div className='toast-container-info'>
                  <div className='toast-line'></div>
                  <div className='toast-icon'>
                    <Image src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.image)}`} isCustomer />
                  </div>
                  <div className='toast-desc'>
                    <label>{data.text}</label>
                  </div>
                </div>
              </div>,
              { position: "bottom-right", hideProgressBar: true, icon: false }
            );
          }
        })
    }
  }, [unreadedMessagesCount])

  useEffect(() => {
    if (isAllowedForm(user, RSM3246_INBOX_FORM_ID)) {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current)
      }
      intervalRef.current = setInterval(() => {
        if (isNotEmpty(user?.id)) {
          getUnreadedMessagesCount().then(response => {
            dispatch(changeUnreadedMsgsCount(response.data))
          });
        }
      }, 30 * 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [user?.id])

  return (
    <>
      {children}
      {
        !isEmpty(token) &&
        <SessionTimeOut
          onContinue={() => activate()}
          ref={sessionTimeOutRef} time={30} />
      }
    </>
  )
}

export default App;