import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, formatDateNTime, formatMoney, handleError, hideWaiting, isEmpty, isEnglish, isNotEmpty, parseNumber, showWaiting } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExportIcon from "../../../icons/Export";
import RecalculateIcon from "../../../icons/Recalculate";
import { exportCustomerEstimationInvoiceDetails, getAdditionalItemsLov, getCommonPartiesLov, getCommonPropertyTypes, getEstimationDomains, getTranactionsList, recalculateInvoice } from "../../../util/apis";
import { DATE_LEVEL_DOMAIN_ALL, DATE_LEVEL_DOMAIN_APPROVAL_DATE, DATE_LEVEL_DOMAIN_TRANSACTION_DATE, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES, ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, ESTIMATOR_PARTY, INSPECTOR_PARTY, STATUS_ACTIVE, STATUS_ESTIMATOR_FEES, STATUS_INSPECTOR_FEES } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import MultiInsertDialog from "../../common/dialogs/multi-insert-dialog";
import { ACTION_ADD_LINES, ACTION_DELETE_ALL_LINES, ACTION_DELETE_LINE, ACTION_RECALCULATE, ACTION_SET_FILTERED_LINES, ACTION_SET_LINE_VALUE } from "./reducer";
import style from './style.module.scss';

export const ESTIMATION_FEES = 128601;
export const ITEM = 128602;

const AdvanceFiltersPanel = forwardRef((props, ref) => {
    const { billingDateMode, billingPeriodFromDgr, billingPeriodToDgr } = props;

    const { t } = useTranslation("customersEstimationInvoices");
    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        if (name != 'billingInfo') {
            filterData[name] = value;
        } else {
            if (billingDateMode == DATE_LEVEL_DOMAIN_APPROVAL_DATE) {
                filterData.approvalFromDate = billingPeriodFromDgr;
                filterData.approvalToDate = billingPeriodToDgr;
                filterData.transactionFromDate = null;
                filterData.transactionToDate = null;
            } else if (billingDateMode == DATE_LEVEL_DOMAIN_TRANSACTION_DATE) {
                filterData.transactionFromDate = billingPeriodFromDgr;
                filterData.transactionToDate = billingPeriodToDgr;
                filterData.approvalFromDate = null;
                filterData.approvalToDate = null;
            } else if (billingDateMode == DATE_LEVEL_DOMAIN_ALL) {
                filterData.approvalFromDate = null;
                filterData.approvalToDate = null;
                filterData.transactionFromDate = null;
                filterData.transactionToDate = null;
            }
        }
        setFilterData({ ...filterData });
    }

    useEffect(() => {
        _handleDataChanged('billingInfo');
    }, [billingDateMode, billingPeriodFromDgr, billingPeriodToDgr])

    const getFilterData = () => {
        return filterData
    }

    const clearFilterData = () => {
        const tempFilter = {};
        if (billingDateMode == DATE_LEVEL_DOMAIN_APPROVAL_DATE) {
            tempFilter.approvalFromDate = billingPeriodFromDgr;
            tempFilter.approvalToDate = billingPeriodToDgr;
            tempFilter.transactionFromDate = null;
            tempFilter.transactionToDate = null;
        } else if (billingDateMode == DATE_LEVEL_DOMAIN_TRANSACTION_DATE) {
            tempFilter.transactionFromDate = billingPeriodFromDgr;
            tempFilter.transactionToDate = billingPeriodToDgr;
            tempFilter.approvalFromDate = null;
            tempFilter.approvalToDate = null;
        } else if (billingDateMode == DATE_LEVEL_DOMAIN_ALL) {
            tempFilter.approvalFromDate = null;
            tempFilter.approvalToDate = null;
            tempFilter.transactionFromDate = null;
            tempFilter.transactionToDate = null;
        }
        setFilterData({ ...tempFilter })
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        clearFilterData
    }))

    return (
        <div className={`container ${style.advanceFiltersPanelContainer}`}>
            <div className="row">

                <div className="w50">
                    <label className='form-label'>{t('approval_date_from_to')}</label>
                    <DateFromToInput
                        id="approval-date-from-to"
                        fromValue={filterData.approvalFromDate}
                        toValue={filterData.approvalToDate}
                        fromOnChange={value => _handleDataChanged("approvalFromDate", value)}
                        toOnChange={value => _handleDataChanged("approvalToDate", value)}
                        enableTime
                    />
                </div>

                <div className="w50">
                    <label className='form-label'>{t('order_date_from_to')}</label>
                    <DateFromToInput
                        id="order-date-from-to"
                        fromValue={filterData.orderFromDate}
                        toValue={filterData.orderToDate}
                        fromOnChange={value => _handleDataChanged("orderFromDate", value)}
                        toOnChange={value => _handleDataChanged("orderToDate", value)}
                        enableTime
                    />
                </div>

            </div>
            <div className="row">
                <div className="w50">
                    <label className='form-label'>{t('transaction_date_from_to')}</label>
                    <DateFromToInput
                        id="transaction-date-from-to"
                        fromValue={filterData.transactionFromDate}
                        toValue={filterData.transactionToDate}
                        fromOnChange={value => _handleDataChanged("transactionFromDate", value)}
                        toOnChange={value => _handleDataChanged("transactionToDate", value)}
                        enableTime
                    />
                </div>

                <div className="w25">
                    <label className='form-label'>{t('city')}</label>
                    <FrmInput
                        id="city"
                        value={filterData.city}
                        onBlur={(value) => _handleDataChanged("city", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('town')}</label>
                    <FrmInput
                        id="towns"
                        value={filterData.town}
                        onBlur={(value) => _handleDataChanged("town", value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('transaction_type')}</label>
                    <FrmSelectMenu
                        id="Transaction-Type"
                        isMulti={true}
                        value={filterData.trxTypes}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("trxTypes", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('property_type')}</label>
                    <FrmSelectMenu
                        id="property_type"
                        isMulti={true}
                        value={filterData.propertyTypes}
                        api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyTypes)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("propertyTypes", value)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('stage')}</label>
                    <FrmSelectMenu
                        id="Stage"
                        isMulti={true}
                        value={filterData.stages}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_STAGES)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("stages", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('inspector')}</label>
                    <FrmSelectMenu
                        id="Inspector"
                        key="Inspector"
                        isMulti={true}
                        value={filterData.inspectorIds}
                        api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectorIds, null, null, 1)}
                        descLo={['nameLo']}
                        descFo={['nameFo']}
                        onChange={e => _handleDataChanged("inspectorIds", e)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    )
})

const HeaderLines = (props) => {
    const { invoice, dispatchInvoice, invoiveTableRef } = props;

    const { t } = useTranslation("customersEstimationInvoices");
    const insertItemDialog = useRef(null);
    const insertTransactionDialog = useRef(null);

    const _insertTransactions = (selectedData) => {
        let rows = [];
        selectedData.map(data => {
            let row = {};
            row.source = ESTIMATION_FEES;
            row.triId = data.id
            row.atrId = data.atrId
            row.cdaId = data.cdaId;
            row.descLo = data.contractCode + ', ' + data.contractDescLo
            row.descFo = data.contractCode + ', ' + data.contractDescFo
            row.vatPerc = data.vatPerc
            row.applicationNum = data.code
            row.dueAmountAlc = data.orgFeesAlc
            row.dueAmountAfc = data.orgFeesAfc
            row.invoiceAmountAlc = data.orgFeesAlc
            row.invoiceAmountAfc = data.orgFeesAfc || 0;
            row.discountAfc = 0;
            row.discountAlc = 0;
            row.approvalDateDgr = data.approvalDateTime
            row.transaction = {}
            row.transaction.code = data.code
            row.transaction.contractCode = data.contractCode
            row.transaction.contractDescLo = data.contractDescLo
            row.transaction.contractDescFo = data.contractDescFo
            row.transaction.transactionType = data.transactionType
            row.transaction.typeDescLo = data.typeDescLo
            row.transaction.typeDescFo = data.typeDescFo
            row.transaction.refCode = data.refCode
            row.transaction.orderNo = data.orderNo
            row.transaction.orderDateTime = data.orderDateTime
            row.transaction.dateTime = data.dateTime
            row.transaction.cityDescLo = data.cityDescLo
            row.transaction.cityDescFo = data.cityDescFo
            row.transaction.districtDescLo = data.districtDescLo
            row.transaction.districtDescFo = data.districtDescFo
            row.transaction.ownerName = data.ownerName
            row.transaction.officialNameLo = data.officialNameLo
            row.transaction.officialNameFo = data.officialNameFo
            row.transaction.clientName = data.clientName
            row.transaction.ownerCardNum = data.ownerCardNum
            row.transaction.propertyTypeDescLo = data.propertyTypeDescLo
            row.transaction.propertyTypeDescFo = data.propertyTypeDescFo
            row.transaction.propertyCode = data.propertyCode
            row.transaction.titleDeedNo = data.titleDeedNo
            row.transaction.parcelArea = data.parcelArea
            row.transaction.totalBuildArea = data.totalBuildArea
            row.transaction.noOfFloors = data.noOfFloors
            row.transaction.finalPrice = data.finalPrice
            row.transaction.stageDescLo = data.stageDescLo
            row.transaction.stageDescFo = data.stageDescFo
            row.transaction.trnxStatusLo = data.trnxStatusLo
            row.transaction.trnxStatusFo = data.trnxStatusFo
            row.transaction.inspectorNameLo = data.inspectorNameLo
            row.transaction.inspectorNameFo = data.inspectorNameFo
            row.transaction.mmaId = data.mmaId
            rows.push(row);
        })
        dispatchInvoice({ value: rows, type: ACTION_ADD_LINES })
        insertTransactionDialog.current.close();
    }

    const _insertItem = (selectedData) => {
        let rows = [];
        selectedData.map(data => {
            let row = {};
            row.source = ITEM;
            row.cdaId = data.id
            row.descLo = data.code + ', ' + data.descLo
            row.descFo = data.code + ', ' + data.descFo
            row.vatPerc = data.vatPerc
            row.item = { ...data };
            rows.push(row);
        })
        dispatchInvoice({ value: rows, type: ACTION_ADD_LINES })
        insertItemDialog.current.close();
    }

    const _deleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            dispatchInvoice({ type: ACTION_DELETE_ALL_LINES })
        })
    }

    const _recalculateAction = () => {
        const data = {}
        for (let index = 0; index < invoice.lines.length; index++) {
            const element = invoice.lines[index];
            if (isNotEmpty(element.triId) && isEmpty(element?.transaction?.mmaId)) {
                data[index] = element.triId;
            }
        }

        showWaiting("recalculate-button-spinner-id", true)
        recalculateInvoice(invoice?.header?.atrId, data)
            .then(response => dispatchInvoice({ type: ACTION_RECALCULATE, value: response.data }))
            .catch(error => handleError(error, null, t))
            .finally(() => hideWaiting("recalculate-button-spinner-id"))
    }

    return (
        <>
            <div className={style.headerLines}>
                <div className={style.headerBtn}>
                    <button
                        title={t('insert_transactions')}
                        className="tableHeaderActionsBtn"
                        onClick={() => insertTransactionDialog.current.open()}
                        disabled={!invoice?.header?.dateDgr || !invoice?.header?.dsvId || !invoice?.header?.atrId || !invoice?.header?.accountType}
                    >
                        <span>
                            <AddHeaderBtnIcon />
                        </span>
                        <label>
                            {t('insert_transactions')}
                        </label>
                    </button>

                    <button
                        title={t('insert_item')}
                        className={`tableHeaderActionsBtn ${style.marBtn}`}
                        onClick={() => insertItemDialog.current.open()}
                        disabled={!invoice?.header?.dateDgr || !invoice?.header?.dsvId || !invoice?.header?.atrId || !invoice?.header?.accountType}
                    >
                        <span>
                            <AddHeaderBtnIcon />
                        </span>
                        <label>
                            {t('insert_item')}
                        </label>
                    </button>

                    <button
                        className="tableHeaderActionsBtn"
                        title={t('recalculate')}
                        onClick={_recalculateAction}
                        disabled={(invoice.lines || []).length === 0}>
                        <Spinner id="recalculate-button-spinner-id" className={style.spinner} />
                        <span>
                            <RecalculateIcon />
                        </span>
                        <label>
                            {t('recalculate')}
                        </label>
                    </button>

                    <button
                        title={t('export')}
                        className={`tableHeaderActionsBtn ${style.marLeft}`}
                        onClick={() => exportCustomerEstimationInvoiceDetails({ lines: invoice.lines, columnSetting: invoiveTableRef.current.getSelectedSettingId() }, 'customers-estimation-invoice', "export-customers-estimation-invoice-spinner")}
                        disabled={(invoice.lines || []).length === 0}>
                        <Spinner id="export-customers-estimation-invoice-spinner" className={style.spinner} />
                        <span>
                            <ExportIcon />
                        </span>
                        <label>
                            {t('export')}
                        </label>
                    </button>

                    <button
                        className="tableHeaderDeleBtn"
                        title={t('delete_all')}
                        onClick={_deleteAll}
                        disabled={invoice?.lines?.length == 0}
                    >
                        <span>
                            <DeleteTowIcon />
                        </span>
                        <label>
                            {t('delete_all')}
                        </label>
                    </button>
                </div>
            </div>
            <MultiInsertDialog
                id='insert_item'
                ref={insertItemDialog}
                title={t('insert_item')}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                rowHeight={40}
                okBtnAction={_insertItem}
                api={(searchValue) => getAdditionalItemsLov(searchValue, null, STATUS_ACTIVE, [STATUS_INSPECTOR_FEES, STATUS_ESTIMATOR_FEES, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES])}
                searchPlaceholder={t('code') + " , " + t('name')}
                columns={[
                    { label: '', width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('code'), value: 'code', width: '10%' },
                    { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: '80%' }
                ]}
                isSmallSize
            />
            <MultiInsertDialog
                id='insert_transactions'
                ref={insertTransactionDialog}
                title={t('insert_transactions')}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                okBtnAction={_insertTransactions}
                tableStyle={style.insertTransactionsTable}
                selectedIds={invoice?.lines.map((line) => ({ id: line.triId }))}
                api={getTranactionsList}
                apiParams={{
                    customerId: invoice.header.atrId,
                    branchId: invoice.header.mbnId,
                    divisionId: invoice.header.dsvId,
                    policyId: invoice.header.lsoId
                }}
                rowHeight={45}
                tableWidth={200}
                searchPlaceholder={t('num_application') + " , " + t('order_num') + " , " + t('ref_code') + " , " + t('owner') + " , " + t('client_name') + " , " + t('owner_card') + " , " + t('title_deed_no')}
                columns={[
                    { label: '', width: '2%' },
                    { label: '#', width: '3%' },
                    { label: t('num_application'), value: 'code', width: '4%' },
                    { label: t('approval_date'), value: 'approvalDateTime', isDateNTime: true, width: '4%' },
                    { label: t('due_amount'), value: 'orgFeesAlc'/* , isMoney: true */, width: '4%' },
                    { label: t('transaction_type'), valueLo: 'transactionTypeLo', valueFo: 'transactionTypeFo', width: '5%' },
                    { label: t('ref_code'), value: 'refCode', width: '4%' },
                    { label: t('order_num'), value: 'orderNo', width: '4%' },
                    { label: t('order_date'), value: 'orderDateTime', isDateNTime: true, width: '4%' },
                    { label: t('application_date'), value: 'dateTime', isDateNTime: true, width: '4%' },
                    { label: t('city'), valueLo: 'cityDescLo', valueFo: 'cityDescFo', width: '5%' },
                    { label: t('town'), valueLo: 'districtDescLo', valueFo: 'districtDescFo', width: '5%' },
                    { label: t('owner'), value: 'ownerName', width: '5%' },
                    { label: t('client_name'), value: 'clientName', width: '5%' },
                    { label: t('owner_card'), value: 'ownerCardNum', width: '5%' },
                    { label: t('property_type'), valueLo: 'propertyTypeDescLo', valueFo: 'propertyTypeDescFo', width: '4%' },
                    { label: t('property_code'), value: 'propertyCode', width: '4%' },
                    { label: t('title_deed_no'), value: 'titleDeedNo', width: '5%' },
                    { label: t('land_area'), value: 'parcelArea', width: '4%' },
                    { label: t('buildding_area'), value: 'totalBuildArea', width: '4%' },
                    { label: t('number_of_floors'), value: 'NoOfFloors', width: '4%' },
                    { label: t('estimation_price'), value: 'finalPrice', isMoney: true, width: '4%' },
                    { label: t('stage'), valueLo: 'stageDescLo', valueFo: 'stageDescFo', width: '4%' },
                    { label: t('status'), valueLo: 'trnxStatusLo', valueFo: 'trnxStatusFo', width: '4%' },
                ]}
                AdvanceFilters={AdvanceFiltersPanel}
                AdvanceFiltersProps={{
                    billingDateMode: invoice.header.billingDateMode,
                    billingPeriodFromDgr: invoice.header.billingPeriodFromDgr,
                    billingPeriodToDgr: invoice.header.billingPeriodToDgr,
                    insertTransactionDialog: insertTransactionDialog

                }}
            />
        </>
    )
}

const InvoicesListRow = (props) => {
    const { data, rowActions, virtualizedStyle, index, onRowClick, rowClassName } = props;
    const { handleChange, handelDeleteLine, currencyId } = rowActions

    const { t } = useTranslation("customersEstimationInvoices");

    const _handleChange = (name, value) => {
        handleChange(name, value, index);
    }

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${rowClassName} ${style.invoicesListRow}`} style={virtualizedStyle} onClick={onRowClick}>
            <div><div><label>{data.sequence}</label></div></div>
            <div><div><label title={t(`source_${data.source}`)}>{t(`source_${data.source}`)}</label></div></div>
            <div>
                <div><label title={isEnglish() ? data.descLo : data.descFo}>{isEnglish() ? data.descLo : data.descFo}</label></div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`desc-${index}`}
                        value={data.desc || ''}
                        onBlur={(value) => _handleChange("desc", value)}
                    />
                </div>
            </div>
            <div title={data?.transaction?.code}><div><label>{data?.transaction?.code}</label></div></div>
            <div><div><label title={data.source == ESTIMATION_FEES ? parseNumber(data.dueAmountAlc) : ""}> {data.source == ESTIMATION_FEES ? parseNumber(data.dueAmountAlc) : ""}</label></div></div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`invoice-amount-afc-${index}`}
                        value={data.invoiceAmountAfc || ''}
                        onBlur={(value) => _handleChange("invoiceAmountAfc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                        disabled={isNotEmpty(data?.transaction?.mmaId)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`invoice-amount-alc-${index}`}
                        value={data.invoiceAmountAlc || ''}
                        onBlur={(value) => _handleChange("invoiceAmountAlc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`discount-afc-${index}`}
                        value={data.discountAfc || ''}
                        onBlur={(value) => _handleChange("discountAfc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`discount-alc-${index}`}
                        value={data.discountAlc || ''}
                        onBlur={(value) => _handleChange("discountAlc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-perc-${index}`}
                        maxValue={100}
                        minValue={0}
                        value={data.vatPerc || ''}
                        onBlur={(value) => _handleChange("vatPerc", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-amount-afc-${index}`}
                        value={data.vatAmountAfc || ''}
                        onBlur={(value) => _handleChange("vatAmountAfc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-amount-alc-${index}`}
                        value={data.vatAmountAlc || ''}
                        onBlur={(value) => _handleChange("vatAmountAlc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`net-afc-${index}`}
                        value={data.netAfc || ''}
                        onBlur={(value) => _handleChange("netAfc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        disabled={true}
                        withoutFormat
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`net-alc-${index}`}
                        value={data.netAlc || ''}
                        onBlur={(value) => _handleChange("netAlc", value)}
                        allowDecimals={true}
                        decimalScale={-1}
                        currentCurrency={currencyId}
                        disabled={true}
                        withoutFormat
                    />
                </div>
            </div>

            <div><div><label title={formatDateNTime(data.approvalDateDgr)}>{formatDateNTime(data.approvalDateDgr)}</label></div></div>
            <div><div><label title={isEnglish() ? data?.transaction?.typeDescLo : data?.transaction?.typeDescFo}>{isEnglish() ? data?.transaction?.typeDescLo : data?.transaction?.typeDescFo}</label> </div></div>
            <div><div><label title={data?.transaction?.refCode}>{data?.transaction?.refCode}</label> </div></div>
            <div><div><label title={data?.transaction?.orderNo}>{data?.transaction?.orderNo}</label> </div></div>
            <div><div><label title={formatDateNTime(data?.transaction?.orderDateTime)}>{formatDateNTime(data?.transaction?.orderDateTime)}</label></div></div>
            <div><div><label title={formatDateNTime(data?.transaction?.dateTime)}>{formatDateNTime(data?.transaction?.dateTime)}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.cityDescLo : data?.transaction?.cityDescFo}>{i18next.language == 'en' ? data?.transaction?.cityDescLo : data?.transaction?.cityDescFo}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.districtDescLo : data?.transaction?.districtDescFo}>{i18next.language == 'en' ? data?.transaction?.districtDescLo : data?.transaction?.districtDescFo}</label></div></div>
            <div><div><label title={data?.transaction?.ownerName}>{data?.transaction?.ownerName}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.officialNameLo : data?.transaction?.officialNameFo}>{i18next.language == 'en' ? data?.transaction?.officialNameLo : data?.transaction?.officialNameFo}</label></div></div>
            <div><div><label title={data?.transaction?.clientName}>{data?.transaction?.clientName}</label></div></div>
            <div><div><label title={data?.transaction?.ownerCardNum}>{data?.transaction?.ownerCardNum}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.propertyTypeDescLo : data?.transaction?.propertyTypeDescFo}>{i18next.language == 'en' ? data?.transaction?.propertyTypeDescLo : data?.transaction?.propertyTypeDescFo}</label></div></div>
            <div><div><label title={data?.transaction?.propertyCode}>{data?.transaction?.propertyCode}</label></div></div>
            <div><div><label title={data?.transaction?.titleDeedNo}>{data?.transaction?.titleDeedNo}</label></div></div>
            <div><div><label title={data?.transaction?.parcelArea}>{data?.transaction?.parcelArea}</label></div></div>
            <div><div><label title={data?.transaction?.totalBuildArea}>{data?.transaction?.totalBuildArea}</label></div></div>
            <div><div><label title={data?.transaction?.noOfFloors}>{data?.transaction?.noOfFloors}</label></div></div>
            <div><div><label title={formatMoney(data?.transaction?.finalPrice)}> {formatMoney(data?.transaction?.finalPrice)}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.stageDescLo : data?.transaction?.stageDescFo}>{i18next.language == 'en' ? data?.transaction?.stageDescLo : data?.transaction?.stageDescFo}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}>{i18next.language == 'en' ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}</label></div></div>
            <div><div><label title={i18next.language == 'en' ? data?.transaction?.inspectorNameLo : data?.transaction?.inspectorNameFo}>{i18next.language == 'en' ? data?.transaction?.inspectorNameLo : data?.transaction?.inspectorNameFo}</label></div></div>
            <div><div><DeleteBtn onClick={() => handelDeleteLine(index)} /></div></div>
        </div>
    );
};

const InvoicesList = (props) => {
    const { invoice, dispatchInvoice, user, invoiveTableRef } = props;
    const { t } = useTranslation("customersEstimationInvoices");

    const columns = [
        { title: '#', width: '1%' },
        { title: t('customersEstimationInvoices:source'), width: '2%', excelColumn: "sourceDescLo,sourceDescFo" },
        { title: t('customersEstimationInvoices:item_code_name'), width: '3%', excelColumn: "descLo,descFo" },
        { title: t('customersEstimationInvoices:description'), width: '4%', excelColumn: "desc" },
        { title: t('customersEstimationInvoices:num_application'), searchColumns: "transaction.code", width: '2%', excelColumn: "applicationCode" },
        { title: t('customersEstimationInvoices:due_amount'), width: '3%', excelColumn: "dueAmountAlc" },
        { title: t('customersEstimationInvoices:invoice_amount_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%', excelColumn: "invoiceAmountAfc" },
        { title: t('customersEstimationInvoices:invoice_amount_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%', excelColumn: "invoiceAmountAlc" },

        { title: t('customersEstimationInvoices:discount_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%', excelColumn: "discountAfc" },
        { title: t('customersEstimationInvoices:discount_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%', excelColumn: "discountAlc" },

        { title: t('customersEstimationInvoices:vat_per'), width: '2%', excelColumn: "vatPerc" },
        { title: t('customersEstimationInvoices:vat_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%', excelColumn: "vatAmountAfc" },
        { title: t('customersEstimationInvoices:vat_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%', excelColumn: "vatAmountAlc" },

        { title: t('customersEstimationInvoices:net_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%', excelColumn: "netAfc" },
        { title: t('customersEstimationInvoices:net_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%', excelColumn: "netAlc" },
        { title: t('customersEstimationInvoices:approval_date'), width: '3%', excelColumn: "approvalDateDgr" },
        { title: t('customersEstimationInvoices:transaction_type'), width: '3%', searchColumns: "transaction.typeDescFo,transaction.typeDescLo", excelColumn: "typeDescLo,typeDescFo" }, /*** *********************-----*/
        { title: t('customersEstimationInvoices:ref_code'), width: '2%', searchColumns: "transaction.refCode", excelColumn: "refCode" },
        { title: t('customersEstimationInvoices:order_num'), width: '3%', excelColumn: "orderNo" },
        { title: t('customersEstimationInvoices:order_date'), width: '3%', excelColumn: "orderDateTime" },
        { title: t('customersEstimationInvoices:application_date'), width: '3%', excelColumn: "dateTime" },
        { title: t('customersEstimationInvoices:city'), width: '3%', searchColumns: "transaction.cityDescLo,transaction.cityDescFo", excelColumn: "cityDescLo,cityDescFo" },
        { title: t('customersEstimationInvoices:town'), width: '3%', searchColumns: "transaction.districtDescLo,transaction.districtDescFo", excelColumn: "districtDescLo,districtDescFo" },
        { title: t('customersEstimationInvoices:owner'), width: '3%', searchColumns: "transaction.ownerName", excelColumn: "ownerName" },
        { title: t('customersEstimationInvoices:customer'), width: '3%', excelColumn: "officialNameLo,officialNameFo" },
        { title: t('customersEstimationInvoices:client_name'), width: '3%', searchColumns: "transaction.clientName", excelColumn: "clientName" },
        { title: t('customersEstimationInvoices:owner_id'), width: '3%', searchColumns: "transaction.ownerCardNum", excelColumn: "ownerCardNum" },
        { title: t('customersEstimationInvoices:property_type'), width: '3%', searchColumns: "transaction.propertyTypeDescLo,transaction.propertyTypeDescFo", excelColumn: "propertyTypeDescLo,propertyTypeDescFo" },
        { title: t('customersEstimationInvoices:property_code'), width: '3%', searchColumns: "transaction.propertyCode", excelColumn: "propertyCode" },
        { title: t('customersEstimationInvoices:num_title_deed'), width: '3%', searchColumns: "transaction.titleDeedNo", excelColumn: "titleDeedNo" },
        { title: t('customersEstimationInvoices:land_area'), width: '3%', excelColumn: "parcelArea" },
        { title: t('customersEstimationInvoices:building_area'), width: '3%', excelColumn: "totalBuildArea" },
        { title: t('customersEstimationInvoices:number_of_floors'), width: '3%', excelColumn: "noOfFloors" },
        { title: t('customersEstimationInvoices:estimation_price'), width: '3%', excelColumn: "finalPrice" },
        { title: t('customersEstimationInvoices:stage'), width: '3%', searchColumns: "transaction.stageDescLo,transaction.stageDescFo", excelColumn: "stageDescLo,stageDescFo" },
        { title: t('customersEstimationInvoices:trnx_Status'), width: '3%', searchColumns: "transaction.trnxStatusLo,transaction.trnxStatusFo", excelColumn: "trnxStatusLo,trnxStatusFo" },
        { title: t('customersEstimationInvoices:inspector'), width: '3%', searchColumns: "transaction.inspectorNameLo,transaction.inspectorNameFo", excelColumn: "inspectorNameLo,inspectorNameFo" },
        { title: '', width: '2%' }
    ];

    const _handelDeleteLine = (index) => {
        dispatchInvoice({ index: index, type: ACTION_DELETE_LINE })
    }

    const _handleChange = (name, value, index) => {
        dispatchInvoice({ name, value, index, exchngRate: invoice?.header?.exchngRate, type: ACTION_SET_LINE_VALUE })
    }

    return (
        <Table
            id="invoices-list-table"
            ref={invoiveTableRef}
            columns={columns}
            Row={InvoicesListRow}
            tableStyle={style.invoicesListTable}
            rowHeight={45}
            tableWidth={400}
            rowActions={{
                handleChange: _handleChange,
                handelDeleteLine: _handelDeleteLine,
                currencyId: invoice?.header?.fcuId
            }}
            list={invoice.filteredLines ? invoice.filteredLines : invoice.lines}
            originalList={invoice.lines}
            formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
            user={user}
            onSearchColumns={(filteredList) => dispatchInvoice({ value: filteredList, type: ACTION_SET_FILTERED_LINES })}
        />
    );
}

const Details = (props) => {
    const { user, invoice, dispatchInvoice } = props;
    const { t } = useTranslation();
    const invoiveTableRef = useRef();

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('customersEstimationInvoices:details')}
                    </label>
                </div>
            </div>
            <HeaderLines invoice={invoice} dispatchInvoice={dispatchInvoice} invoiveTableRef={invoiveTableRef} />
            <InvoicesList invoice={invoice} dispatchInvoice={dispatchInvoice} user={user} invoiveTableRef={invoiveTableRef} />
        </div>
    )
}

export default Details;