import Checkbox from "@ipgd-gauge/checkbox";
import { useTranslation } from "react-i18next";
import style from './style.module.scss';

const dialogId = "update-valuation-fields-dialog";

const Tab2 = (props) => {
    const { tab, data, handleDataChanged } = props;
    const { t } = useTranslation('updateValuationFields');

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-cost-style`}
                            label={t('is_cost_style')}
                            isChecked={data?.styleCost}
                            setIsChecked={value => handleDataChanged("styleCost", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-income-style`}
                            label={t('is_income_style')}
                            isChecked={data?.styleIncome}
                            setIsChecked={value => handleDataChanged("styleIncome", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-market-style`}
                            label={t('is_market_style')}
                            isChecked={data?.styleMarket}
                            setIsChecked={value => handleDataChanged("styleMarket", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-comparison_method`}
                            label={t('is_comparison_method')}
                            isChecked={data?.methodComaprision}
                            setIsChecked={value => handleDataChanged("methodComaprision", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-residual-value-method`}
                            label={t('is_residual_value_method')}
                            isChecked={data?.methodResidualValue}
                            setIsChecked={value => handleDataChanged("methodResidualValue", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-income-capital-method`}
                            label={t('is_income_capital_method')}
                            isChecked={data?.methodIncomeCapital}
                            setIsChecked={value => handleDataChanged("methodIncomeCapital", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-replace-cost-method`}
                            label={t('is_replace_cost_method')}
                            isChecked={data?.methodReplacementCost}
                            setIsChecked={value => handleDataChanged("methodReplacementCost", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-cash-flow`}
                            label={t('cash_flow')}
                            isChecked={data?.methodDiscCashFlow}
                            setIsChecked={value => handleDataChanged("methodDiscCashFlow", value)}
                        />
                    </div>
                    <div className={`w50 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-investment-method`}
                            label={t('is_investment_method')}
                            isChecked={data?.methodInvestment}
                            setIsChecked={value => handleDataChanged("methodInvestment", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab2;