import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import style from './style.module.scss';

const dialogId = "update-valuation-fields-dialog";

const Tab1 = (props) => {
    const { tab, data, handleDataChanged } = props;
    const { t } = useTranslation('updateValuationFields');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w100">
                        <label id="valuation-fields-status-lbl" className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="valuation-fields-status"
                            sidePanelMode
                            options={[
                                {
                                    id: "active",
                                    title: t('active'),
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: data.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: "inactive",
                                    title: t('inactive'),
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: data.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('block')}</label>
                        <FrmInput
                            id={`${dialogId}-block`}
                            value={isEnglish() ? data?.valuationBlock?.descLo : data?.valuationBlock?.descFo}
                            disabled={true}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('field_seq')}</label>
                        <FrmInput
                            id={`${dialogId}-field-seq`}
                            value={data.seq}
                            disabled={true}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('fields_internal_name')}</label>
                        <FrmInput
                            id={`${dialogId}-fields-internal-name`}
                            value={data.internalName}
                            disabled={true}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('system_name_en')}</label>
                        <FrmInput
                            id={`${dialogId}-system-name-en`}
                            value={data.descLo}
                            disabled={true}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('system_name_ar')}</label>
                        <FrmInput
                            id={`${dialogId}-system-name-ar`}
                            value={data.descFo}
                            disabled={true}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('user_name_en')}</label>
                        <FrmInput
                            id={`${dialogId}-user-name-en`}
                            value={data.userDescLo}
                            onBlur={(value) => handleDataChanged("userDescLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('user_name_ar')}</label>
                        <FrmInput
                            id={`${dialogId}-user-name-ar`}
                            value={data.userDescFo}
                            onBlur={(value) => handleDataChanged("userDescFo", value)}
                        />
                    </div>

                    <div className={`w100 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-searchable`}
                            label={t('is_searchable')}
                            isChecked={data?.searchable}
                            setIsChecked={value => handleDataChanged("searchable", value)}
                        />
                    </div>
                    <div className={`w100 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-ref-code`}
                            label={t('is_ref_code')}
                            isChecked={data?.refCode}
                            setIsChecked={value => handleDataChanged("refCode", value)}
                        />
                    </div>
                    <div className={`w100 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-land`}
                            label={t('is_land')}
                            isChecked={data?.land}
                            setIsChecked={value => handleDataChanged("land", value)}
                        />
                    </div>
                    <div className={`w100 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-is-flat`}
                            label={t('is_flat')}
                            isChecked={data?.flat}
                            setIsChecked={value => handleDataChanged("flat", value)}
                        />
                    </div>
                    <div className={`w100 ${style.checkboxContainer}`}>
                        <Checkbox
                            id={`${dialogId}-hide-in-task-mode`}
                            label={t('hide_in_task_mode')}
                            isChecked={data?.hideInTaskMode}
                            setIsChecked={value => handleDataChanged("hideInTaskMode", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;